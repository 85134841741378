import { Button } from "antd";

function IntroSection({
  onStart,
  requestDetails
}: {
  onStart: () => void;
  requestDetails: any;
}) {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-5/6 md:w-1/2">
        <div className="text-center text-xl font-bold text-color-7">
          Welcome to ConstructivIQ!
        </div>
        <div className="text-center text-sm text-color-6 mt-3 flex flex-col items-center justify-center">
          You have been requested to provide Lead Times for certain Materials by{" "}
          {requestDetails.gc_first_name} {requestDetails.gc_last_name} from{" "}
          {requestDetails.gc_organization_name}.
          <ul className="w-[65%] text-left mt-1 list-decimal list-outside p-0 m-0 marker:m-0">
            <li>
              Enter the Lead Times of individual materials and click Submit.
            </li>
            <li>
              You can come back to this page and submit Lead Times for the
              pending Materials and/or update the Lead Times for submitted
              materials later.
            </li>
          </ul>
        </div>
        <div className="flex justify-center w-full mt-7">
          <Button
            htmlType="button"
            type="primary"
            className="w-full md:w-3/5"
            onClick={onStart}
          >
            Start
          </Button>
        </div>
      </div>
    </div>
  );
}

export default IntroSection;
