import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Popover, Select, message } from "antd";
import {
  createProjectIntegration,
  getAccountProjects
} from "services/integration-service";

import WarningIcon from "components/svg-icons/warning-icon";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IntegrationType } from "pages/subscription-settings/integrations-tab-new/utils";
import { useWatch } from "antd/lib/form/Form";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import moment from "moment";
import { ProjectIntegrationsTabSharedContext } from "../project-integrations-tab-context";
import { IntegrationSystemNameMap } from "../../../../constants";

export default function ProjectAutodeskInitialIntegration(props: any) {
  const { onProjectIntegrated } = props;

  const { sharedState, dispatch } = useContext(
    ProjectIntegrationsTabSharedContext
  );

  const { tokenRetrievalState }: TProjectContext = useContext(ProjectContext);

  const [form] = Form.useForm();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [projects, setProjects] = useState<any>(null);

  const [submittingForm, setSubmittingForm] = useState(false);

  const companies = useMemo(() => {
    const comp: any = [];
    const subConfigs = sharedState?.subscriptionIntegrationConfigs?.filter(
      (subConfig: any) => {
        return (
          subConfig.integration.system ===
            IntegrationType[IntegrationType.AUTODESK] ||
          subConfig.integration.system ===
            IntegrationType[IntegrationType.BIM360]
        );
      }
    );
    subConfigs?.forEach((config: any) => {
      comp.push({
        name: config.properties.AUTODESK_ACCOUNT_NAME,
        id: config.properties.AUTODESK_ACCOUNT_ID,
        subscriptionId: config.id
      });
    });
    return comp.filter((company: any) => company.id);
  }, [sharedState.subscriptionIntegrationConfigs]);

  const selectedCompanyId = useWatch("account_id", form);

  useEffect(() => {
    if (!selectedCompanyId || !tokenRetrievalState.token) return;

    const getAccounts = async () => {
      const foundCompany = companies.find((company: any) => {
        return company.id === selectedCompanyId;
      });

      const projectsResp = await getAccountProjects(
        sharedState?.system,
        {
          accountId: foundCompany.id,
          oauthOperationId: sharedState?.authData?.id
        },
        tokenRetrievalState?.token
      );

      if (projectsResp.success) {
        setProjects(projectsResp.data);
      }
    };
    getAccounts();
  }, [
    companies,
    selectedCompanyId,
    sharedState.authData.id,
    sharedState.system,
    tokenRetrievalState.token
  ]);

  const onFinish = (values: any) => {
    setSubmittingForm(true);
    const foundCompany = companies.find((company: any) => {
      return company.id === values.account_id;
    });

    const foundProject = projects.find((project: any) => {
      return project.id === values.project_id;
    });
    const payload: any = [
      {
        properties: {
          integration_name: sharedState?.additionalData?.newIntegrationName,
          autodesk_project_id: values.project_id.toString(),
          autodesk_project_name: foundProject.name,
          project_alert_email: values.alert_email.join(",")
        },
        subscription_config_id: foundCompany.subscriptionId,
        oauth_operation_id: sharedState?.authData?.id
      }
    ];

    const saveData = async () => {
      const createResponse = await createProjectIntegration(
        IntegrationType[IntegrationType.AUTODESK],
        payload,
        tokenRetrievalState.token
      );

      setSubmittingForm(false);

      if (createResponse.success) {
        message.success("Integration created successfully.");
        onProjectIntegrated(createResponse);
        dispatch({
          type: "SET_ACTIVE_LIST_VIEW"
        });
      } else {
        message.error("Failed to create integration.");
      }
    };
    saveData();
  };

  const confirmPopoverContent = (
    <div>
      <div className="flex justify-center">
        <WarningIcon />
      </div>
      <div className="flex justify-center text-lg font-medium">
        Please confirm your action
      </div>
      <div className="mt-2 mb-3.5">
        Please review and verify the entered details before you save.
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Button
          onClick={() => {
            setShowConfirmDialog(false);
          }}
          disabled={submittingForm}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
          loading={submittingForm}
        >
          Save
        </Button>
      </div>
    </div>
  );

  const companyListOptions = useMemo(() => {
    return companies?.map((company: any) => {
      return {
        label: company.name,
        value: company.id
      };
    });
  }, [companies]);

  const projectListOptions = useMemo(() => {
    return projects?.map((project: any) => {
      return {
        label: project.name,
        value: project.id
      };
    });
  }, [projects]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="h-full flex flex-col"
    >
      <div className="h-full relative">
        <div className="grow w-full md:max-w-screen-sm mx-auto py-4">
          <div className="mt-5 ">
            <div className="text-sm uppercase font-semibold text-neutral-700">
              You are authorized with{" "}
              {IntegrationSystemNameMap[sharedState?.system]}
            </div>
            <div className="text-xs mt-1">
              <span className="text-neutral-600">Last authorized on: </span>
              <span>
                {sharedState?.authData?.updated_at
                  ? moment(sharedState?.authData?.updated_at)
                      .local()
                      .format("hh:mmA MM/DD/YYYY")
                  : null}
              </span>
            </div>
          </div>
          <div className="mt-7 w-[500px]">
            <Form.Item
              name="account_id"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>ACCOUNT NAME</span>
                </div>
              }
              rules={[{ required: true, message: "Please select an Account" }]}
              requiredMark={false}
            >
              <Select
                showSearch={false}
                // mode="multiple"
                options={companyListOptions}
                // tagRender={tagRender}
                placeholder="Select an Account"
                className="normal-case"
                loading={!companyListOptions}
                allowClear={false}
                showArrow
              />
            </Form.Item>
            <Form.Item
              name="project_id"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>PROJECT NAME</span>
                </div>
              }
              rules={[{ required: true, message: "Please select a Project" }]}
              requiredMark={false}
            >
              <Select
                showSearch={false}
                options={projectListOptions}
                placeholder="Select a Project"
                className="normal-case"
                loading={selectedCompanyId && !projectListOptions}
                allowClear={false}
                showArrow
                disabled={!selectedCompanyId || !projectListOptions}
              />
            </Form.Item>
            <Form.Item
              name="alert_email"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>Notification Recipients</span>
                  <Popover
                    content={
                      <div className="text-sm font-medium">
                        Enter the email address of users who need to be notified
                        in case of failure in authorization.
                      </div>
                    }
                  >
                    <InfoCircleOutlined className="pl-1 -mt-0.5" />
                  </Popover>
                </div>
              }
              rules={[
                { required: true, message: "Please enter at least one email" }
              ]}
            >
              <Select
                className="normal-case"
                mode="tags"
                placeholder="Emails IDs"
                notFoundContent={null}
                dropdownStyle={{ display: "none" }}
              />
            </Form.Item>
          </div>
          <div className="mt-9 grid grid-cols-2 gap-x-4">
            <div className="space-y-1">
              <div className="info-label">AUTHORIZATION GRANTED BY:</div>
              <div className="info-value">
                {sharedState.authData?.output?.user_info?.given_name}{" "}
                {sharedState.authData?.output?.user_info?.family_name}(
                {sharedState.authData?.output?.user_info?.email})
              </div>
            </div>
            <div className="space-y-1">
              <div className="info-label">ADDED BY:</div>
              <div className="info-value">
                {sharedState.authData?.created_by_user?.first_name}{" "}
                {sharedState.authData?.created_by_user?.last_name} (
                {sharedState.authData?.created_by_user?.email})
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className="mx-0 pb-0 mb-0" />
      <footer className="p-6 flex justify-end gap-x-4">
        <Button
          size="middle"
          onClick={() => {
            dispatch({
              type: "SET_ACTIVE_LIST_VIEW"
            });
          }}
          disabled={submittingForm}
        >
          Back
        </Button>
        <Popover
          placement="topRight"
          content={confirmPopoverContent}
          destroyTooltipOnHide
          open={showConfirmDialog}
        >
          <Button
            size="middle"
            type="primary"
            onClick={async () => {
              try {
                await form.validateFields();
                // console.log("validationResult ", validationResult);
                setShowConfirmDialog(true);
              } catch (ex) {
                console.log(ex);
              }
            }}
            disabled={submittingForm}
          >
            Save
          </Button>
        </Popover>
      </footer>
    </Form>
  );
}
