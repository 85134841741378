import "./links.scss";

const linkedCell = (
  title: string,
  value: any,
  titleClassname: string = "",
  valueClassname: string = ""
) => (
  <div className="flex space-x-1.5 px-3 links-card items-center">
    <div className={`text-xs font-normal text-[#8E8E8E] ${titleClassname}`}>
      {title}
    </div>
    <div className={`${valueClassname}`} title={value}>
      {value}
    </div>
  </div>
);

export default linkedCell;
