import ErrorIcon from "components/svg-icons/error-icon";
import { IMaterial } from "./type-definitions";

export function VerticalList({
  items,
  onChange,
  activeKey
}: {
  items: { label: any; key: string }[];
  onChange: (item: any) => void;
  activeKey: string | null;
}) {
  return (
    <div className="py-0.5">
      {items.map((item) => (
        <button
          role="tab"
          type="button"
          tabIndex={0}
          className={`bg-white cursor-pointer w-full px-6 py-2.5 pr-5 border-0 border-r-2 border-transparent border-solid ${
            activeKey === item.key
              ? "bg-[#F0F0F0] !border-black font-semibold"
              : ""
          }`}
          onClick={() => {
            onChange(item.key);
          }}
          key={item.key}
          data-testid={`vertical-list-item-${item.key}`}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}

export function VerticalListItem({
  label,
  subLabel,
  hasWarningIcon
}: {
  label: string;
  subLabel?: string;
  hasWarningIcon?: boolean;
}) {
  return (
    <div className="text-left w-full max-w-full">
      <div className="flex items-center justify-between">
        <div className="w-full max-w-full overflow-hidden">
          <div className="text-sm text-color-1 max-w-full overflow-hidden truncate">
            {label}
          </div>
          {subLabel && (
            <div className="text-xs text-color-2 italic max-w-full overflow-hidden truncate">
              {subLabel}
            </div>
          )}
        </div>
        {hasWarningIcon && (
          <div
            className="danger-text flex items-center justify-center ml-1 pt-0.5"
            data-testid="info-icon"
          >
            <ErrorIcon width={17} height={17} />
          </div>
        )}
      </div>
    </div>
  );
}

export function MaterialListItem({
  material,
  index,
  additionalClassName
}: {
  material: IMaterial;
  index: number;
  additionalClassName?: string;
}) {
  return (
    <div
      className={`text-xs border-0 border-solid border-b ${additionalClassName} px-4 pb-0.5 ${
        index === 0 ? "" : "pt-2"
      }`}
      key={material.id}
      data-testid={`material-list-item-${material.id}`}
    >
      {material.name}
    </div>
  );
}
