import { ProjectContext } from "context/ProjectProvider";
import { useCIQMutation, useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useState } from "react";
import { GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION } from "services/graphQL/ciq-queries";
import { MUTATION_SEND_FEATURE_WF_INVITE } from "services/graphQL/mutations";
import { IMaterial } from "../type-definitions";

export function useRequestDurations(materialIds: string[]): {
  data: { material: IMaterial[] } | null;
  loading: boolean;
  sendInvite: (variables: any) => Promise<any>;
  sendingInvite: boolean;
} {
  const { gqlClientForProject } = useContext(ProjectContext);

  const [sendingInvite, setSendingInvite] = useState(false);

  const { data, loading } = useCIQQuery<{
    material: IMaterial[];
  }>(GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION, {
    client: gqlClientForProject,
    variables: {
      materialIds
    },
    skip: materialIds.length === 0
  });

  const [sendFeatureWFInvite] = useCIQMutation(
    MUTATION_SEND_FEATURE_WF_INVITE,
    {
      client: gqlClientForProject
    }
  );

  const sendInvite = async (variables: any) => {
    setSendingInvite(true);
    const response = await sendFeatureWFInvite({ variables });
    setSendingInvite(false);
    return response;
  };

  return {
    data: data || null,
    loading,
    sendInvite,
    sendingInvite
  };
}
