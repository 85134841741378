import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, message, Spin, Tabs } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import { TMaterialItem } from "models/materials";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { MUTATION_UPDATE_MATERIAL_PK } from "services/graphQL/mutations";
import MaterialTitlePanel from "components/material-title-panel";
import { useProjectParticipants } from "hooks/project-participants";
import { matchUserProjectRole } from "utils/utils";
import { MATERIAL_BY_PK } from "services/graphQL/ciq-queries";
import { useMaterialAttachments } from "hooks/material";
import { useCIQMutation, useCIQQuery } from "hooks/ciq-gql-hooks";
import ErrorBoundary from "components/error-boundary";
import { EUserTypes, ErrorMessages } from "../../constants";
import MaterialDetailsTab from "./material-details-tab";

import "./material-details.scss";

export default function MaterialDetailsPage() {
  const history = useHistory();

  const { projectId, materialId } = useParams() as any;

  const location = useLocation();
  const tab = new URLSearchParams(location.search).get("tab");

  const [materialDetailsState, setMaterialDetailsState] = useState<any>();

  const { gqlClientForProject, tokenContents, eventLogs } =
    useContext(ProjectContext);

  const isLoggedInUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const { projectParticipants } = useProjectParticipants(!isLoggedInUserGC);

  const [tabIndex, setTabIndex] = useState<string>(tab || "1");

  const {
    data: materialDetailsSubData,
    error: materialDetailsSubError,
    refetch: refetchMaterialPK
  } = useCIQQuery<{ material_by_pk: TMaterialItem }>(MATERIAL_BY_PK, {
    variables: { id: materialId },
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const { data: commentAndAttachments, refetchCommentData } =
    useMaterialAttachments({
      materialId,
      gqlClientForProject
    });

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs.length && previousEventLogs.current !== eventLogs) {
      if (eventLogs.some((log) => log.data_source === "comment")) {
        refetchCommentData();
      }

      const dependentIds = [materialId];
      const materialDbId = materialDetailsState?.date_block_materials[0]?.id;
      const linkedSubmittalDbIds =
        materialDetailsState?.submittal_material_links?.map(
          (m: any) => m.submittal?.date_block_submittals[0].id
        );

      const linkedSubmittalIds =
        materialDetailsState?.submittal_material_links?.map(
          (m: any) => m.submittal?.id
        );

      if (materialDbId) dependentIds.push(materialDbId);
      if (linkedSubmittalDbIds) dependentIds.push(...linkedSubmittalDbIds);
      if (linkedSubmittalIds) dependentIds.push(...linkedSubmittalIds);

      if (
        eventLogs.some(
          (e) =>
            e.data_source === "project_feature_configurations" ||
            e.info.material_ids?.includes(materialId) ||
            dependentIds.includes(e.object_id)
        )
      ) {
        refetchMaterialPK();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    eventLogs,
    materialId,
    refetchMaterialPK,
    refetchCommentData,
    materialDetailsState?.submittal_material_links,
    materialDetailsState?.date_block_materials
  ]);

  useEffect(() => {
    if (!tab) {
      history.replace(`/project/${projectId}/materials/${materialId}?tab=1`);
    } else {
      setTabIndex(tab);
    }
  }, [history, materialId, projectId, tab]);

  useEffect(() => {
    if (!materialDetailsSubError) return;
    message.error(materialDetailsSubError.message);
  }, [materialDetailsSubError]);

  const [updateMaterial] = useCIQMutation(MUTATION_UPDATE_MATERIAL_PK, {
    client: gqlClientForProject
  });

  useEffect(() => {
    if (materialDetailsSubData?.material_by_pk) {
      setMaterialDetailsState(materialDetailsSubData?.material_by_pk);
    }
  }, [materialDetailsSubData]);

  const goToMaterialsPage = () => {
    history.push(`/project/${projectId}/materials`);
  };

  const saveFieldChange = useCallback(
    async (updates: any) => {
      // console.log("saveFieldChange ", updates);

      const updateReqObj = {
        variables: {
          set: updates,
          pk_columns: { id: materialId }
        }
      };

      try {
        const updateResponse = await updateMaterial(updateReqObj);
        if (updateResponse.errors) {
          if (
            updateResponse.errors[0].message &&
            updateResponse.errors[0].message.includes("duplicate")
          ) {
            message.error(ErrorMessages.MaterialExists);
          } else {
            message.error(updateResponse.errors[0].message);
          }

          const revert: any = {};
          Object.keys(updates).forEach((key: string) => {
            revert[key] =
              materialDetailsSubData?.material_by_pk[
                key as keyof TMaterialItem
              ];
          });
          setMaterialDetailsState((prev: any) => {
            return {
              ...prev,
              ...revert
            };
          });
          return;
        }
        message.success("Updated successfully");
      } catch (ex: any) {
        message.error(ex.message);

        const revert: any = {};
        Object.keys(updates).forEach((key: string) => {
          revert[key] =
            materialDetailsSubData?.material_by_pk[key as keyof TMaterialItem];
        });
        setMaterialDetailsState((prev: any) => {
          return {
            ...prev,
            ...revert
          };
        });
      }
    },
    [materialDetailsSubData?.material_by_pk, materialId, updateMaterial]
  );

  const materialTitlePanel = (
    <MaterialTitlePanel
      materialDetailsState={materialDetailsState}
      setMaterialDetailsState={setMaterialDetailsState}
      saveFieldChange={saveFieldChange}
      projectId={projectId}
    />
  );

  const tabItems = [
    {
      label: "Material Details",
      key: "1",
      children: (
        <div>
          {materialDetailsState ? (
            <ErrorBoundary>
              <MaterialDetailsTab
                materialDetailsState={materialDetailsState}
                setMaterialDetailsState={setMaterialDetailsState}
                saveFieldChange={saveFieldChange}
                materialAttachments={commentAndAttachments?.attachments}
                commentsData={commentAndAttachments?.commentsData}
                materialTitlePanel={materialTitlePanel}
                projectParticipants={projectParticipants}
                materialDbData={materialDetailsSubData?.material_by_pk!}
                isGCUserLoggedIn={isLoggedInUserGC}
              />
            </ErrorBoundary>
          ) : (
            !materialDetailsSubError && (
              <div className="min-h-[80vh] flex items-center justify-center">
                <Spin size="large" className="mt-4" />
              </div>
            )
          )}
        </div>
      )
    }
  ];

  return (
    <div className="material-details-page w-full px-5 pb-6">
      <div className="w-full bg-transparent border-solid border-0 border-b-2 border-[#00000005] grid grid-cols-12 items-center h-[40px]">
        <div className="col-span-2 flex items-center justify-start">
          <Button
            icon={<ArrowLeftOutlined />}
            className="text-one text-black !bg-transparent !border-0 px-0 !shadow-none"
            onClick={goToMaterialsPage}
          >
            All materials
          </Button>
        </div>
      </div>
      <div className="relative">
        <Tabs
          onChange={(activeKey: any) => {
            setTabIndex(activeKey.toString());
            history.push(
              `/project/${projectId}/materials/${materialId}?tab=${activeKey}`
            );
          }}
          activeKey={tabIndex}
          items={tabItems}
          className="material-details-page-tab"
        />
      </div>
    </div>
  );
}
