import { Button, Collapse, Form, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";

import "../links.scss";
import { QUERY_SUBMITTAL_TYPES } from "services/graphQL/queries";
import { useCallback, useEffect, useState } from "react";
import LinkIcon from "components/svg-icons/link-icon";
import AsterixIcon from "components/svg-icons/asterix-icon";
import { DateUtils } from "utils/dateutils";
import { ProjectSettingType } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { RiskLevelType } from "components/date-block/models";
import { mergedStrings } from "utils/utils";
import { ErrorMessages, SubmittalStatusToStrMap } from "../../../constants";
import linkedCell from "../linkedCell";

const { Panel } = Collapse;

type TMaterialLinksProps = {
  materialDetailsState: any;
  projectId: string;
  canOpenLinkingPage: boolean;
  canEditLinkingPage: boolean;
  projectDetails: ProjectSettingType | undefined;
};

function MaterialLinks(props: TMaterialLinksProps) {
  const {
    materialDetailsState,
    canOpenLinkingPage,
    canEditLinkingPage,
    projectId,
    projectDetails
  } = props;

  const [submittalTypes, setSubmittalTypes] = useState([]);

  const { data: submittalTypesData } = useCIQQuery(QUERY_SUBMITTAL_TYPES);
  const history = useHistory();

  useEffect(() => {
    setSubmittalTypes(submittalTypesData?.configurations_value);
  }, [submittalTypesData]);

  const submittalPanelHeader = (link: any) => {
    const displaySubmittalId =
      projectDetails?.spec_section && link.spec_no
        ? `${link.spec_no} - ${link.submittal_sequence_id}`
        : `${link.submittal_sequence_id}`;
    return (
      <div
        className="flex items-center"
        title={`${displaySubmittalId}-${link?.title}`}
      >
        <span className="flex items-center mr-2">
          <LinkIcon />
        </span>
        <i className="opacity-60">
          {displaySubmittalId}
          <span className="mx-1">-</span>
        </i>
        <span className="w-full truncate overflow-hidden mr-5">
          {link?.title}
        </span>
      </div>
    );
  };

  // const getSpecInfo = (spec_section_no: string, spec_section_name: string) => {
  //   if (spec_section_no && spec_section_name)
  //     return `${spec_section_no} - ${spec_section_name}`;
  //   if (spec_section_no) return spec_section_no;
  //   return "";
  // };

  // const getSubmitterInfo = (submittal: any) => {
  //   if (submittal.submittal_user) {
  //     return `${submittal.submittal_user.first_name} ${submittal.submittal_user.last_name} - ${submittal.submittal_user.project_users[0].subscription_vendor.name}`;
  //   }
  //   if (submittal.submitter_unregistered) {
  //     return submittal.submitter_unregistered;
  //   }
  //   return "NA";
  // };

  const getSubmittalType = useCallback(
    (typeId: any) => {
      if (!submittalTypes || !submittalTypes.length) return "";
      const foundType: any = submittalTypes.find((sType: any) => {
        return sType.id === typeId;
      });
      return foundType.value;
    },
    [submittalTypes]
  );

  const schedulePanelHeader = (link: any) => {
    const headerMarkup = (
      <div
        className="flex items-center"
        title={`${link?.gantt_task?.source_task_id}-${link?.gantt_task?.text}`}
      >
        <span className="flex items-center mr-2">
          <LinkIcon />
        </span>
        <i className="opacity-60">
          {link?.gantt_task?.source_task_id}
          {link?.gantt_task?.source_task_id && !link.driving_task && (
            <span className="mx-1">-</span>
          )}
        </i>
        {link.driving_task && (
          <span className="flex items-center mx-1 mb-1">
            <AsterixIcon />
          </span>
        )}
        <span className="truncate overflow-hidden">
          {link?.gantt_task?.text}
        </span>
        {link.linked_to_end_date !== null &&
          (link?.gantt_task?.start_date || link?.gantt_task?.end_date) && (
            <i className="opacity-60 px-1 w-[90px]">
              {link?.gantt_task?.start_date && link.linked_to_end_date === false
                ? DateUtils.format(link?.gantt_task?.start_date)
                : null}
              {link?.gantt_task?.end_date && link.linked_to_end_date === true
                ? DateUtils.format(link?.gantt_task?.end_date)
                : null}
            </i>
          )}
        {link.linked_to_end_date === null && link?.gantt_task?.start_date && (
          <i className="opacity-60 px-1 w-[90px]">
            DateUtils.format(link?.gantt_task?.start_date)
          </i>
        )}
      </div>
    );

    if (canOpenLinkingPage) {
      return (
        <Link
          to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
          className="text-inherit hover:text-inherit"
        >
          {headerMarkup}
        </Link>
      );
    }
    return headerMarkup;
  };

  const cssForValue = "text-xs font-normal text-[#000000CC]";
  return (
    <div>
      <Form.Item
        label={
          <div className="flex space-x-2 items-center">
            <span>
              SUBMITTALS
              {materialDetailsState?.submittal_material_links?.length
                ? ` (${materialDetailsState?.submittal_material_links.length})`
                : ""}
            </span>
          </div>
        }
      >
        {materialDetailsState?.submittal_material_links.length > 0 ? (
          <Collapse expandIconPosition="end" className="custom-collapse">
            {materialDetailsState?.submittal_material_links.map((link: any) => {
              const { submittal } = link;
              if (!submittal)
                return (
                  <Panel
                    header={
                      <Tooltip title={ErrorMessages.SubmittalAccessDenied}>
                        {submittalPanelHeader(link)}
                      </Tooltip>
                    }
                    key={link.submittal_id}
                    className="custom-panel"
                    collapsible="disabled"
                  />
                );
              const type = getSubmittalType(submittal.type);
              const status =
                SubmittalStatusToStrMap[submittal?.workflow_status];
              const riskLevel = submittal?.date_block_submittals?.length
                ? submittal?.date_block_submittals[0]?.risk_level
                : "";
              const specSection = mergedStrings([
                submittal?.spec_no ?? "",
                submittal?.spec_name ?? ""
              ]);
              return (
                <Panel
                  header={submittalPanelHeader(link)}
                  key={submittal.id}
                  className={
                    riskLevel === RiskLevelType.High
                      ? "custom-panel risk-panel truncate"
                      : "custom-panel truncate"
                  }
                >
                  <div className="flex-col space-y-2.5">
                    <div className="flex w-full -mb-2">
                      {linkedCell(
                        "Spec Section:",
                        `${specSection}`,
                        "",
                        `${cssForValue} truncate max-w-[300px]`
                      )}
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="grid grid-rows-2 gap-1">
                        {linkedCell("Type:", type, "w-[38px]", cssForValue)}
                        {linkedCell("Status:", status, "", cssForValue)}
                      </div>
                      <div className="grid grid-rows-2">
                        {linkedCell(
                          "Revision:",
                          submittal?.revision,
                          "",
                          cssForValue
                        )}
                        {linkedCell(
                          "Risk:",
                          riskLevel,
                          "w-[52px]",
                          riskLevel === RiskLevelType.High
                            ? "date-block-risk-high text-xs font-semibold"
                            : cssForValue
                        )}
                      </div>
                    </div>
                    <div className="flex justify-end p-2">
                      <Button
                        size="small"
                        className="text-xs"
                        onClick={() => {
                          history.push(
                            `/project/${projectId}/submittals/${submittal?.id}`
                          );
                        }}
                      >
                        View Submittal
                      </Button>
                    </div>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            No links added{" "}
            {canEditLinkingPage && (
              <Link
                to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
              >
                Click to add
              </Link>
            )}
          </span>
        )}
      </Form.Item>
      <Form.Item
        label={
          <span>
            SCHEDULE ACTIVITIES
            {materialDetailsState?.material_schedule_links?.length
              ? ` (${materialDetailsState?.material_schedule_links.length})`
              : ""}
          </span>
        }
      >
        {materialDetailsState?.material_schedule_links.length > 0 ? (
          <Collapse
            accordion
            expandIconPosition="end"
            className="custom-collapse"
          >
            {materialDetailsState?.material_schedule_links.map((link: any) => {
              return (
                <Panel
                  header={schedulePanelHeader(link)}
                  key={link.task_id}
                  className="custom-panel"
                  collapsible="disabled"
                />
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            No links added{" "}
            {canEditLinkingPage && (
              <Link
                to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
              >
                Click to add
              </Link>
            )}
          </span>
        )}
      </Form.Item>
    </div>
  );
}

export default MaterialLinks;
