/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import modal from "antd/lib/modal";
import { useMutation } from "@apollo/client";
import { ProjectContext } from "context/ProjectProvider";
import { MUTATION_DELETE_LINKING_SUBMITTAL_SCHEDULE } from "services/graphQL/mutations";
import { useLocation } from "react-router";
import { ScheduleLinkingType, SubmittalType } from "./models";

interface ITrackActivityProps {
  selectedSubmittal: SubmittalType | undefined;
  onChanges: (value: boolean) => void;
  canEdit: boolean;
}

function TrackActivity({
  selectedSubmittal,
  onChanges,
  canEdit
}: ITrackActivityProps) {
  const { search } = useLocation();
  const [trackActivity, setTrackActivity] = useState(false);
  const { gqlClientForProject } = useContext(ProjectContext);
  useEffect(() => {
    onChanges(trackActivity);
  }, [trackActivity]);
  useEffect(() => {
    const defaultOpen = new URLSearchParams(search).get("linkActivity");
    if (
      selectedSubmittal?.submittal_schedule_links?.length ||
      defaultOpen === "true"
    ) {
      setTrackActivity(true);
    } else {
      setTrackActivity(false);
    }
  }, []);

  const [deleteLinkingSubmittalScheduleMutation] = useMutation(
    MUTATION_DELETE_LINKING_SUBMITTAL_SCHEDULE,
    {
      client: gqlClientForProject
    }
  );
  const deleteAllLinkedActivity = () => {
    selectedSubmittal?.submittal_schedule_links?.forEach(
      (item: ScheduleLinkingType) => {
        const submittalIds = selectedSubmittal?.ids || [selectedSubmittal?.id];
        deleteLinkingSubmittalScheduleMutation({
          variables: {
            where: {
              task_id: { _eq: item.gantt_task.id },
              submittal_id: { _in: submittalIds }
            }
          }
        }).then((res) => {
          if (res.errors) message.error(res.errors[0].message);
        });
      }
    );
  };

  const toggleActivityLinking = () => {
    if (trackActivity) {
      if (selectedSubmittal?.submittal_schedule_links?.length) {
        modal.confirm({
          title: "Do you want to unlink all the activity?",
          icon: <ExclamationCircleOutlined />,
          content: "",
          className: "skip-listing-window",
          onOk() {
            deleteAllLinkedActivity();
            setTrackActivity(false);
          },
          okText: "Yes",
          cancelText: "No"
        });
      } else {
        setTrackActivity(false);
      }
    } else {
      setTrackActivity(true);
    }
  };
  useEffect(() => {});
  return (
    <Checkbox
      disabled={!canEdit}
      checked={trackActivity}
      onChange={toggleActivityLinking}
      style={{ marginRight: "6px" }}
    >
      Link submittal with activities
    </Checkbox>
  );
}

export default TrackActivity;
