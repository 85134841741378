import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  message
} from "antd";
import type { CheckboxProps } from "antd";

import { useForm } from "antd/lib/form/Form";
import {
  IntegrationNameCellRenderer,
  integrationTypeCellRenderer
} from "components/cell-renders";
import { GridLoadingIndicator } from "components/widgets";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  getOAuthUrl,
  saveACCIntegrationConfig
} from "services/integration-service";

import {
  IntegrationType,
  activeSourceTypes,
  getCIQAUthorizerDetails
} from "pages/subscription-settings/integrations-tab-new/utils";
import { useIntegrationAuthProcess } from "hooks/integration-hooks";
import ProjectIntegrationListMessage from "components/widgets/project-integration-list-message";
import { useHistory, useParams } from "react-router";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import {
  ErrorMessages,
  IntegrationAuthProcessStatuses,
  IntegrationSystemNameMap,
  ProjectPermissionEnum
} from "../../../constants";
import { ProjectIntegrationsTabSharedContext } from "./project-integrations-tab-context";

const { Option } = Select;

function ProjectIntegrationsList() {
  const history = useHistory();

  const {
    tokenRetrievalState,
    gqlClientForProject,
    tokenContents
  }: TProjectContext = useContext(ProjectContext);

  const { dispatch, sharedState } = useContext(
    ProjectIntegrationsTabSharedContext
  );

  const gridRef = useRef<AgGridReact>(null);
  const [form] = useForm();

  const [addingIntegration, setAddingIntegration] = useState(false);
  const [isSubIntConfigured, setSubIntConfigured] = useState(false);

  const canAddIntegration = !isPermissionNotGrantted(
    ProjectPermissionEnum.addProjectIntegration,
    tokenContents?.role!
  );

  const { initAuthProcess } = useIntegrationAuthProcess(
    "project",
    gqlClientForProject
  );

  const [openAddIntegrationPanel, setOpenAddIntegrationPanel] = useState(false);

  const [checked, setChecked] = useState(false);
  const { projectId } = useParams() as any;

  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      autoHeight: true,
      resizable: true,
      headerClass: "grid-header-style",
      flex: 1,
      minWidth: 100
    };
  }, []);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "INTEGRATION NAME",
        headerTooltip: "INTEGRATION NAME",
        menuTabs: [],
        cellRenderer: IntegrationNameCellRenderer
      },
      {
        headerName: "SOURCE APPLICATION",
        field: "integration.system",
        menuTabs: [],
        filter: false,
        sortable: false,
        cellRenderer: integrationTypeCellRenderer
      },
      {
        headerName: "ACCOUNT NAME",
        menuTabs: [],
        filter: false,
        sortable: false,
        valueGetter: ({ data }: any) => {
          return (
            data.subscriptionProperties.PROCORE_COMPANY_NAME ||
            data.subscriptionProperties.AUTODESK_ACCOUNT_NAME
          );
        }
      },
      {
        headerName: "PROJECT NAME",
        menuTabs: [],
        filter: false,
        sortable: false,
        valueGetter: ({ data }: any) => {
          return (
            data.properties.PROCORE_PROJECT_NAME ||
            data.properties.AUTODESK_PROJECT_NAME
          );
        }
      },
      {
        headerName: "ADDED BY",
        headerTooltip: "ADDED BY",
        menuTabs: [],
        sortable: false,
        valueGetter: (params: any) => {
          const authorizerDetails = getCIQAUthorizerDetails(
            params.data.properties
          );
          const emailStr = authorizerDetails.email
            ? `(${authorizerDetails.email})`
            : "";
          return `${authorizerDetails.name} ${emailStr}`;
        }
      }
    ];
  }, []);

  const availableIntegrationOptions = useMemo(() => {
    if (
      !sharedState?.availableIntegrations ||
      !sharedState?.subscriptionIntegrationConfigs?.length
    )
      return [
        {
          value: 1,
          label: IntegrationSystemNameMap.AUTODESK,
          subscriptionIntgConfig: null
        },
        {
          value: 5,
          label: IntegrationSystemNameMap.BIM360,
          subscriptionIntgConfig: null
        }
      ];
    return sharedState?.availableIntegrations
      .filter((opt: any) => activeSourceTypes.includes(opt?.system))
      .sort((intA: any, intB: any) => (intA?.system > intB?.system ? 1 : -1))
      .map((availableIntegration: any) => {
        const foundSubscriptionIntgConfig =
          sharedState.subscriptionIntegrationConfigs.find(
            (sIntgConfig: any) => {
              return (
                sIntgConfig.integration.system === availableIntegration.system
              );
            }
          );

        return {
          value: availableIntegration.id,
          label: IntegrationSystemNameMap[availableIntegration.system],
          subscriptionIntgConfig: foundSubscriptionIntgConfig || null
        };
      });
  }, [
    sharedState?.availableIntegrations,
    sharedState.subscriptionIntegrationConfigs
  ]);

  const selectedIntegrationType = Form.useWatch("integrationType", form);
  const selectedIntegrationMethod = Form.useWatch("integrationMethod", form);

  useEffect(() => {
    if (selectedIntegrationType === IntegrationType.PROCORE) {
      const foundProcoreConfig = sharedState.systemIntegrationConfigs.find(
        (config: any) => {
          return config.system === IntegrationType[IntegrationType.PROCORE];
        }
      );

      form.setFieldsValue({
        root_url:
          foundProcoreConfig.integration_config_properties.PROCORE_ROOT_URL,
        login_url:
          foundProcoreConfig.integration_config_properties.PROCORE_LOGIN_URL
      });
    }
  }, [form, selectedIntegrationType, sharedState.systemIntegrationConfigs]);

  const onManageIntegrationClick = async (data: any) => {
    dispatch({
      type: "SET_SHARED_STATE",
      payload: {
        activeView: "details",
        system: data.integration.system,
        additionalData: data
      }
    });
  };

  const getIntegrationSourceType = (integrationType: IntegrationType) => {
    switch (integrationType) {
      case IntegrationType.AUTODESK:
        return "autodesk";
      case IntegrationType.CMIC:
        return "cmic";
      case IntegrationType.BIM360:
        return "bim360";
      default:
        return "";
    }
  };

  const saveConfig = async (values: any) => {
    const payload: any = {
      integration_name: values?.integrationName ?? ""
    };

    const integrationSourceType = getIntegrationSourceType(
      values.integrationType
    );
    const integrationMethod = selectedIntegrationMethod ?? "file";

    const saveResponse = await saveACCIntegrationConfig(
      payload,
      tokenRetrievalState.token,
      integrationMethod,
      integrationSourceType
    );
    if (saveResponse.success) {
      if (checked) {
        window.open(`/project/${projectId}/submittals`, "_blank");
      }
      setOpenAddIntegrationPanel(false);
      setAddingIntegration(false);
    }
  };

  const onFormSubmit = async (values: any) => {
    setAddingIntegration(true);

    if (values.integrationType === IntegrationType.AUTODESK) {
      if (selectedIntegrationMethod === "file") {
        saveConfig(values);
        return;
      }
      const OAuthURLResponse = await getOAuthUrl(
        IntegrationType[values.integrationType],
        {},
        tokenRetrievalState?.token
      );

      setAddingIntegration(false);

      if (OAuthURLResponse.success) {
        const authResponse: any = await initAuthProcess(OAuthURLResponse.data);
        // console.log("authResponse ", authResponse);
        if (
          authResponse?.oauth_status?.status ===
          IntegrationAuthProcessStatuses.PROCESSED
        ) {
          setOpenAddIntegrationPanel(false);
          dispatch({
            type: "SET_SHARED_STATE",
            payload: {
              activeView: "configure-initial",
              system: authResponse.source_type.toUpperCase(),
              additionalData: {
                newIntegrationName: form.getFieldValue("integrationName")
              }
            }
          });
        } else {
          const error = authResponse.output?.error || "Authorization failed";
          message.error(error);
        }
      }
    }

    if (values.integrationType === IntegrationType.PROCORE) {
      dispatch({
        type: "SET_SHARED_STATE",
        payload: {
          activeView: "configure-initial",
          system: IntegrationType[IntegrationType.PROCORE],
          additionalData: {
            newIntegrationName: form.getFieldValue("integrationName")
          }
        }
      });
    }

    if (values.integrationType === IntegrationType.BIM360) {
      saveConfig(values);
    }
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <div>
      <div>
        <div className="px-3 py-2 flex items-center justify-between">
          <div className="text-[#3B3B3B] font-semibold text-sm">
            {sharedState?.projectIntegrationConfigs
              ? `${sharedState?.projectIntegrationConfigs.length} `
              : ""}
            INTEGRATIONS
          </div>
          <div>
            {sharedState?.projectIntegrationConfigs &&
            !sharedState?.projectIntegrationConfigs?.length ? (
              <Button
                onClick={() => {
                  setOpenAddIntegrationPanel(true);
                }}
                disabled={!canAddIntegration || addingIntegration}
                title={
                  !canAddIntegration ? ErrorMessages.PermissionNotGranted : ""
                }
              >
                ADD INTEGRATION
              </Button>
            ) : null}
          </div>
        </div>
        <div className="h-[500px] ag-theme-alpine relative">
          {canAddIntegration &&
            sharedState.subscriptionIntegrationConfigs &&
            sharedState?.projectIntegrationConfigs &&
            !sharedState?.projectIntegrationConfigs.length && (
              <ProjectIntegrationListMessage
                onConfigureClick={() => {
                  setOpenAddIntegrationPanel(true);
                }}
              />
            )}
          <AgGridReact<any>
            ref={gridRef}
            onFirstDataRendered={() => {
              gridRef.current!.api.sizeColumnsToFit();
            }}
            rowData={sharedState?.projectIntegrationConfigs}
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows // Optional - set to 'true' to have rows animate when sorted
            loadingOverlayComponent={GridLoadingIndicator}
            suppressDragLeaveHidesColumns
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            suppressContextMenu
            suppressRowDrag
            suppressMovableColumns
            context={{
              onManageIntegrationClick
            }}
            suppressNoRowsOverlay
          />
        </div>
      </div>

      <Modal
        title="ADD INTEGRATION"
        width={420}
        style={{
          right: 0,
          bottom: 0,
          top: 40,
          padding: 0,
          position: "absolute",
          overflow: "hidden"
        }}
        bodyStyle={{
          height: "calc(100vh - 92px)",
          padding: "10px 0px 10px 0px"
        }}
        footer={null}
        open={openAddIntegrationPanel}
        onCancel={() => {
          setOpenAddIntegrationPanel(false);
          form.resetFields();
        }}
        destroyOnClose
        maskClosable={false}
        keyboard={false}
      >
        <div className="px-3 pb-3 h-full">
          <Form
            form={form}
            layout="vertical"
            className="h-full overflow-y-auto"
            onFinish={onFormSubmit}
            disabled={addingIntegration}
          >
            <div className="h-full flex flex-col">
              <div className="grow">
                <div>
                  <Form.Item
                    name="integrationName"
                    label="Integration Name"
                    required
                    rules={[
                      { required: true, message: "Enter Integration Name" }
                    ]}
                  >
                    <Input placeholder="Enter Integration Name" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="integrationType"
                    label="Source Application"
                    required
                    rules={[
                      { required: true, message: "Select source application" }
                    ]}
                  >
                    <Select
                      placeholder="Select Source Application"
                      notFoundContent={
                        <div>
                          Go to{" "}
                          <Button
                            size="small"
                            type="text"
                            className="font-medium workflow-document-upload"
                            onClick={() => {
                              history.push("/settings/integrations");
                            }}
                          >
                            Subscription settings
                          </Button>{" "}
                          to add integrations
                        </div>
                      }
                      onChange={(value) => {
                        setChecked(false);

                        const integration: any =
                          availableIntegrationOptions.find(
                            (opt: any) => opt.value === value
                          );

                        if (
                          !integration.subscriptionIntgConfig &&
                          integration.value === 1
                        ) {
                          form.setFieldValue("integrationMethod", "file");
                        } else {
                          form.setFieldValue("integrationMethod", null);
                        }

                        setSubIntConfigured(
                          integration?.subscriptionIntgConfig
                        );
                      }}
                    >
                      {availableIntegrationOptions?.map((option: any) => {
                        return (
                          <Option
                            key={option.value}
                            value={option.value}
                            label={option.label}
                            disabled={
                              !option.subscriptionIntgConfig &&
                              option.label === IntegrationSystemNameMap.PROCORE
                            }
                          >
                            {option.subscriptionIntgConfig ||
                            option.label === IntegrationSystemNameMap.BIM360 ||
                            option.label ===
                              IntegrationSystemNameMap.AUTODESK ? (
                              option.label
                            ) : (
                              <div>
                                <div className="text-gray-400">
                                  {option.label}
                                </div>
                                <div className="text-gray-600">
                                  Go to{" "}
                                  <Button
                                    size="small"
                                    type="text"
                                    className="font-medium workflow-document-upload"
                                    onClick={() => {
                                      history.push("/settings/integrations");
                                    }}
                                  >
                                    Subscription Settings
                                  </Button>{" "}
                                  to add this integration.
                                </div>
                              </div>
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Divider />
                  {selectedIntegrationType === IntegrationType.AUTODESK && (
                    <Form.Item
                      name="integrationMethod"
                      label="Integration Method"
                      className="-mt-1"
                      required
                      rules={[
                        { required: true, message: "Select integration method" }
                      ]}
                    >
                      <Radio.Group className="grid grid-rows-2  px-1 pt-2 space-y-2 ">
                        <Radio value="api" disabled={!isSubIntConfigured}>
                          <div className="font-medium text-sm text-[#555555]">
                            {" "}
                            API-Based (Recommended)
                          </div>
                          <div className="mt-1 font-normal text-xs text-[#000000E0] leading-[22px]">
                            Sync data automatically by connecting your Autodesk
                            Account.
                          </div>
                        </Radio>
                        <Radio value="file">
                          <div className="font-medium text-sm text-[#555555]">
                            File-Based (If API Access not available)
                          </div>
                          <div className="mt-1 font-normal  text-xs text-[#000000E0] leading-[22px]">
                            Manually upload .XLSX or .CSV Exports from your ACC
                            project.
                          </div>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {selectedIntegrationType === IntegrationType.PROCORE && (
                    <div className="pb-2">
                      <div className="mt-2 mb-1 text-xs">
                        <div className="font-semibold mb-1">
                          Contact your Procore Company Admin to follow the below
                          steps before proceeding here:
                        </div>
                        <ol className="m-0 pl-4 space-y-0.5">
                          <li>Go to Procore App Management.</li>
                          <li>Select ConstructivIQ App.</li>
                          <li>Go to Permissions.</li>
                          <li>
                            Add the project which you wish to integrate under{" "}
                            <br />
                            &apos;Permitted Projects&apos;.
                          </li>
                        </ol>
                      </div>
                    </div>
                  )}
                  {/* //TODO: Comment out below code when project name input field comes in picture in feature */}
                  {/* {selectedIntegrationType === IntegrationType.CMIC && (
                    <Form.Item
                      name="cmic_project_name"
                      label="Cmic Project Name"
                      className="-mt-2"
                    >
                      <Input placeholder="Enter CMIC Project Name" />
                    </Form.Item>
                  )} */}
                </div>
              </div>
              {selectedIntegrationType === IntegrationType.AUTODESK && (
                <div>
                  {selectedIntegrationMethod === "api" && (
                    <div className="p-1 flex flex-col text-[#000000CC]">
                      <p className="font-semibold text-xs ">Next Steps:</p>
                      <p className="font-normal text-xs ">
                        Clicking “Next” will redirect you to log in to Autodesk.
                      </p>
                      <p className="font-normal italic text-xs ">
                        {" "}
                        To log in, use the credentials of an Autodesk
                        Construction Cloud Account Admin who is part of at least
                        one Autodesk® Project.
                      </p>
                      <p className="font-normal text-[10px]">
                        Read the{" "}
                        <a
                          href="https://apps.autodesk.com/BIM360/en/Detail/HelpDoc?appId=4545831120489722584&appLang=en&os=Web"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Help Document
                        </a>{" "}
                        for more details.
                      </p>
                    </div>
                  )}
                  {selectedIntegrationMethod === "file" && (
                    <div className="p-1 pb-2 flex flex-col text-xs leading-[14.52px] text-[#000000CC]">
                      <p className="font-semibold">Next Steps:</p>
                      <ul className="m-0 pl-6">
                        <li>
                          Export Submittal Log from Autodesk Construction Cloud.
                        </li>
                        <li>
                          Import the file into the Submittals Module in
                          ConstructivIQ.
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {selectedIntegrationType === IntegrationType.BIM360 && (
                <div className="p-1 pb-2 flex flex-col text-xs leading-[14.52px] text-[#000000CC]">
                  <p className="font-semibold">Next Steps:</p>
                  <ul className="m-0 pl-6">
                    <li>Export Submittal Log from BIM360.</li>
                    <li>
                      Import the file into the Submittals Module in
                      ConstructivIQ.
                    </li>
                  </ul>
                </div>
              )}
              <div>
                <Divider className="mx-0 mt-1 mb-4" />
                <div className="flex justify-end items-center">
                  {(selectedIntegrationType === IntegrationType.CMIC ||
                    selectedIntegrationType === IntegrationType.BIM360 ||
                    selectedIntegrationMethod === "file") && (
                    <Checkbox
                      disabled={addingIntegration}
                      onChange={onChange}
                      className="w-full"
                    >
                      Open Submittals on Save.
                    </Checkbox>
                  )}
                  <div className="flex space-x-4 justify-end">
                    <Button
                      htmlType="reset"
                      className="px-5"
                      onClick={() => {
                        setOpenAddIntegrationPanel(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="px-5"
                      disabled={addingIntegration}
                      loading={addingIntegration}
                    >
                      {selectedIntegrationMethod === "file" ||
                      selectedIntegrationType === IntegrationType.BIM360
                        ? "Save"
                        : "Next"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default ProjectIntegrationsList;
