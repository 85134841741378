import { useLazyQuery, useQuery } from "@apollo/client";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  IdLinkComponent,
  SubmittalReportDetailsCellRenderer,
  SubmittalStatusCellRenderer,
  responsibleContractorCellRenderer,
  specSectionRenderer,
  userOrgCellRenderer
} from "components/cell-renders";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  useIntegrationProjectParticipants,
  useProjectParticipants
} from "hooks/project-participants";
import { updateSubmittalList } from "pages/submittals-list/helpers";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useParams } from "react-router";

import {
  QUERY_SUBMITTAL_RISK_REPORT_INTG,
  QUERY_SUBMITTAL_RISK_REPORT_WF,
  QUERY_SUBMITTAL_TYPES
} from "services/graphQL/queries";
import { Button, Dropdown, Input, Menu, Popover } from "antd";
import { IconSearch } from "@tabler/icons-react";
import { StartPrinting } from "utils/ag-grid-print-utils";
import { getUser } from "services/auth";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { MUTATION_UPDATE_AUDIT_EXPORTED_LOGS } from "services/graphQL/mutations";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { getSubmittalFormattedValues } from "pages/reports-menu/upcoming-workflows/export-csv-util";
import {
  AcceptanceStatus,
  AuditLogFeatures,
  AuditLogFeatureType,
  ESubmittalStatus,
  InfoMessages,
  ReportTitles,
  RiskStates,
  SubmittalStatusToStrMap
} from "../../constants";
import { DownloadReport } from "../../services/export-reports/download-report";
import { useCiqSubmittalRiskReportList } from "./hooks/risk-report";

type Props = {
  risk: RiskStates;
  isIntegrationMode: boolean;
  printMode?: boolean;
};

function SubmittalRiskDetail(props: Props) {
  const { risk, isIntegrationMode, printMode } = props;

  const { projectId } = useParams() as any;

  const gridRef = useRef<AgGridReact<any>>(null);

  const [gridData, setGridData] = useState<{
    submittals?: any;
  }>({
    submittals: null
  });

  const [gridSearchtext, setGridSearchtext] = useState("");

  const [expandedItemIds, setExpandedItemIds] = useState<string[]>([]);

  const [disableExport, setDisableExport] = useState(false);

  const {
    gqlClientForProject,
    projectDetails,
    eventLogs,
    columnHeaders: { submittalHeaderMap }
  }: TProjectContext = useContext(ProjectContext);

  const { projectParticipants: WFProjectParticipants } =
    useProjectParticipants(isIntegrationMode);

  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode
  );

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  const SUBMITTAL_RISK_REPORT = isIntegrationMode
    ? QUERY_SUBMITTAL_RISK_REPORT_INTG
    : QUERY_SUBMITTAL_RISK_REPORT_WF;

  const { data: submittalRiskData } = useCiqSubmittalRiskReportList({
    gqlClientForProject,
    isIntegrationMode,
    variables: {
      where: {
        risk_level: { _eq: risk },
        workflow_status: { _neq: ESubmittalStatus.VOID }
      }
    }
  });

  const [getLazySubmittalRiskData] = useLazyQuery(SUBMITTAL_RISK_REPORT, {
    client: gqlClientForProject,
    fetchPolicy: "network-only"
  });

  const [saveExportAuditLogEvent] = useCIQMutation(
    MUTATION_UPDATE_AUDIT_EXPORTED_LOGS,
    {
      client: gqlClientForProject
    }
  );

  useEffect(() => {
    if (!submittalRiskData) return;
    setGridData({
      submittals: isIntegrationMode
        ? submittalRiskData.submittal_cumulative_risk_report_intg_mode_func
        : submittalRiskData.submittal_cumulative_risk_report_wf_mode_func
    });
  }, [isIntegrationMode, submittalRiskData]);

  useEffect(() => {
    if (gridData.submittals) {
      setTimeout(() => {
        gridRef.current?.api.refreshCells({
          force: true,
          columns: ["assignee"]
        });
      }, 0);
    }
  }, [gridData.submittals]);

  const deleteSubmittalsFromList = useCallback(
    (ids: Array<string>) => {
      const items = gridData.submittals ? [...gridData.submittals] : [];
      ids.forEach((id) => {
        const index = items.findIndex((x) => x.id === id);
        items.splice(index, 1);
      });
      setGridData({
        submittals: items
      });
    },
    [gridData.submittals]
  );

  const getAndUpdateSubmittalList = useCallback(
    async (submittalIds: Array<string>) => {
      if (!submittalIds.length) return;
      const variables =
        submittalIds.length > 10
          ? {}
          : { where: { id: { _in: submittalIds } } };

      const res = await getLazySubmittalRiskData({
        variables,
        fetchPolicy: "network-only"
      });
      if (res.data) {
        const updatedSubmittalList = isIntegrationMode
          ? res.data.submittal_cumulative_risk_report_intg_mode_func
          : res.data.submittal_cumulative_risk_report_wf_mode_func;

        if (updatedSubmittalList.length === 0) {
          deleteSubmittalsFromList(submittalIds);
          return;
        }

        const items = [...gridData.submittals] as Array<any>;
        updatedSubmittalList.forEach((item: any) => {
          const foundIndex = items.findIndex((x) => x.id === item.id);
          if (foundIndex === -1) items.push(item);
          items[foundIndex] = item;
        });
        setGridData({
          submittals: items
        });
      }
    },
    [
      deleteSubmittalsFromList,
      getLazySubmittalRiskData,
      gridData.submittals,
      isIntegrationMode
    ]
  );

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const conditionForSubmittalRiskLog = (log: TAuditChangeLog) =>
        // log.info.computation === true &&
        log?.info?.submittal_ids && log?.info?.submittal_ids?.length > 0;

      if (eventLogs.some(conditionForSubmittalRiskLog)) {
        const auditLogs = eventLogs.filter(
          (log: TAuditChangeLog) => log.info.submittal_ids
        ) as Array<TAuditChangeLog>;

        const deletedIds = auditLogs
          .filter(
            (log) =>
              log.change_type === "delete" && log.data_source === "submittals"
          )
          .map((s: TAuditChangeLog) => s.info.submittal_ids!) as any;
        if (deletedIds.length) {
          const deletedDArray = [...new Set([].concat([], ...deletedIds))];
          deleteSubmittalsFromList(deletedDArray);
        }

        const ids = auditLogs
          .filter(
            (log) =>
              log.change_type === "update" &&
              (log.data_source === "date_block" ||
                log.data_source === "submittals")
          ) // update create import
          .map((s) => s.info.submittal_ids) as any;

        if (ids.length) {
          const singleDArray = [...new Set([].concat([], ...ids))];
          getAndUpdateSubmittalList(singleDArray);
        }
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    eventLogs,
    deleteSubmittalsFromList,
    isIntegrationMode,
    getAndUpdateSubmittalList
  ]);

  const { data: submittalTypes } = useQuery(QUERY_SUBMITTAL_TYPES, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const typesMap = useMemo(() => {
    if (submittalTypes) {
      const tmpTypesMap: any = {};
      submittalTypes.configurations_value.forEach((type: any) => {
        tmpTypesMap[type.id] = type;
      });
      return tmpTypesMap;
    }
    return null;
  }, [submittalTypes]);

  const statusesMap: any = useMemo(() => {
    const tmpStatusesMap: any = {};

    Object.keys(SubmittalStatusToStrMap).forEach((keyStatusId: any) => {
      tmpStatusesMap[keyStatusId] = {
        name: SubmittalStatusToStrMap[keyStatusId]
      };
    });
    return tmpStatusesMap;
  }, []);

  const submittalListTableData: any = useMemo(() => {
    if (!gridData.submittals || !projectParticipants.allProjectUsersMap)
      return null;

    const updatedSubmittalList = updateSubmittalList(
      gridData.submittals,
      projectParticipants
    );
    return updatedSubmittalList;
  }, [projectParticipants, gridData.submittals]);

  const defaultColDef: {} = useMemo(() => {
    const defaultCol: any = {
      sortable: true,
      editable: false,
      filter: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
      sortingOrder: ["asc", "desc"],
      suppressColumnsToolPanel: false
    };
    if (printMode) {
      defaultCol.maxWidth = 155;
    }
    return defaultCol;
  }, [printMode]);

  useEffect(() => {
    gridRef.current?.api?.refreshCells({ force: true });
  }, [gridData.submittals, projectParticipants]);

  const submittalListIds = useMemo(() => {
    if (!submittalListTableData) return [];
    return submittalListTableData.map((submittal: any) => {
      return submittal.id;
    });
  }, [submittalListTableData]);

  // console.log("submittalListIds ", submittalListIds);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        colId: "submittal_id",
        field: "submittal_id",
        headerName: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        filter: false,
        menuTabs: [],
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: {
          innerRenderer: IdLinkComponent,
          projectId
        },
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
          if (projectDetails?.spec_section) {
            if (nodeA.data.spec_no && nodeB.data.spec_no) {
              const specnoA = Number(nodeA.data.spec_no.replace(/ /g, ""));
              const specnoB = Number(nodeB.data.spec_no.replace(/ /g, ""));
              if (nodeA.data.spec_no === nodeB.data.spec_no) {
                if (valueA === valueB) {
                  return 0;
                }
                return valueA > valueB ? 1 : -1;
              }
              return specnoA > specnoB ? 1 : -1;
            }

            if (valueA === valueB) {
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          }

          if (valueA === valueB) {
            return 0;
          }

          return valueA > valueB ? 1 : -1;
        },
        tooltipValueGetter: (params: any) => {
          const cellText =
            params.context.projectDetails?.spec_section && params.data.spec_no
              ? `${params.data.spec_no} - ${params.value}`
              : `${params.value}`;
          return cellText;
        }
      },
      {
        colId: "custom_spec_section",
        headerName: submittalHeaderMap?.spec_no?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.spec_no?.toUpperCase() || "",
        cellRenderer: specSectionRenderer,
        cellRendererParams: {
          specNoField: "spec_no",
          specNameField: "spec_name",
          projectId
        },
        valueGetter: (params: any) => {
          try {
            let specSectionStr = params.data.spec_no || "";
            if (params.data.spec_name) {
              specSectionStr += ` - ${params.data.spec_name}`;
            }
            return specSectionStr;
          } catch (ex) {
            return "";
          }
        },
        comparator: (valueA: string | null, valueB: string | null) => {
          if (!valueA) return 1;
          if (!valueB) return -1;
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        tooltipValueGetter: (params) => params.value
      },
      {
        colId: "title",
        field: "title",
        headerName: submittalHeaderMap?.title?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.title?.toUpperCase() || "",
        tooltipField: "title"
      },
      {
        colId: "type",
        field: "type",
        headerName: submittalHeaderMap?.type?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.type?.toUpperCase() || "",
        keyCreator: (params: any) => params.value,
        getQuickFilterText: (params: any) => params.value,
        tooltipValueGetter: (params: any) => params.value
      },
      {
        colId: "responsible_contractor",
        field: "responsible_contractor_id",
        headerName:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
        headerTooltip:
          submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
        sort: "asc",
        cellRenderer: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.cellMarkup;
        },
        valueGetter: (params: any) => {
          const data = responsibleContractorCellRenderer(params);
          return data.orgName;
        },
        tooltipValueGetter: (params) => params.value,
        cellStyle: (params) => {
          if (params.data.status_id === AcceptanceStatus.ACTIVE) {
            return { opacity: 0.5 };
          }
          return { opacity: 1.0 };
        }
      },
      {
        colId: "assignee",
        headerName: submittalHeaderMap?.assignee?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.assignee?.toUpperCase() || "",
        cellRenderer: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.cellMarkup;
        },
        cellRendererParams: {
          userInput: {
            id: "assignee_user_id",
            firstName: "assignee_first_name",
            lastName: "assignee_last_name",
            org: "assignee_org",
            unregistered_user: "assignee_unregistered",
            unregistered_user_org: "assignee_unregistered_org",
            statusKey: "assignee_status"
          }
        },
        valueGetter: (params: any) => {
          const data = userOrgCellRenderer(params);
          return data.fullName;
        },
        tooltipValueGetter: (params) => params.value,
        cellStyle: (params) => {
          if (params.data.status_id === AcceptanceStatus.ACTIVE) {
            return { opacity: 0.5 };
          }
          return { opacity: 1.0 };
        }
      },
      {
        colId: "status",
        field: "workflow_status",
        headerName: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        maxWidth: 161,
        cellRenderer: SubmittalStatusCellRenderer,
        cellRendererParams: {
          dataMapVar: "statusesMap",
          valueVar: "id",
          labelVar: "name"
        },
        tooltipValueGetter: (params: any) => {
          return params.context.statusesMap[params.value].name;
        }
      },
      {
        colId: "risk_level",
        field: "risk_level",
        headerName: "RISK",
        hide: true
      },
      {
        colId: "risk_assessment",
        headerName: "RISK ASSESSMENT",
        hide: true
      },
      {
        colId: "linked_materials",
        field: "submittal_material_links",
        headerName: "IMPACTED MATERIALS",
        hide: true
      },
      {
        colId: "linked_tasks",
        field: "submittal_schedule_links",
        headerName: "IMPACTED ACTIVITIES",
        hide: true
      }
    ];
  }, [projectDetails?.spec_section, projectId, submittalHeaderMap]);

  const expandCollapseAll = useCallback(() => {
    let tmpExpandedItemIds = [];
    if (
      expandedItemIds.length &&
      expandedItemIds.length === submittalListIds.length
    ) {
      tmpExpandedItemIds = [];
    } else {
      tmpExpandedItemIds = submittalListIds;
    }

    gridRef.current!.api.forEachNode((node: any) => {
      // eslint-disable-next-line no-param-reassign
      node.expanded = !!tmpExpandedItemIds.length;
    });
    gridRef.current!.api.onGroupExpandedOrCollapsed();

    setExpandedItemIds(tmpExpandedItemIds);
  }, [expandedItemIds.length, submittalListIds]);

  const saveExportAuditLog = useCallback(async () => {
    const payload: any = {
      variables: {
        eventType: AuditLogFeatureType.LOG_EXPORTED,
        feature: AuditLogFeatures.SUBMITTAL_RISK_REPORT
      }
    };
    await saveExportAuditLogEvent(payload);
  }, [saveExportAuditLogEvent]);

  const exportAsPDF = useCallback(async () => {
    setDisableExport(true);

    const res: any = await DownloadReport(
      `/project/${projectId}/reports/submittals/export?accessToken=#{accessToken}&subscriptionId=#{subscriptionId}`,
      ReportTitles.submittalsRiskReport,
      getUser(),
      projectDetails
    );
    if (res?.data) {
      saveExportAuditLog();
    }

    setDisableExport(false);
  }, [projectDetails, projectId, saveExportAuditLog]);

  const gridContext: any = useMemo(() => {
    return {
      projectDetails,
      typesMap,
      statusesMap,
      isIntegrationMode,
      projectParticipants
    };
  }, [
    isIntegrationMode,
    projectDetails,
    projectParticipants,
    statusesMap,
    typesMap
  ]);

  const detailCellRenderer = useMemo(() => {
    return SubmittalReportDetailsCellRenderer;
  }, []);

  useEffect(() => {
    gridRef.current?.api?.setQuickFilter(gridSearchtext);
  }, [gridSearchtext]);

  /**
   * Start the jsreport print after finishing the loading of all records in ag-grid.
   *
   * Here we are trying to get the number of rows from the Ag-Grid which is rendered and
   * the number of record from the data. Once it is matched it means grid
   * has been rendered all records. After this condition match starting the print.
   */
  useEffect(() => {
    let intervalTimer: string | number | NodeJS.Timeout | undefined;
    if (printMode) {
      const totalRecords = submittalListTableData?.length;
      // eslint-disable-next-line prefer-const
      intervalTimer = setInterval(() => {
        let totalRowCount = gridRef.current?.api?.getDisplayedRowCount() || 0;
        totalRowCount /= 2; // there is grouping of row hence devide by two.

        // Stop the timer once all records are diplayed in the grid row.
        if (totalRowCount === totalRecords) {
          clearInterval(intervalTimer);
          setTimeout(StartPrinting, 100);
        }
      }, 500);
    }
    return () => clearInterval(intervalTimer);
  }, [submittalListTableData, printMode, submittalListIds]);

  const updateExpandedItems = (itemId: string) => {
    if (expandedItemIds.includes(itemId)) {
      const itemRemovedArray: any = expandedItemIds.filter(
        (id: any) => id !== itemId
      );
      setExpandedItemIds(itemRemovedArray);
    } else {
      const clonedExpIds = [...expandedItemIds];
      clonedExpIds.push(itemId);
      setExpandedItemIds(clonedExpIds);
    }
  };

  const exportPdfButton = useMemo(() => {
    const exportBtnDisabled =
      !submittalListTableData || !submittalListTableData.length;

    if (exportBtnDisabled)
      return (
        <Menu.Item onClick={exportAsPDF} disabled={exportBtnDisabled}>
          <span>{disableExport ? "Exporting" : "Export PDF"}</span>
        </Menu.Item>
      );

    const popoverContent = (
      <div className="w-[310px]">{InfoMessages.exportReportToPdfTooltip}</div>
    );
    return (
      <Menu.Item onClick={exportAsPDF} disabled={exportBtnDisabled}>
        <Popover content={popoverContent} trigger="hover" placement="topRight">
          <span>{disableExport ? "Exporting" : "Export PDF"}</span>
        </Popover>
      </Menu.Item>
    );
  }, [disableExport, exportAsPDF, submittalListTableData]);

  const exportLogAsCSV = () => {
    const columnApi = gridRef.current?.columnApi;
    const additionalColumns = [
      "risk_level",
      "risk_assessment",
      "linked_materials",
      "linked_tasks"
    ];
    if (gridRef.current && columnApi)
      gridRef.current?.api?.exportDataAsCsv({
        columnKeys: columnApi
          .getAllGridColumns()
          ?.filter((col) => {
            const colId = col.getColId();
            return col.isVisible() || additionalColumns?.includes(colId);
          })
          .map((col) => col.getColId()),
        processCellCallback(params: any): string {
          return getSubmittalFormattedValues(params);
        },
        fileName: `Submittals at ${risk} Risk_${projectDetails?.name}.csv`
      });
  };

  return (
    <div className="pl-0.5">
      <div className="mt-3 flex items-end justify-between">
        <div className="uppercase font-semibold">SUBMITTALS AT {risk} RISK</div>
        <div className="flex items-center gap-x-3">
          <div className="w-[225px] py-1 hide-on-print">
            <Input
              type="text"
              placeholder="Search Submittals"
              prefix={<IconSearch size={14} />}
              onChange={(event) => {
                setGridSearchtext(event.target.value);
              }}
              allowClear
              disabled={
                !submittalListTableData || !submittalListTableData.length
              }
            />
          </div>
          <Button
            onClick={() => {
              expandCollapseAll();
            }}
            className="w-[110px] hide-on-print"
            disabled={!submittalListTableData || !submittalListTableData.length}
          >
            {expandedItemIds.length &&
            expandedItemIds.length === submittalListIds.length ? (
              <span>Collapse All</span>
            ) : (
              <span>Expand All</span>
            )}
          </Button>

          <Dropdown
            className="w-[110px]  hide-on-print"
            overlay={
              <Menu>
                {exportPdfButton}
                <Menu.Item
                  onClick={exportLogAsCSV}
                  disabled={
                    !submittalListTableData || !submittalListTableData.length
                  }
                >
                  Export CSV
                </Menu.Item>
              </Menu>
            }
            placement="bottom"
          >
            <Button loading={disableExport}>Export</Button>
          </Dropdown>
        </div>
      </div>
      <div className="mt-2">
        <div
          style={{ height: `${printMode ? "auto" : "calc(100vh - 340px)"}` }}
        >
          <AgGridReact
            ref={gridRef}
            className="ag-theme-alpine"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={submittalListTableData}
            headerHeight={36}
            rowHeight={62}
            pagination={false}
            context={gridContext}
            suppressPaginationPanel
            suppressContextMenu
            masterDetail
            detailCellRenderer={detailCellRenderer}
            domLayout={printMode ? "print" : "normal"}
            groupDefaultExpanded={printMode ? 1 : 0}
            onRowGroupOpened={(event: any) => {
              updateExpandedItems(event.data.id);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SubmittalRiskDetail;
