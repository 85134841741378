/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Collapse,
  Form,
  message,
  Modal,
  Select,
  Switch,
  Tooltip,
  Upload,
  Input
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./submittal-details.css";
import {
  AttachmentDoc,
  SubmittalLog,
  SubmittalTypeType
} from "models/submittal-log";
import AddCompany from "components/add-company";
import { InviteProjectUser } from "pages/invite-user/invite-subscription-user";
import SelectNotFoundContent from "components/widgets/select-notfound-content";
import { useHistory, useParams } from "react-router";
import {
  isPermissionNotGrantted,
  ProjectContext,
  ProjectSettingType
} from "context/ProjectProvider";
import { getLoggedinUserId } from "services/auth";
import DistributionList from "components/distribution-list-submittal/dl-submittal-details";
import {
  getUserCompany,
  isProductionEnv,
  matchUserProjectRole
} from "utils/utils";
import DocumentListWidget from "components/document-list-widget";
import { LinkOutlined } from "@ant-design/icons";
import { ProjectParticipants } from "hooks/project-participants";
import ErrorBoundary from "components/error-boundary";
import SpecNumberNameDropDown from "components/spec-number-name";
import { MUTATION_UPDATE_MATERIAL_PK } from "services/graphQL/mutations";
import classNames from "classnames";
import SubmittalLinks from "components/links/submittal-links/submittal-links";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import AsterixIcon from "components/svg-icons/asterix-icon";
import DateBlockDynamicUIPanel from "components/date-block/full-date-block";
import { Link } from "react-router-dom";
import DateBlockSubmittalImpactVisualisation from "components/date-block/submittal-impact-visualise";
import { GoverningTaskType } from "components/date-block/models";
import FileViewerModal from "components/file-viewer-modal/file-viewer-modal";
import SubmittalInsights from "pages/submittal-details/submittal-insights/submittal-insights";
import SubmittalInsightsIcon from "components/svg-icons/submittal-insights-icon";
import UserTag from "components/user-tag/user-tag";
import {
  GET_SUBMITTAL_INSIGHTS,
  PROJECT_INSIGHTS_CONFIG
} from "services/graphQL/ciq-queries";
import { useShowDesignAndSpec } from "hooks/common";
import SubmittalInsightsDisabledIcon from "components/svg-icons/submittal-insights-disabled-icon";
import { useCIQMutation, useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  AcceptanceStatus,
  DialogTitles,
  ErrorMessages,
  ESubmittalStatus,
  EUserRoleName,
  EUserTypes,
  ProjectPermissionEnum
} from "../../constants";
import { getEditableFields } from "./helpers";
import { SubmittalHistory } from "./submittal-history";
import CreateSpecSectionModal from "./create-spec-section";
import AdvanceAssignModal from "./advance-assign-modal";
import ReadOnlyGeneralInfo from "./cards/readonly-general-info";
import ReadOnlyWorkflowCard from "./cards/readonly-workflow";
import ReviewStatusCard from "./cards/review-status-card";
import "ant-design-draggable-modal/dist/index.css";

const { Option } = Select;
const { Panel } = Collapse;

export type SubmittalDetailsProps = {
  gqlClientForProject: any;
  submittalData: SubmittalLog;
  documents: Array<AttachmentDoc>;
  allAttachments: Array<AttachmentDoc>;
  submittalTypesOptions: Array<SubmittalTypeType>;
  updateSubmittalOne: any;
  updateSubmittalById: any;
  UploadAttachment: Function;

  selectAttachmentItem: any;
  setTabIndex: any;
  submittalViewState: {
    status: number;
    userType: string;
  };
  submittalHistory: any;
  submittalWorkflowResponses: any;
  findLatestAttachmentVersion: (doc: AttachmentDoc) => AttachmentDoc;
  projectDetails: ProjectSettingType | undefined;
  isRevisionMode: boolean;
  submittalTitlePanel: JSX.Element;
  workflowOverride: {
    isWorkflowInOverrideMode: boolean;
    setIsWorkflowInOverrideMode: Function;
  };
  projectParticipants: ProjectParticipants;
  isIntegrationMode: boolean;
  subscriptionSubmittalData: any;
  submittalHeaderMap: any;
  refetchSubmittalPK: Function;
};

export const priorities = [
  { label: "Low", value: "Low" },
  { label: "Normal", value: "Normal" },
  { label: "High", value: "High" }
];

function SubmittalDetails(props: SubmittalDetailsProps) {
  const {
    gqlClientForProject,
    submittalData,
    documents,
    allAttachments,
    submittalTypesOptions,
    updateSubmittalOne,
    updateSubmittalById,
    UploadAttachment,
    selectAttachmentItem,
    setTabIndex,
    submittalViewState,
    submittalHistory,
    submittalWorkflowResponses,
    findLatestAttachmentVersion,
    projectDetails,
    isRevisionMode,
    submittalTitlePanel,
    workflowOverride,
    projectParticipants,
    isIntegrationMode,
    subscriptionSubmittalData,
    submittalHeaderMap,
    refetchSubmittalPK
  } = props;

  const history = useHistory();
  const { projectId } = useParams() as any;
  const { tokenContents } = useContext(ProjectContext);
  const [modal, contextHolder] = Modal.useModal();

  const [updatedData, setUpdatedData] = useState<SubmittalLog>(submittalData);
  const [, setFileLoading] = useState<boolean>(false);

  const [isAddCompanyDrawerOpen, setIsAddCompanyDrawerOpen] = useState(false);
  const [isAddSubProjectUserDrawerOpen, setIsAddSubProjectUserDrawerOpen] =
    useState(false);

  const [showCreateSpecSectionModal, setShowCreateSpecSectionModal] =
    useState(false);

  const [showAdvAssignModal, setShowAdvAssignModal] = useState(false);
  const [isShowImpactDateBlock, setShowImpactDateBlock] = useState(false);
  const [showSpecSectionFileModal, setShowSpecSectionFileModal] =
    useState(false);
  const [showSubmittalInsightsModal, setShowSubmittalInsightsModal] =
    useState(false);
  const [submittalType, setSubmittalType] = useState<string>("");
  const { isWorkflowInOverrideMode } = workflowOverride;
  const { isDesignTabEnabled } = useShowDesignAndSpec();

  const [isSubmittalVoid, setIsSubmittalVoid] = useState<boolean>(false);

  const loggedInUserId = useMemo(() => {
    return getLoggedinUserId();
  }, []);

  useEffect(() => {
    setSubmittalType(submittalData?.raw_type || "");
    setUpdatedData(submittalData);
  }, [submittalData]);

  useEffect(() => {
    setIsSubmittalVoid(updatedData?.workflow_status === ESubmittalStatus.VOID);
  }, [updatedData]);

  const isLoggedInUserGcAdmin =
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin];

  const isLoggedInUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const isLoggedInUserSC = matchUserProjectRole(
    EUserTypes.SUBCONTRACTOR,
    tokenContents?.role
  );

  const isLoggedInUserArchitect = matchUserProjectRole(
    EUserTypes.ARCHITECT,
    tokenContents?.role
  );

  const isLoggedInUserEngineer = matchUserProjectRole(
    EUserTypes.ENGINEER,
    tokenContents?.role
  );

  const isLoggedInUserOwner = matchUserProjectRole(
    EUserTypes.OWNER,
    tokenContents?.role
  );

  const selectedSubmitterUserId =
    subscriptionSubmittalData?.submitter ||
    subscriptionSubmittalData?.submitter_unregistered;

  const selectedDesignReviwerId =
    subscriptionSubmittalData?.design_reviewer ||
    subscriptionSubmittalData?.design_reviewer_unregistered;

  const isSubmittalInsightsEnabled = !isProductionEnv() && isLoggedInUserGC;

  const { data: submittalInsightsConfig } = useCIQQuery(
    PROJECT_INSIGHTS_CONFIG,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject || isProductionEnv() || !isLoggedInUserGC
    }
  );

  const specSectionNumberRegex = `(\\s*)${
    submittalData?.spec_no || "".split("").join("(\\s*)")
  }(\\s*)`;

  const { data: submittalInsightsData } = useCIQQuery(GET_SUBMITTAL_INSIGHTS, {
    variables: {
      where: { spec_section_number: { _iregex: specSectionNumberRegex } }
    },
    client: gqlClientForProject,
    skip:
      !gqlClientForProject ||
      isProductionEnv() ||
      !submittalData?.spec_no ||
      !isLoggedInUserGC
  });

  const editableFields: string[] = useMemo(() => {
    return getEditableFields(submittalViewState, updatedData, tokenContents);
  }, [submittalViewState, updatedData, tokenContents]);

  // console.log("editableFields ", editableFields);

  const fileUploadRequest = async ({ file, onSuccess }: any) => {
    console.log(file);
    setFileLoading(true);
    const res = await UploadAttachment({ file, onSuccess });
    console.log(res);
    setFileLoading(false);
  };

  const saveFieldChange = async (data: any) => {
    const prevData: any = { ...submittalData };
    // const newUpdatedData = { ...submittalData, ...data };
    const newUpdatedData = Object.assign(submittalData, data);
    setUpdatedData(newUpdatedData);

    try {
      const variables: any = {
        id: submittalData.id,
        submittalValues: data
      };

      const updateResponse: any = await updateSubmittalById({
        variables
      });

      if (updateResponse.errors) {
        modal.error({ content: updateResponse.errors[0].message });
        setUpdatedData({ ...updatedData, [data.field]: prevData[data.field] });
      } else {
        message.success("Updated successfully");
      }

      // const updateResponseMsg =
      //   updateResponse.data.update_submittal_one.message;

      // if (updateSubmittalSuccessResp.includes(updateResponseMsg)) {
      //   message.success(updateResponseMsg);
      // }

      // if (updateSubmittalErrResp.includes(updateResponseMsg)) {
      //   modal.error({ content: updateResponseMsg });
      //   setUpdatedData({ ...updatedData, [data.field]: prevData[data.field] });
      // }
      return updateResponse;
    } catch (ex) {
      console.log("ex ", ex);
      modal.error({ content: "An error occured" });
      setUpdatedData(prevData);
      return ex;
    }
  };

  const setDrawerAction = (drawerAction: boolean) => {
    setIsAddCompanyDrawerOpen(drawerAction);
    // setAddTradePartnerDrawerOpen(drawerAction);
    setIsAddSubProjectUserDrawerOpen(drawerAction);
  };

  const isLoggedInUserMainInWorkflow =
    loggedInUserId === submittalData.gc_reviewer ||
    loggedInUserId === submittalData.design_reviewer ||
    loggedInUserId === submittalData.submitter;

  // console.log("isLoggedInUserMainInWorkflow ", isLoggedInUserMainInWorkflow);

  const showReviewOption =
    (isLoggedInUserMainInWorkflow || isLoggedInUserGC) &&
    !isLoggedInUserSC &&
    submittalViewState.status === ESubmittalStatus.REVIEW;

  const responsibleContractorOptions = useMemo(() => {
    if (isLoggedInUserGC && !isIntegrationMode) {
      return projectParticipants.responsibleContractors.map((company: any) => {
        return {
          id: company.vendor_id,
          name: company.subscription_vendor.name,
          companyInviteStatus: company.project_vendors_company_status?.status,
          ...company
        };
      });
    }

    if (
      !subscriptionSubmittalData ||
      !subscriptionSubmittalData?.responsible_contractor_vendor
    )
      return [];

    const optionObj = {
      id: subscriptionSubmittalData?.responsible_contractor_vendor.id,
      name: subscriptionSubmittalData?.responsible_contractor_vendor.name,
      companyInviteStatus: ""
    };

    return [optionObj];
  }, [
    isIntegrationMode,
    isLoggedInUserGC,
    projectParticipants.responsibleContractors,
    subscriptionSubmittalData
  ]);

  const getGCReviewerOptions = () => {
    if (isLoggedInUserGC && !isIntegrationMode) {
      return projectParticipants.gcReviewers;
    }

    if (!submittalData || !submittalData?.gc_reviewer_user) return [];

    let selectedGCReviewerUser = null;
    try {
      selectedGCReviewerUser = {
        id: submittalData?.gc_reviewer_user.id,
        first_name: submittalData?.gc_reviewer_user.first_name,
        last_name: submittalData?.gc_reviewer_user.last_name,
        company: {
          name: submittalData?.gc_reviewer_user.project_users[0].subscription
            .organization_subscriptions[0].organization.name,
          inviteStatus: null
        },
        type: "actual",
        status_id: submittalData?.gc_reviewer_user.project_users[0]?.status_id
      };
    } catch (ex) {
      //
    }
    return [selectedGCReviewerUser];
  };

  const getDReviewerOptions = useCallback(
    (listType: string) => {
      if (!isIntegrationMode && isLoggedInUserGC && listType === "all") {
        return projectParticipants.designReviewers;
      }

      if (
        !submittalData?.design_reviewer_user &&
        !submittalData?.design_reviewer_unregistered
      )
        return [];

      let optionObj = null;
      if (submittalData?.design_reviewer_user) {
        const drUser = submittalData?.design_reviewer_user;
        optionObj = {
          id: drUser?.id,
          first_name: drUser?.first_name,
          last_name: drUser?.last_name,
          company: {
            name: drUser?.project_users[0]?.subscription_vendor?.name
          },
          type: "actual",
          status_id:
            submittalData?.design_reviewer_user.project_users[0]?.status_id
        };
      }
      if (submittalData?.design_reviewer_unregistered) {
        const drUser = submittalData?.design_reviewer_unregistered;
        optionObj = {
          id: drUser,
          first_name: "",
          last_name: "",
          fullName: drUser,
          company: { name: submittalData?.design_reviewer_vendor_unregistered },
          type: "poc"
        };
      }

      return [optionObj];
    },
    [
      isIntegrationMode,
      isLoggedInUserGC,
      projectParticipants.designReviewers,
      submittalData?.design_reviewer_unregistered,
      submittalData?.design_reviewer_user,
      submittalData?.design_reviewer_vendor_unregistered
    ]
  );

  const submitterUserOptions = useMemo(() => {
    if (isLoggedInUserGC && !isIntegrationMode) {
      const submitterUsers = projectParticipants.submitterUsers.filter(
        (user: any) => {
          return (
            user.company.vendor_id ===
            subscriptionSubmittalData?.responsible_contractor
          );
        }
      );
      return submitterUsers;
    }

    if (
      !subscriptionSubmittalData ||
      !subscriptionSubmittalData?.submitter_user
    ) {
      return [];
    }

    const optionObj = {
      ...subscriptionSubmittalData?.submitter_user,
      company: {
        name: subscriptionSubmittalData?.responsible_contractor_vendor?.name
      },
      status_id:
        subscriptionSubmittalData?.submitter_user.project_users[0]?.status_id
    };

    return [optionObj];
  }, [
    isIntegrationMode,
    isLoggedInUserGC,
    subscriptionSubmittalData,
    projectParticipants.submitterUsers
  ]);

  const BICUnregisteredUserDetails = useMemo(() => {
    // unregistered can only be one of DR or Submitter
    if (submittalData.assignee_unregistered) {
      const drReviewer = getDReviewerOptions("selection");
      // console.log("drReviewer ", drReviewer);
      if (drReviewer[0].id === submittalData.assignee_unregistered) {
        return drReviewer[0];
      }

      return {
        id: `${submittalData.submitter_unregistered}`,
        fullName: `${submittalData.submitter_unregistered}`,
        company: { name: submittalData?.responsible_contractor_vendor?.name },
        type: "poc"
      };
    }

    return null;
  }, [getDReviewerOptions, submittalData]);

  const BICregisteredUserDetails = useMemo(() => {
    if (!isIntegrationMode && submittalData?.submittal_assignee) {
      return {
        first_name: submittalData.submittal_assignee.first_name,
        last_name: submittalData.submittal_assignee.last_name,
        company: {
          name: getUserCompany(
            submittalData.submittal_assignee?.project_users[0]
          )
        },
        status_id: submittalData.submittal_assignee?.project_users[0]?.status_id
      };
    }

    if (submittalData.assignee && projectParticipants) {
      const assigneeUser = projectParticipants.submitterUsers.find(
        (user: any) => {
          return user.id === submittalData.assignee;
        }
      );

      return assigneeUser;
    }
    return null;
  }, [
    isIntegrationMode,
    projectParticipants,
    submittalData.assignee,
    submittalData.submittal_assignee
  ]);

  const cannotRespondToWorkflow = isPermissionNotGrantted(
    ProjectPermissionEnum.SubmittalWorkflow,
    tokenContents?.role!
  );
  // console.log("cannotRespondToWorkflow ", cannotRespondToWorkflow);
  const cannotCreateSubmittal = isPermissionNotGrantted(
    ProjectPermissionEnum.CreateSubmittal,
    tokenContents?.role!
  );

  const isSubmittalInDoneState =
    submittalViewState.status === ESubmittalStatus.DONE;

  const getAttachBtnDisabledMsg = () => {
    if (cannotRespondToWorkflow) {
      return ErrorMessages.PermissionNotGranted;
    }

    if (isWorkflowInOverrideMode) return "";

    if (!editableFields.includes("attachment")) {
      return "Only the Ball In court user can currently add attachments";
    }

    return "";
  };

  const getAssignDisabledTooltipMsg = () => {
    if (isWorkflowInOverrideMode || isRevisionMode) return "";

    if (cannotRespondToWorkflow) {
      return ErrorMessages.PermissionNotGranted;
    }

    if (submittalViewState.status === ESubmittalStatus.DONE) {
      return "No actions can be performed as the submittal is already closed";
    }

    if (
      isLoggedInUserGC &&
      editableFields.includes("comment") &&
      (!submittalData.gc_reviewer ||
        (!updatedData.design_reviewer &&
          !updatedData.design_reviewer_unregistered) ||
        (!updatedData.submitter && !updatedData.submitter_unregistered))
    ) {
      let messageText = "To continue";
      const pendingUserSelect = [];
      if (!updatedData.submitter && !updatedData.submitter_unregistered)
        pendingUserSelect.push("Submitter");
      if (!submittalData.gc_reviewer) pendingUserSelect.push("GC Reviewer");
      if (
        !updatedData.design_reviewer &&
        !updatedData.design_reviewer_unregistered
      )
        pendingUserSelect.push("Design Reviewer");
      if (pendingUserSelect.length > 0)
        messageText += `, please select a ${pendingUserSelect.join(", ")}.`;

      return messageText;
    }

    if (
      !editableFields.includes("comment") &&
      loggedInUserId === submittalData.assignee &&
      submittalData.assignee !== submittalData.gc_reviewer &&
      submittalData.assignee !== submittalData.design_reviewer &&
      submittalData.assignee !== submittalData.submitter
    ) {
      return "";
    }

    if (!editableFields.includes("comment")) {
      return "Only The Ball In Court user can currently perform an action";
    }

    return "";
  };

  const getWorkflowFieldsDisabledTooltipMsg = () => {
    if (isIntegrationMode)
      return ErrorMessages.FieldDisabledDueToIntegrationMode;
    if (cannotCreateSubmittal) return ErrorMessages.PermissionNotGranted;
    if (isSubmittalInDoneState)
      return "No actions can be performed as the submittal is already closed";
    if (submittalViewState.status !== ESubmittalStatus.CREATE) {
      return "Details cannot be updated as workflow has started";
    }
    return "";
  };

  const assignDisabledTooltipMsg = getAssignDisabledTooltipMsg();

  const workflowFieldsDisabledTooltipMsg =
    getWorkflowFieldsDisabledTooltipMsg();

  const getGeneralInfoFieldsDisabledTooltipMsg = () => {
    if (isRevisionMode) return ErrorMessages.RevisionMode;
    if (tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin])
      return "";
    if (isIntegrationMode)
      return ErrorMessages.FieldDisabledDueToIntegrationMode;
    if (cannotCreateSubmittal) return ErrorMessages.PermissionNotGranted;
    if (isSubmittalInDoneState)
      return "No actions can be performed as the submittal is already closed";
    if (submittalViewState.status !== ESubmittalStatus.CREATE) {
      return "Details cannot be updated as workflow has started";
    }
    return "";
  };

  const generalInfoDisabledTooltipMsg =
    getGeneralInfoFieldsDisabledTooltipMsg();

  const getSubmitterDisabledTooltipMsg = () => {
    if (isIntegrationMode)
      return ErrorMessages.FieldDisabledDueToIntegrationMode;
    if (cannotCreateSubmittal) return ErrorMessages.PermissionNotGranted;
    if (isSubmittalInDoneState)
      return "No actions can be performed as the submittal is already closed";
    if (
      !cannotCreateSubmittal &&
      submittalViewState.status !== ESubmittalStatus.CREATE
    ) {
      return "Details cannot be updated as workflow has started";
    }

    if (!cannotCreateSubmittal && !updatedData.responsible_contractor) {
      return "First select a Responsible Contractor";
    }

    return "";
  };

  const canOpenLinkingPage = !isPermissionNotGrantted(
    ProjectPermissionEnum.ViewLinkingPage,
    tokenContents?.role!
  );

  const canEditLinkingPage = !isPermissionNotGrantted(
    ProjectPermissionEnum.EditLinkingPage,
    tokenContents?.role!
  );

  const [updateMaterialMutation] = useCIQMutation(MUTATION_UPDATE_MATERIAL_PK, {
    client: gqlClientForProject
  });

  const canInlineEditMaterialPrimaryFields: boolean = !isPermissionNotGrantted(
    ProjectPermissionEnum.InlineEditMaterialPrimaryFields,
    tokenContents?.role!
  );

  const checkOverrideForActualUsers =
    submittalData.assignee && submittalData.assignee !== loggedInUserId;

  const checkOverrideForPOCUsers =
    submittalData.assignee_unregistered &&
    (submittalData.assignee_unregistered ===
      submittalData.submitter_unregistered ||
      submittalData.assignee_unregistered ===
        submittalData.design_reviewer_unregistered);

  const showOverrideWorkflowBtn =
    isLoggedInUserGcAdmin &&
    (checkOverrideForActualUsers || checkOverrideForPOCUsers);

  const drivingMaterialIndex = useMemo(() => {
    if (
      !submittalData?.submittal_material_links ||
      !submittalData?.submittal_material_links.length
    )
      return 0;
    const idx = submittalData?.submittal_material_links?.findIndex(
      (l) => l.driving_material === true
    );
    return idx !== -1 ? idx : 0;
  }, [submittalData?.submittal_material_links]);

  const [materialDB, setMaterialDB] = useState(
    submittalData?.submittal_material_links.length > 0
      ? submittalData.submittal_material_links[drivingMaterialIndex].material
      : undefined
  );

  useEffect(() => {
    const materialIndex = submittalData.submittal_material_links.findIndex(
      (item: any) => {
        return item?.material?.name === materialDB?.name;
      }
    );
    if (materialIndex !== -1) {
      setMaterialDB(
        submittalData.submittal_material_links[materialIndex].material
      );
    } else if (submittalData.submittal_material_links.length > 0) {
      setMaterialDB(submittalData.submittal_material_links[0].material);
    } else {
      setMaterialDB(undefined);
    }
  }, [materialDB?.name, submittalData]);

  const submitterCompanyName =
    subscriptionSubmittalData?.submitter_unregistered ||
    subscriptionSubmittalData?.submitter
      ? subscriptionSubmittalData?.responsible_contractor_vendor?.name
      : "";

  const gcReviewerCompanyName =
    submittalData.gc_reviewer_user?.project_users?.[0]?.subscription
      ?.organization_subscriptions?.[0]?.organization?.name;

  const drReviewerCompanyName =
    submittalData.design_reviewer_user ||
    submittalData.design_reviewer_unregistered
      ? getDReviewerOptions("selection")[0].company?.name
      : "";

  const workflowCard = (
    <Card className="card-block">
      <div className="uppercase text-xs font-bold tracking-wider pb-2">
        WORKFLOW
      </div>
      <div className="h-[400px] overflow-y-auto overflow-x-hidden">
        <Form.Item
          label={submittalHeaderMap?.responsible_contractor?.toUpperCase()}
        >
          <Tooltip
            title={workflowFieldsDisabledTooltipMsg}
            placement="rightBottom"
          >
            <Select
              value={
                updatedData.responsible_contractor
                  ? updatedData.responsible_contractor
                  : undefined
              }
              onChange={async (value: string) => {
                await saveFieldChange({
                  responsible_contractor: value,
                  submitter_unregistered: "",
                  submitter: null
                });

                setUpdatedData((prevData: any) => {
                  return Object.assign(prevData, {
                    responsible_contractor: value,
                    submitter_unregistered: "",
                    submitter: null
                  });
                });
                refetchSubmittalPK();
              }}
              showSearch
              filterOption
              optionFilterProp="label"
              disabled={
                isIntegrationMode ||
                cannotRespondToWorkflow ||
                !editableFields.includes("responsible_contractor")
              }
              notFoundContent={
                <SelectSearchNotFoundContent
                  notFoundMsg={
                    responsibleContractorOptions?.length > 0
                      ? "Company not found. To add, go to "
                      : "No companies added to this project. To add, go to "
                  }
                  linkTitle="Project Companies"
                  linkPath={`/project/${projectId}/settings/general/project-companies`}
                />
              }
            >
              {responsibleContractorOptions?.map((company: any) => {
                return (
                  <Option
                    key={company.id}
                    value={company.id}
                    label={company.name}
                  >
                    {company.name}
                  </Option>
                );
              })}
            </Select>
          </Tooltip>
        </Form.Item>
        <Form.Item
          label={
            <span>
              {submittalHeaderMap?.submitter?.toUpperCase()}{" "}
              {submitterCompanyName && (
                <span className="normal-case">({submitterCompanyName})</span>
              )}
            </span>
          }
        >
          <Tooltip
            title={getSubmitterDisabledTooltipMsg()}
            placement="rightBottom"
          >
            <Select
              value={selectedSubmitterUserId}
              onChange={(value: string) => {
                const selectedUser = submitterUserOptions.find((user: any) => {
                  return user.id === value;
                });
                if (selectedUser.type === "actual") {
                  saveFieldChange({
                    submitter: value,
                    submitter_unregistered: ""
                  });
                } else {
                  saveFieldChange({
                    submitter_unregistered: value,
                    submitter: null
                  });
                }
                refetchSubmittalPK();
              }}
              notFoundContent={
                <SelectSearchNotFoundContent
                  notFoundMsg={
                    submitterUserOptions?.length > 0
                      ? "User is not part of above selected company. To add, go to "
                      : "There are no users added to this company. To add, go to "
                  }
                  linkTitle="Project Settings"
                  linkPath={`/project/${projectId}/settings/general/project-users`}
                />
              }
              showSearch
              optionFilterProp="label"
              filterOption
              disabled={
                isIntegrationMode ||
                cannotRespondToWorkflow ||
                !editableFields.includes("submitter") ||
                !updatedData.responsible_contractor
              }
            >
              {submitterUserOptions.map((user: any) => {
                if (
                  user.status_id === AcceptanceStatus.DEACTIVATED &&
                  user.id !== selectedSubmitterUserId
                )
                  return "";

                const selectedAndInactive =
                  user.status_id === AcceptanceStatus.DEACTIVATED &&
                  user.id === selectedSubmitterUserId;

                return (
                  <Option
                    key={user.id}
                    value={user.id}
                    label={`${user.first_name} ${user.last_name}`}
                    className={selectedAndInactive ? "hidden" : ""}
                  >
                    {user.status_id === AcceptanceStatus.DEACTIVATED && (
                      <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
                        <UserTag label="Inactive" />
                      </div>
                    )}
                    <div>
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {user.company.name}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </Tooltip>
        </Form.Item>
        <Form.Item
          label={
            <span>
              {submittalHeaderMap?.gc_reviewer?.toUpperCase()}{" "}
              {gcReviewerCompanyName && (
                <span className="normal-case">({gcReviewerCompanyName})</span>
              )}
            </span>
          }
        >
          <Tooltip
            title={workflowFieldsDisabledTooltipMsg}
            placement="rightBottom"
          >
            <Select
              value={
                updatedData.gc_reviewer
                  ? updatedData.gc_reviewer.toString()
                  : undefined
              }
              onChange={(value: string) => {
                saveFieldChange({
                  gc_reviewer: value
                });
              }}
              showSearch
              filterOption
              optionFilterProp="label"
              notFoundContent={
                <SelectNotFoundContent
                  notFoundMsg="User does not exist in the project. Go to Project Settings to add new user."
                  onAddClick={() => {
                    setIsAddSubProjectUserDrawerOpen(true);
                  }}
                  showAddBtn={false}
                />
              }
              disabled={
                isIntegrationMode ||
                cannotRespondToWorkflow ||
                !editableFields.includes("gc_reviewer")
              }
            >
              {getGCReviewerOptions().map((user: any) => {
                if (
                  (user.status_id === AcceptanceStatus.DEACTIVATED &&
                    user.id !== updatedData?.gc_reviewer?.toString()) ??
                  ""
                )
                  return "";

                const selectedAndInactive =
                  (user.status_id === AcceptanceStatus.DEACTIVATED &&
                    user.id === updatedData?.gc_reviewer?.toString()) ??
                  "";

                return (
                  <Option
                    key={user.id}
                    value={user.id}
                    label={`${user.first_name} ${user.last_name} ${user?.company?.name}`}
                    className={selectedAndInactive ? "hidden" : ""}
                  >
                    {user.status_id === AcceptanceStatus.DEACTIVATED && (
                      <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
                        <UserTag label="Inactive" />
                      </div>
                    )}
                    <div>
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {user?.company?.name}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </Tooltip>
        </Form.Item>
        <Form.Item
          label={
            <span>
              {submittalHeaderMap?.design_reviewer?.toUpperCase()}{" "}
              {drReviewerCompanyName && (
                <span className="normal-case">({drReviewerCompanyName})</span>
              )}
            </span>
          }
        >
          <Tooltip
            title={workflowFieldsDisabledTooltipMsg}
            placement="rightBottom"
          >
            <Select
              value={selectedDesignReviwerId}
              onChange={(value: string) => {
                const foundUser: any = projectParticipants.designReviewers.find(
                  (user: any) => {
                    return user.id === value;
                  }
                );
                if (foundUser.type === "actual") {
                  saveFieldChange({
                    design_reviewer: value,
                    design_reviewer_unregistered: "",
                    design_reviewer_vendor_unregistered: ""
                  });
                } else {
                  saveFieldChange({
                    design_reviewer_unregistered: value,
                    design_reviewer_vendor_unregistered: foundUser.company.name,
                    design_reviewer: null
                  });
                }
              }}
              showSearch
              filterOption
              optionFilterProp="label"
              notFoundContent={
                <SelectSearchNotFoundContent
                  notFoundMsg={
                    getDReviewerOptions("all")?.length > 0
                      ? "Architect/Engineer is not added to project. To add, go to "
                      : "There are no Architects/Engineers added to this project. To add, go to "
                  }
                  linkTitle="Project Settings"
                  linkPath={`/project/${projectId}/settings/general/project-users`}
                />
              }
              disabled={
                isIntegrationMode ||
                cannotRespondToWorkflow ||
                !editableFields.includes("design_reviewer")
              }
            >
              {getDReviewerOptions("all").map((user: any) => {
                if (
                  user.status_id === AcceptanceStatus.DEACTIVATED &&
                  user.id !== selectedDesignReviwerId
                )
                  return "";

                const selectedAndInactive =
                  user.status_id === AcceptanceStatus.DEACTIVATED &&
                  user.id === selectedDesignReviwerId;
                return (
                  <Option
                    key={`user-${user.id}`}
                    value={user.id}
                    label={`${user.first_name} ${user.last_name} ${user.company?.name}`}
                    className={selectedAndInactive ? "hidden" : ""}
                  >
                    {user.status_id === AcceptanceStatus.DEACTIVATED && (
                      <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
                        <UserTag label="Inactive" />
                      </div>
                    )}
                    <div>
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {user.company?.name}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </Tooltip>
        </Form.Item>

        <Tooltip
          title={workflowFieldsDisabledTooltipMsg}
          placement="rightBottom"
        >
          <div>
            <DistributionList
              submittalId={submittalData.id}
              disabled={
                isIntegrationMode ||
                submittalViewState.status !== ESubmittalStatus.CREATE
              }
            />
          </div>
        </Tooltip>
        <Tooltip title="" placement="rightBottom">
          <Form.Item
            label={submittalHeaderMap?.watchers?.toUpperCase() || "WATCHERS"}
          >
            <Input
              placeholder={`Enter ${
                submittalHeaderMap?.watchers || "Watchers"
              }`}
              value={updatedData?.watchers}
              maxLength={4000}
              disabled={
                cannotRespondToWorkflow ||
                isIntegrationMode ||
                !editableFields.includes("watchers")
              }
              onBlur={(event) => {
                if (
                  submittalData.watchers?.trim() === event.target.value.trim()
                )
                  return;
                saveFieldChange({
                  watchers: event.target.value
                });
              }}
              onChange={(event) => {
                setUpdatedData((prev: SubmittalLog) => {
                  return prev
                    ? { ...prev, watchers: event.target.value }
                    : prev;
                });
              }}
            />
          </Form.Item>
        </Tooltip>
      </div>
    </Card>
  );

  const specSectionLabelWithViewer = (
    <div className="w-full flex items-center justify-between">
      <div>{submittalHeaderMap?.spec_no?.toUpperCase()}</div>
      {isDesignTabEnabled && submittalData?.spec_section?.file_key ? (
        <Button
          type="link"
          size="small"
          className="text-xs uppercase workflow-document-upload h-[17px] mb-1"
          onClick={() => {
            setShowSpecSectionFileModal(true);
          }}
        >
          view spec section file
        </Button>
      ) : null}
    </div>
  );

  const generalInfoCard = (
    <Card className="card-block">
      <div className="uppercase text-xs font-bold tracking-wider pb-2">
        GENERAL INFORMATION
      </div>
      <div className="grow min-h-0 h-[400px] overflow-auto">
        <div className="h-full max-h-full">
          <div className="w-full flex">
            <Form.Item
              label={submittalHeaderMap?.state?.toUpperCase()}
              className="w-1/3"
            >
              <span>{submittalData?.raw_status || ""}</span>
            </Form.Item>
            <Form.Item
              label={submittalHeaderMap?.assignee?.toUpperCase()}
              className="w-2/3"
            >
              {submittalData?.assignee_unregistered && (
                <div>
                  <span>{BICUnregisteredUserDetails?.fullName}</span>
                  <span className="ml-1 text-xs">
                    ({BICUnregisteredUserDetails?.company.name})
                  </span>
                </div>
              )}
              {BICregisteredUserDetails && (
                <div>
                  <span>
                    {BICregisteredUserDetails?.first_name}{" "}
                    {BICregisteredUserDetails?.last_name}
                  </span>
                  {BICregisteredUserDetails?.company?.name && (
                    <span className="ml-1 text-xs">
                      ({BICregisteredUserDetails?.company?.name})
                    </span>
                  )}
                </div>
              )}
            </Form.Item>
          </div>
          <Form.Item
            label={submittalHeaderMap?.submittal_number?.toUpperCase()}
          >
            <Input
              value={updatedData?.submittal_number}
              onChange={(event: any) => {
                setUpdatedData((prev: any) => {
                  return {
                    ...prev,
                    submittal_number: event.target.value
                  };
                });
              }}
              onBlur={async (event: any) => {
                const value = event.target.value?.trim();
                const oldValue = submittalData?.submittal_number || "";

                if (oldValue !== value) {
                  saveFieldChange({
                    submittal_number: event.target.value
                  });
                }
              }}
              disabled={
                isRevisionMode ||
                isIntegrationMode ||
                isSubmittalInDoneState ||
                cannotRespondToWorkflow ||
                !editableFields.includes("submittal_number")
              }
            />
          </Form.Item>
          <Tooltip
            title={generalInfoDisabledTooltipMsg}
            placement="rightBottom"
          >
            <Form.Item label={specSectionLabelWithViewer}>
              <SpecNumberNameDropDown
                defaultSpecName={updatedData.spec_name}
                defaultSpecNumber={updatedData.spec_no}
                currentSpecSection={{
                  specSectionId: updatedData.spec_section_id,
                  enable: false
                }}
                disabled={
                  isRevisionMode ||
                  isIntegrationMode ||
                  isSubmittalInDoneState ||
                  cannotRespondToWorkflow ||
                  !editableFields.includes("spec_section")
                }
                onChange={(
                  id: string | null,
                  number: string | null,
                  name: string | null
                ) => {
                  saveFieldChange({
                    spec_section_id: id,
                    spec_no: number,
                    spec_name: name
                  });
                }}
              />
            </Form.Item>
          </Tooltip>

          <Tooltip
            title={generalInfoDisabledTooltipMsg}
            placement="rightBottom"
          >
            <Form.Item label={submittalHeaderMap?.type?.toUpperCase()}>
              <Select
                value={submittalType ?? ""}
                onChange={(id: string) => {
                  const typeObj = submittalTypesOptions.find((t: any) => {
                    return t.id === Number(id);
                  });
                  saveFieldChange({ type: id, raw_type: typeObj?.value || "" });
                  setSubmittalType(typeObj?.value || "");
                }}
                disabled={
                  isRevisionMode ||
                  isIntegrationMode ||
                  isSubmittalInDoneState ||
                  cannotRespondToWorkflow ||
                  !editableFields.includes("type")
                }
              >
                {submittalTypesOptions.map((item: SubmittalTypeType) => (
                  <Option key={item.id} label={item.value}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Tooltip>
          <Tooltip
            title={generalInfoDisabledTooltipMsg}
            placement="rightBottom"
          >
            <Form.Item
              label={submittalHeaderMap?.priority?.toUpperCase() || "PRIORITY"}
            >
              <Select
                placeholder={`Select a ${
                  submittalHeaderMap?.priority || "Priority"
                }`}
                value={
                  updatedData.priority
                    ? updatedData.priority.toString()
                    : undefined
                }
                onChange={(id: string) => {
                  saveFieldChange({ priority: id });
                }}
                disabled={
                  isRevisionMode ||
                  cannotRespondToWorkflow ||
                  isIntegrationMode ||
                  !editableFields.includes("priority")
                }
              >
                {priorities.map((priorityItem: any) => (
                  <Option key={priorityItem.value} label={priorityItem.label}>
                    {priorityItem.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Tooltip>
          <Tooltip
            title={generalInfoDisabledTooltipMsg}
            placement="rightBottom"
          >
            <Form.Item label={submittalHeaderMap?.description?.toUpperCase()}>
              <TextArea
                // autoSize={false}
                rows={7}
                placeholder="Enter Description"
                value={updatedData?.description}
                onBlur={(data) => {
                  if (
                    submittalData.description?.trim() ===
                    data.target.value.trim()
                  )
                    return;
                  saveFieldChange({
                    description: data.target.value
                  });
                }}
                onChange={(data) => {
                  setUpdatedData((prev: SubmittalLog) => {
                    return prev
                      ? { ...prev, description: data.target.value }
                      : prev;
                  });
                }}
                disabled={
                  isRevisionMode ||
                  isIntegrationMode ||
                  isSubmittalInDoneState ||
                  cannotRespondToWorkflow ||
                  !editableFields.includes("description")
                }
              />
            </Form.Item>
          </Tooltip>
        </div>
      </div>
    </Card>
  );

  const attachmentsCard = (
    <Card className="card-block">
      <div className="flex w-full justify-between">
        <div className="uppercase text-xs font-bold tracking-wider pb-2">
          ATTACHMENTS
        </div>
        {!isRevisionMode && documents && documents.length > 0 && (
          <Upload showUploadList={false} customRequest={fileUploadRequest}>
            <Button
              size="small"
              type="text"
              className="border-none pr-1"
              onClick={() => {
                setTabIndex("2");
                history.push(
                  `/project/${projectId}/submittals/${submittalData.id}?tab=2`
                );
              }}
              title={getAttachBtnDisabledMsg()}
              disabled={
                !isWorkflowInOverrideMode &&
                (isRevisionMode ||
                  isPermissionNotGrantted(
                    ProjectPermissionEnum.AddAttachment,
                    tokenContents?.role!
                  ) ||
                  !editableFields.includes("attachment"))
              }
            >
              <span className="add-more-btn">Add More</span>
            </Button>
          </Upload>
        )}
      </div>
      {documents && documents.length > 0 ? (
        <div className="h-[320px] overflow-auto">
          <div className="overflow-auto">
            <DocumentListWidget
              documents={documents}
              onDocumentClick={(data: any) => {
                setTabIndex("2");
                const latest = findLatestAttachmentVersion(data);
                selectAttachmentItem(latest);
                history.push(
                  `/project/${projectId}/submittals/${submittalData.id}?tab=2`
                );
              }}
            />
          </div>
        </div>
      ) : (
        <div className="h-[320px] flex flex-col items-center justify-center">
          <span className="text-xs text-neutral-400">
            No attachments added.
          </span>
          {!isRevisionMode && (
            <Tooltip title={getAttachBtnDisabledMsg()} placement="rightBottom">
              <Button
                className="border-none"
                size="small"
                type="text"
                onClick={() => {
                  setTabIndex("2");
                  history.push(
                    `/project/${projectId}/submittals/${submittalData.id}?tab=2`
                  );
                }}
                disabled={
                  !isWorkflowInOverrideMode &&
                  (isRevisionMode ||
                    isPermissionNotGrantted(
                      ProjectPermissionEnum.AddAttachment,
                      tokenContents?.role!
                    ) ||
                    !editableFields.includes("attachment"))
                }
              >
                <span className="add-more-btn">Add More</span>
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </Card>
  );

  const submittalLinksCard = (
    <Card className="card-block links-card">
      <div className="uppercase text-xs font-bold tracking-wider pb-2">
        LINKS
      </div>
      <div className="h-[400px] overflow-auto">
        <ErrorBoundary>
          <SubmittalLinks
            {...{
              submittalData: subscriptionSubmittalData,
              canOpenLinkingPage,
              isRevisionMode,
              canEditLinkingPage,
              materialLinks:
                subscriptionSubmittalData?.submittal_material_links,
              projectId,
              projectDetails,
              updateMaterialMutation,
              canInlineEditMaterialPrimaryFields,
              gqlClientForProject,
              isIntegrationMode
            }}
          />
        </ErrorBoundary>
      </div>
    </Card>
  );

  const workflowHistoryCard = (
    <Card
      className={classNames("card-block", {
        "readonly-card": isLoggedInUserSC
      })}
    >
      <div className="uppercase text-xs font-bold tracking-wider pb-2">
        WORKFLOW HISTORY
      </div>
      <div
        className={classNames({
          "h-[320px]": !isLoggedInUserSC,
          "h-[700px]": isLoggedInUserSC
        })}
      >
        <ErrorBoundary>
          <SubmittalHistory
            submittalHistory={submittalHistory}
            allAttachments={allAttachments}
            designReviewerId={
              submittalData?.design_reviewer ||
              submittalData?.design_reviewer_unregistered
            }
            gcReviewerId={submittalData?.gc_reviewer}
          />
        </ErrorBoundary>
      </div>
    </Card>
  );

  const readOnlyWorkflowCard = (
    <div className="card-block readonly-card">
      <ReadOnlyWorkflowCard
        {...{
          submittalData,
          submitterUserOptions,
          getGCReviewerOptions,
          getDReviewerOptions,
          responsibleContractorOptions,
          isIntegrationMode,
          submittalViewState,
          submittalHeaderMap
        }}
      />
    </div>
  );

  const readOnlyGeneralInfoCard = (
    <Card className="card-block readonly-card">
      <div className="uppercase text-xs font-bold tracking-wider pb-2">
        GENERAL INFORMATION
      </div>
      <div>
        <ReadOnlyGeneralInfo
          {...{
            submittalData,
            BICUnregisteredUserDetails,
            getUserCompany,
            submittalTypesOptions,
            setShowSpecSectionFileModal,
            submittalHeaderMap
          }}
        />
      </div>
    </Card>
  );

  const readOnlyLinksCard = (
    <div className="overflow-auto card-block links-card readonly-card">
      <ErrorBoundary>
        <SubmittalLinks
          {...{
            submittalData,
            canOpenLinkingPage,
            isRevisionMode,
            canEditLinkingPage,
            materialLinks: submittalData?.submittal_material_links,
            projectId,
            projectDetails,
            updateMaterialMutation,
            canInlineEditMaterialPrimaryFields,
            gqlClientForProject,
            isIntegrationMode
          }}
        />
      </ErrorBoundary>
    </div>
  );

  const submittalSectionViewForNormalUsers = (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-4">{generalInfoCard}</div>
      <div className="col-span-4">{workflowCard}</div>
      <div className="col-span-4">{submittalLinksCard}</div>
      {!isIntegrationMode && (
        <div className="col-span-4">{attachmentsCard}</div>
      )}
      {!isIntegrationMode && (
        <div className="col-span-4">
          <ErrorBoundary>
            <ReviewStatusCard
              gqlClientForProject={gqlClientForProject}
              workflowOverride={workflowOverride}
              showReviewOption={showReviewOption}
              assignDisabledTooltipMsg={assignDisabledTooltipMsg}
              submittalWorkflowResponses={submittalWorkflowResponses}
              isRevisionMode={isRevisionMode}
              cannotRespondToWorkflow={cannotRespondToWorkflow}
              editableFields={editableFields}
              updatedData={updatedData}
              submittalViewState={submittalViewState}
              isLoggedInUserMainInWorkflow={isLoggedInUserMainInWorkflow}
              showOverrideWorkflowBtn={showOverrideWorkflowBtn}
              isLoggedInUserGC={isLoggedInUserGC}
              isLoggedInUserGcAdmin={isLoggedInUserGcAdmin}
              loggedInUserId={loggedInUserId}
              submittalData={submittalData}
              isLoggedInUserOwner={isLoggedInUserOwner}
              isLoggedInUserSC={isLoggedInUserSC}
              isLoggedInUserEngineer={isLoggedInUserEngineer}
              isLoggedInUserArchitect={isLoggedInUserArchitect}
              updateSubmittalOne={updateSubmittalOne}
              UploadAttachment={UploadAttachment}
              projectId={projectId}
              contentClassNames={classNames({
                "h-[330px]": !isLoggedInUserSC,
                "h-[250px]": isLoggedInUserSC
              })}
              submittalHistory={submittalHistory}
            />
          </ErrorBoundary>
        </div>
      )}
      {!isIntegrationMode && (
        <div className="col-span-4">{workflowHistoryCard}</div>
      )}
    </div>
  );

  const submittalSectionViewForSCUsers = (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-8 grid grid-cols-12 gap-4">
        <div className="col-span-6">{readOnlyGeneralInfoCard}</div>
        <div className="col-span-6 actionable">{attachmentsCard}</div>
        <div className="col-span-6 h-[380px] overflow-auto">
          <Collapse
            expandIconPosition="end"
            accordion
            className="readonly-collapse"
            defaultActiveKey={2}
          >
            <Panel
              header={
                <div className="uppercase text-xs font-bold tracking-wider">
                  WORKFLOW
                </div>
              }
              key={1}
              className="readonly-collapsible-panel"
            >
              <div className="h-[250px] overflow-auto">
                {readOnlyWorkflowCard}
              </div>
            </Panel>
            <Panel
              header={
                <div className="uppercase text-xs font-bold tracking-wider">
                  LINKS
                </div>
              }
              key={2}
              className="readonly-collapsible-panel"
            >
              <div className="h-[250px] overflow-auto">{readOnlyLinksCard}</div>
            </Panel>
          </Collapse>
        </div>
        <div className="col-span-6 actionable">
          <ErrorBoundary>
            <ReviewStatusCard
              gqlClientForProject={gqlClientForProject}
              workflowOverride={workflowOverride}
              showReviewOption={showReviewOption}
              assignDisabledTooltipMsg={assignDisabledTooltipMsg}
              submittalWorkflowResponses={submittalWorkflowResponses}
              isRevisionMode={isRevisionMode}
              cannotRespondToWorkflow={cannotRespondToWorkflow}
              editableFields={editableFields}
              updatedData={updatedData}
              submittalViewState={submittalViewState}
              isLoggedInUserMainInWorkflow={isLoggedInUserMainInWorkflow}
              showOverrideWorkflowBtn={showOverrideWorkflowBtn}
              isLoggedInUserGC={isLoggedInUserGC}
              isLoggedInUserGcAdmin={isLoggedInUserGcAdmin}
              loggedInUserId={loggedInUserId}
              submittalData={submittalData}
              isLoggedInUserOwner={isLoggedInUserOwner}
              isLoggedInUserSC={isLoggedInUserSC}
              isLoggedInUserEngineer={isLoggedInUserEngineer}
              isLoggedInUserArchitect={isLoggedInUserArchitect}
              updateSubmittalOne={updateSubmittalOne}
              UploadAttachment={UploadAttachment}
              projectId={projectId}
              contentClassNames={classNames({
                "h-[320px]": !isLoggedInUserSC,
                "h-[250px]": isLoggedInUserSC
              })}
              submittalHistory={submittalHistory}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className="col-span-4">{workflowHistoryCard}</div>
    </div>
  );

  const governingActivity = useMemo(() => {
    const linkedTask = submittalData?.submittal_schedule_links?.find(
      (task: any) => task.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        isLinkedEndDate: linkedTask.linked_to_end_date
      };

    return {} as any;
  }, [submittalData?.submittal_schedule_links]);

  const materialGoverningActivity = useMemo(() => {
    if (materialDB && materialDB.material_schedule_links) {
      const linkedTask = materialDB.material_schedule_links.find(
        (e: any) => e.driving_task
      );
      if (linkedTask)
        return {
          ...linkedTask.gantt_task,
          isLinkedEndDate: linkedTask.linked_to_end_date
        } as GoverningTaskType;
    }
    return {} as GoverningTaskType;
  }, [materialDB]);

  const submittalDateBlockTitleDiv = useMemo(
    () => (
      <div className="flex item-center justify-between">
        <div className="uppercase text-xs font-bold tracking-wider h-8 flex items-center">
          SUBMITTAL SCHEDULE{" "}
          {!isSubmittalVoid && canOpenLinkingPage && (
            <Link
              to={`/project/${projectId}/schedule/submittal-linking?&SubmittalId=${submittalData.id}`}
              title="Add Linking"
            >
              <LinkOutlined
                style={{
                  color: "#1890ff",
                  fontSize: 16,
                  marginLeft: 10,
                  marginTop: 2
                }}
              />
            </Link>
          )}
          {isRevisionMode ? (
            <span className="submittal--warning-message">
              To view the material schedule of the linked materials, please
              navigate to the latest revision.
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    ),
    [
      canOpenLinkingPage,
      isRevisionMode,
      isSubmittalVoid,
      projectId,
      submittalData.id
    ]
  );
  const materialDateBlockTitleDiv = useMemo(
    () => (
      <div className="flex w-full items-center tracking-wider h-8 justify-between">
        <div className="flex items-center text-xs font-bold">
          MATERIAL:
          <div>
            <Select
              className="ml-1"
              style={{
                minWidth: "330px",
                maxWidth: "330px"
              }}
              defaultValue={drivingMaterialIndex}
              onChange={(index) => {
                setMaterialDB(
                  submittalData.submittal_material_links[index].material
                );
              }}
            >
              {submittalData.submittal_material_links
                .filter((l: any) => l.material)
                .map((l: any, index: number) => (
                  <Option value={index} key={l.material.name}>
                    <Tooltip
                      title={
                        l.material?.implicit
                          ? `Material for Submittal ${submittalData.submittal_id}`
                          : l.material?.name?.length > 20
                          ? l.material.name
                          : ""
                      }
                    >
                      {l.material?.implicit ? (
                        <div className="flex items-center max-w-full pr-1 overflow-hidden">
                          {l.driving_material ? (
                            <div className="flex items-center mr-1 ">
                              <AsterixIcon />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="grow max-w-full">
                            <div className="max-w-full truncate">
                              {`Material for Submittal ${submittalData.submittal_id}`}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center max-w-full pr-1 overflow-hidden">
                          {l.driving_material ? (
                            <div className="flex items-center mr-1">
                              <AsterixIcon />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="grow max-w-full">
                            <div className="max-w-full truncate">
                              {l.material?.name}
                            </div>
                          </div>
                        </div>
                      )}
                    </Tooltip>
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      </div>
    ),
    [
      drivingMaterialIndex,
      submittalData.submittal_id,
      submittalData.submittal_material_links
    ]
  );

  const noLinkedMaterialItemDiv = !isRevisionMode ? (
    <div className="no-linking-item-found">
      <div className="no-linking-item-found--title text-center">
        {submittalData.material_tracking
          ? "No Materials Linked"
          : "Material tracking is disabled"}
      </div>
      {canEditLinkingPage && (
        <Button
          type="link"
          disabled={!canEditLinkingPage}
          onClick={() => {
            history.push(
              `/project/${projectId}/schedule/submittal-linking?&SubmittalId=${submittalData.id}`
            );
          }}
        >
          {submittalData.material_tracking
            ? "Click here to add"
            : "Click here to enable"}
        </Button>
      )}
    </div>
  ) : (
    ""
  );

  const specFileModalHeader = () => {
    return (
      <div className="w-full grid grid-cols-3 gap-x-6">
        <div className="col-span-2 truncate">
          {submittalData?.spec_no && <span>{submittalData?.spec_no} -</span>}{" "}
          {submittalData?.spec_name || ""}
        </div>
      </div>
    );
  };

  const insightsEnabled = useMemo(() => {
    if (!submittalInsightsConfig) return false;
    if (
      submittalInsightsConfig?.project_feature_configurations &&
      submittalInsightsConfig?.project_feature_configurations?.length > 0
    ) {
      const resArr: any[] =
        submittalInsightsConfig?.project_feature_configurations;
      const submittalHeadersMap = resArr.find((obj) => obj?.feature_id === 1);
      return submittalHeadersMap?.insights_enabled || false;
    }
    return false;
  }, [submittalInsightsConfig]);

  const submittalInsights = useMemo(() => {
    return submittalInsightsData?.submittal_insights_view;
  }, [submittalInsightsData]);

  return (
    <Form layout="vertical">
      <div className="space-y-4 mb-10">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            {" "}
            <div className="flex items-center">
              {submittalTitlePanel}
              {isSubmittalInsightsEnabled && (
                <div>
                  {!insightsEnabled ||
                  !submittalInsights ||
                  submittalInsights?.length === 0 ? (
                    <Tooltip
                      placement="right"
                      title={
                        !insightsEnabled
                          ? "Insights are disabled for this project"
                          : "Unable to provide insights as similar submittals were not found"
                      }
                    >
                      <div className="flex pr-4">
                        <SubmittalInsightsDisabledIcon />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Insights">
                      <Button
                        className="border-none bg-transparent mr-2 mt-2"
                        icon={
                          <div>
                            <SubmittalInsightsIcon />
                          </div>
                        }
                        onClick={() => setShowSubmittalInsightsModal(true)}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>

          <ErrorBoundary>
            <div className="col-span-12">
              <div className="flex flex-col bg-white date-block-row  w-full justify-center p-6 overflow-x-auto">
                <div className="w-full flex space-x-2 justify-end pb-2 h-10">
                  <div className="pr-6">* Governing date</div>
                  {!isRevisionMode && (
                    <div className="flex space-x-2">
                      <div>Date Block</div>
                      <Switch
                        onChange={(checked) => setShowImpactDateBlock(checked)}
                        disabled={isRevisionMode}
                      />
                      <div>Risk View (Preview)</div>
                    </div>
                  )}
                </div>

                {isShowImpactDateBlock && !isRevisionMode ? (
                  <DateBlockSubmittalImpactVisualisation
                    submittal={updatedData}
                    governingTask={governingActivity}
                  />
                ) : (
                  <DateBlockDynamicUIPanel
                    submittal={updatedData}
                    material={
                      submittalData?.material_tracking ? materialDB : undefined
                    }
                    submittalTitle={submittalDateBlockTitleDiv}
                    materialTitle={materialDateBlockTitleDiv}
                    noMaterialDateBlockDiv={noLinkedMaterialItemDiv}
                    noSubmittalDateBlockDiv={null}
                    governingTaskOfSubmittal={governingActivity}
                    governingTaskOfMaterial={materialGoverningActivity}
                    disableDB={isRevisionMode || isSubmittalVoid}
                    componentLocation="SubmittalDetail"
                  />
                )}
              </div>
            </div>
          </ErrorBoundary>
        </div>
        {isLoggedInUserSC
          ? submittalSectionViewForSCUsers
          : submittalSectionViewForNormalUsers}
      </div>
      {isAddSubProjectUserDrawerOpen && (
        <InviteProjectUser
          existingUsers={[]}
          setDrawerOpen={setDrawerAction}
          showDrawerOpen={isAddSubProjectUserDrawerOpen}
          modelTitle={DialogTitles.addUser}
        />
      )}

      {isAddCompanyDrawerOpen && (
        <AddCompany
          setDrawerOpen={setDrawerAction}
          showDrawerOpen={isAddCompanyDrawerOpen}
          modelHeader={
            <div className="flex justify-between">
              <div className="flex">{DialogTitles.addCompany}</div>
            </div>
          }
        />
      )}
      {showCreateSpecSectionModal && (
        <CreateSpecSectionModal
          isModalOpen={showCreateSpecSectionModal}
          onDoneCb={(newSpecSectionId: string) => {
            setShowCreateSpecSectionModal(false);
            if (!newSpecSectionId) return;
            saveFieldChange({
              spec_section_id: newSpecSectionId
            });
          }}
        />
      )}
      {showAdvAssignModal && (
        <AdvanceAssignModal
          projectId={projectId}
          submittalId={submittalData.id}
          isModalOpen={showAdvAssignModal}
          onDoneCb={setShowAdvAssignModal}
          submittalData={submittalData}
        />
      )}
      {showSpecSectionFileModal && (
        <FileViewerModal
          fileKey={submittalData?.spec_section?.file_key}
          pageNumber={submittalData?.snippet_coordinates?.pageid || 0}
          showModal={showSpecSectionFileModal}
          hideModal={() => {
            setShowSpecSectionFileModal(false);
          }}
          headerComponent={specFileModalHeader()}
        />
      )}
      {contextHolder}
      {isSubmittalInsightsEnabled && showSubmittalInsightsModal && (
        <SubmittalInsights
          submittalInsights={submittalInsights}
          showModal={showSubmittalInsightsModal}
          hideModal={() => {
            setShowSubmittalInsightsModal(false);
          }}
        />
      )}
    </Form>
  );
}

export default SubmittalDetails;
