import { IntegrationsTabSharedContext } from "pages/subscription-settings/integrations-tab-new/integrations-tab-context";
import { useCallback, useContext, useEffect, useState } from "react";
import { OAUTH_OUTPUT } from "services/graphQL/ciq-queries";
import { ProjectIntegrationsTabSharedContext } from "pages/project-setting-menu/project-integrations/project-integrations-tab-context";
import { Modal } from "antd";
import { IntegrationAuthProcessStatuses } from "../constants";
import { useCustomOAuthPopup } from "./common";
import { useCIQQuerySubscription } from "./ciq-gql-hooks";

type AuthConfig = {
  id: string;
  auth_url: string;
  [key: string]: any;
};

type OAuthStatus = {
  status: string;
  [key: string]: any;
};

type OAuthOperations = {
  oauth_status: OAuthStatus;
  [key: string]: any;
};

type AuthData = {
  oauth_operations: OAuthOperations[];
};

export const useIntegrationAuthProcess = (
  source: "subscription" | "project" = "subscription",
  gqlClient?: any
) => {
  const { dispatch: subscriptionDispatch } = useContext(
    IntegrationsTabSharedContext
  );

  const { dispatch: projectDispatch } = useContext(
    ProjectIntegrationsTabSharedContext
  );

  const { openPopup, closePopup, isPopupOpen } = useCustomOAuthPopup();

  const [authConfig, setAuthConfig] = useState<AuthConfig | null>(null);
  const [authPromise, setAuthPromise] = useState<{
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
  } | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const { data: authData } = useCIQQuerySubscription<AuthData>(OAUTH_OUTPUT, {
    variables: {
      id: authConfig?.id
    },
    client: gqlClient,
    skip: !authConfig?.id
  });

  const handleAuthDataUpdate = useCallback(() => {
    if (authData) {
      const oAuthOperations = authData.oauth_operations[0];
      if (isAuthenticating && oAuthOperations.oauth_status?.status) {
        closePopup();

        if (
          oAuthOperations.oauth_status?.status ===
          IntegrationAuthProcessStatuses.PROCESSED
        ) {
          if (source === "subscription") {
            subscriptionDispatch({
              type: "SET_SHARED_STATE",
              payload: {
                authData: { ...oAuthOperations, ...authConfig }
              }
            });
          }
          if (source === "project") {
            projectDispatch({
              type: "SET_SHARED_STATE",
              payload: {
                authData: { ...oAuthOperations, ...authConfig }
              }
            });
          }
          if (authPromise) {
            authPromise.resolve({ ...oAuthOperations, ...authConfig });
          }
        } else {
          if (authPromise) {
            authPromise.resolve(null);
          }
          const error = oAuthOperations.output?.error || "Failed to authorize";
          closePopup();
          Modal.error({
            title: "Authentication failed",
            content: error
          });
        }

        setAuthPromise(null);
        setIsAuthenticating(false);
      }
    }
  }, [
    authData,
    isAuthenticating,
    closePopup,
    source,
    authPromise,
    subscriptionDispatch,
    authConfig,
    projectDispatch
  ]);

  useEffect(() => {
    handleAuthDataUpdate();
  }, [authData, handleAuthDataUpdate]);

  const resetAuthProcess = useCallback(() => {
    setAuthConfig(null);
    setAuthPromise(null);
    setIsAuthenticating(false);
    closePopup();
  }, [closePopup]);

  const initAuthProcess = useCallback(
    (config: AuthConfig) => {
      resetAuthProcess();
      setAuthConfig(config);
      openPopup({ title: "Login to authenticate", url: config.auth_url });
      setIsAuthenticating(true);

      return new Promise((resolve, reject) => {
        setAuthPromise({ resolve, reject });
      });
    },
    [openPopup, resetAuthProcess]
  );

  return {
    initAuthProcess,
    authData: authData?.oauth_operations[0],
    closePopup,
    isPopupOpen,
    resetAuthProcess
  };
};
