import {
  MaterialLinkedType,
  MaterialType,
  ScheduleLinkingType,
  SubmittalLinkedType,
  SubmittalType
} from "./models";

export const findColumnDataOfMaterial = (materials: Array<MaterialType>) => {
  if (materials.length === 0) return null;
  const first = materials[0];
  const mat = {
    id: materials.length === 1 ? first.id : "",
    ids: materials.map((x) => x.id),
    material_id: first.material_id,
    name: first.name,
    spec_section_no: first.spec_section_no,
    spec_section_name: first.spec_section_name,
    implicit: first.implicit,
    material_schedule_links: [...first.material_schedule_links],
    submittal_material_links: [...first.submittal_material_links]
  } as MaterialType;

  if (materials.length === 1) return mat;

  if (materials.some((m) => m.name !== mat.name)) mat.name = "";
  if (materials.some((m) => m.spec_section_no !== mat.spec_section_no))
    mat.spec_section_no = "";
  if (materials.some((m) => m.spec_section_name !== mat.spec_section_name))
    mat.spec_section_name = "";

  const submittalMaterialLinks = [] as SubmittalLinkedType[];
  mat.submittal_material_links.forEach((s) => {
    if (
      materials.every((x) =>
        x.submittal_material_links.some(
          (y) => y.submittal.id === s.submittal.id
        )
      )
    )
      submittalMaterialLinks.push(s);
  });
  mat.submittal_material_links = submittalMaterialLinks;

  const activityMaterialLink = [] as ScheduleLinkingType[];
  mat.material_schedule_links.forEach((s) => {
    if (
      materials.every((x) =>
        x.material_schedule_links.some(
          (y) => y.gantt_task.id === s.gantt_task.id
        )
      )
    )
      activityMaterialLink.push(s);
  });
  mat.material_schedule_links = activityMaterialLink;

  return mat;
};

export const findColumnDataOfSubmittal = (submittals: Array<SubmittalType>) => {
  if (submittals.length === 0) return null;
  const first = submittals[0];
  const sub = {
    id: submittals.length === 1 ? first.id : "",
    ids: submittals.map((x) => x.id),
    description: first.description,
    material_tracking: first.material_tracking,
    spec_no: first.spec_no,
    spec_name: first.spec_name,
    status: first.status,
    submittal_id: first.submittal_id,
    title: first.title,
    submittal_material_links: [...first.submittal_material_links],
    submittal_schedule_links: [...first.submittal_schedule_links]
  } as SubmittalType;

  if (submittals.length === 1) return sub;

  if (submittals.some((m) => m.title !== sub.title)) sub.title = "";
  if (submittals.some((m) => m.material_tracking !== sub.material_tracking))
    sub.material_tracking = false;
  if (submittals.some((m) => m.spec_no !== sub.spec_no)) sub.spec_no = "";
  if (submittals.some((m) => m.spec_name !== sub.spec_name)) sub.spec_name = "";

  const submittalMaterialLinks = [] as MaterialLinkedType[];
  sub.submittal_material_links.forEach((s) => {
    if (
      submittals.every((x) =>
        x.submittal_material_links.some((y) => y.material.id === s.material.id)
      )
    )
      submittalMaterialLinks.push(s);
  });
  sub.submittal_material_links = submittalMaterialLinks;

  const activitySubmittalLink = [] as ScheduleLinkingType[];
  sub.submittal_schedule_links.forEach((s) => {
    if (
      submittals.every((x) =>
        x.submittal_schedule_links.some(
          (y) => y.gantt_task.id === s.gantt_task.id
        )
      )
    )
      activitySubmittalLink.push(s);
  });
  sub.submittal_schedule_links = activitySubmittalLink;

  return sub;
};
