import TextArea from "antd/lib/input/TextArea";
import { Collapse, Form } from "antd";
import { useEffect, useMemo, useRef } from "react";
import ErrorIcon from "components/svg-icons/error-icon";
import { IMaterialGroup, IMaterialGroupFormFields } from "../type-definitions";
import { MaterialListItem } from "../widgets";

function HasAssigneeRhsContent({
  group,
  formFieldsState
}: {
  group: IMaterialGroup;
  formFieldsState: [
    IMaterialGroupFormFields,
    React.Dispatch<React.SetStateAction<IMaterialGroupFormFields>>
  ];
}) {
  const { Panel } = Collapse;
  const [groupFormFields, setGroupFormFields] = formFieldsState;

  const textAreaRef = useRef<any>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      const { value } = textAreaRef.current.resizableTextArea.textArea;
      textAreaRef.current.focus();
      textAreaRef.current.resizableTextArea.textArea.setSelectionRange(
        value.length,
        value.length
      );
    }
  }, []);

  const { WFStartedItems, requestAlreadySentItems, allowedToSendRequestItems } =
    group.materialsByStatus;

  const hasExcludedItems = useMemo(
    () => WFStartedItems.length > 0,
    [WFStartedItems]
  );

  const includedMaterials = useMemo(
    () => allowedToSendRequestItems.concat(requestAlreadySentItems),
    [allowedToSendRequestItems, requestAlreadySentItems]
  );

  return (
    <div>
      <div>
        <div className="text-sm font-semibold">
          {group.subcontractorCompany?.name}
        </div>
        <div className="text-xs">
          {group.assigneeUser?.first_name} {group.assigneeUser?.last_name} (
          {group.assigneeUser?.email})
        </div>
      </div>
      {includedMaterials && includedMaterials.length ? (
        <div className="mt-6">
          <div className="w-2/3">
            <Form layout="vertical">
              <Form.Item label="Add note to request">
                <TextArea
                  ref={textAreaRef}
                  placeholder="Add a note"
                  value={groupFormFields[group.groupId]?.note}
                  onChange={(e) => {
                    setGroupFormFields((prev) => ({
                      ...prev,
                      [group.groupId]: { note: e.target.value }
                    }));
                  }}
                  autoSize={{ minRows: 2, maxRows: 2 }}
                  allowClear
                  data-testid="notes-input"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : null}

      {includedMaterials && includedMaterials.length ? (
        <div className="form-field-heading-2 mt-6">
          Selected Materials ({includedMaterials.length})
        </div>
      ) : null}

      {allowedToSendRequestItems && allowedToSendRequestItems.length ? (
        <div className="mb-4">
          <div className="mt-0.5">
            {allowedToSendRequestItems.map((material, index) => (
              <MaterialListItem
                material={material}
                index={index}
                key={material.id}
                additionalClassName="border-0 border-b border-solid border-color-2"
              />
            ))}
          </div>
        </div>
      ) : null}

      {requestAlreadySentItems && requestAlreadySentItems.length ? (
        <div className="pl-3 mt-1">
          <div className="sub-heading">
            <div className="font-medium">Recently Requested</div>
            <div className="!text-color-1">
              These Materials will be resent as part of this email.
            </div>
          </div>
          <div className="mt-1.5 -ml-3">
            {requestAlreadySentItems.map((material, index) => (
              <MaterialListItem
                material={material}
                index={index}
                key={material.id}
                additionalClassName="border-0 border-b border-solid border-color-2"
              />
            ))}
          </div>
        </div>
      ) : null}

      {hasExcludedItems && (
        <div>
          <div className="flex items-start space-x-1">
            <div className="form-field-heading-2 grow-0">
              Excluded from selection
            </div>
            <div className="danger-text flex items-center">
              <ErrorIcon width={16} height={16} />
            </div>
          </div>
          <div className="mt-1">
            <Collapse bordered={false} ghost>
              {WFStartedItems && WFStartedItems.length ? (
                <Panel
                  header={
                    <span className="text-xs">
                      Materials with Workflow Started ({WFStartedItems.length})
                    </span>
                  }
                  key="1"
                >
                  <div className="px-2 pb-3.5">
                    {WFStartedItems.map((material, index) => (
                      <MaterialListItem
                        material={material}
                        index={index}
                        key={material.id}
                        additionalClassName="border-0 border-b border-solid border-color-1 pl-5"
                      />
                    ))}
                  </div>
                </Panel>
              ) : null}
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
}

export default HasAssigneeRhsContent;
