/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleOutlined } from "@ant-design/icons";
import "./listing-window.scss";
import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Checkbox } from "antd";
import Tooltip from "antd/es/tooltip";

type SuggectionPanelType = {
  isSuggested: boolean;
  setSuggested: (value: React.SetStateAction<boolean>) => void;
  areAllSuggestionsLinked: boolean;
  onLinkUnlinkAll: (check: boolean) => void;
};

interface IListingWindowProps {
  children: any;
  title: string;
  onClose: any;
  isFromSubmittals?: boolean | undefined;
  suggectionPanel?: SuggectionPanelType;
}
function ListingWindow({
  children,
  title,
  onClose,
  isFromSubmittals,
  suggectionPanel
}: IListingWindowProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: any) => {
    const domNode = elementRef?.current;
    if (!domNode || !domNode?.contains(event.target)) {
      const skipWindows = document.querySelectorAll(".skip-listing-window");
      const canSkip = Array.from(skipWindows).some(
        (v) => v?.contains && v.contains(event.target)
      );
      if (!canSkip) {
        setTimeout(() => {
          onClose();
        }, 100);
      }
    }
  };
  const componentDidMount = () => {
    document.addEventListener("click", handleClickOutside, true);
  };

  const componentWillUnmount = () => {
    document.removeEventListener("click", handleClickOutside, true);
  };

  useEffect(() => {
    componentDidMount();
    return componentWillUnmount;
  }, []);
  if (document.getElementById("linkingOptionContainer")) {
    return ReactDOM.createPortal(
      <div className="listing-window" ref={elementRef}>
        <div className="listing-window--nav">
          <h5>{title}</h5>{" "}
          <div className="flex justify-between items-center gap-1">
            {suggectionPanel && (
              <div className="flex space-x-2">
                {suggectionPanel.isSuggested && (
                  <Tooltip
                    title={
                      suggectionPanel.areAllSuggestionsLinked
                        ? "Unlink all of the suggested items below."
                        : "Link all of the suggested items below."
                    }
                  >
                    <Checkbox
                      checked={suggectionPanel.areAllSuggestionsLinked}
                      onChange={(e) => {
                        suggectionPanel.onLinkUnlinkAll(e.target.checked);
                      }}
                    >
                      {suggectionPanel.areAllSuggestionsLinked
                        ? "Unlink All"
                        : "Link All"}
                    </Checkbox>
                  </Tooltip>
                )}
                <Checkbox
                  checked={suggectionPanel.isSuggested}
                  onChange={(e) => {
                    suggectionPanel.setSuggested(e.target.checked);
                  }}
                >
                  <span className="listing-window--suggestedLbl">
                    {isFromSubmittals
                      ? "Suggested Submittals"
                      : "Suggested Materials"}
                  </span>
                </Checkbox>
              </div>
            )}
            <CloseCircleOutlined onClick={onClose} />
          </div>
        </div>
        <div className="listing-window--children">{children}</div>
      </div>,
      document.getElementById("linkingOptionContainer") || document.body
    );
  }
  return null;
}

export default ListingWindow;
