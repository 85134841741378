function ForwardArrowIcon(props: {
  width?: number;
  height?: number;
  disabled?: boolean;
}) {
  const { width = 24, height = 24, disabled = false } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
      fill={disabled ? "#999" : "#5f6368"}
    >
      <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
    </svg>
  );
}

export default ForwardArrowIcon;
