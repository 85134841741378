/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  DownOutlined,
  InfoCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { useQuery, useSubscription } from "@apollo/client";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Dropdown, Button, Space } from "antd";
import LinkIcon from "components/svg-icons/link-icon";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useEffect, useState } from "react";
import { SUBSCRIPTION_SUBMITTALS_LINKING } from "services/graphQL/subscriptions";
import "./index.scss";
import Search from "antd/lib/input/Search";
import {
  QUERY_GANTT_TASKS_LIST,
  QUERY_PROJECT_MATERIALS,
  QUERY_SUBMITTALS_LINKING_TABLE
} from "services/graphQL/queries";
import { GridLoadingIndicator } from "components/widgets";
import ErrorBoundary from "components/error-boundary";
import SubmittalIcon from "components/svg-icons/submittal-icon-2";
import GoverningLegend from "components/governing-legend";
import Tooltip from "antd/es/tooltip";
import RedirectIcon from "components/svg-icons/redirect-icon";
import { mergedStrings } from "utils/utils";
import {
  MaterialLinkedType,
  MaterialType,
  ScheduleLinkingType,
  SubmittalType
} from "./models";
import ActivityLinking from "./activity-linking";
import MaterialLinking from "./material-linking";
import TrackMaterial from "./track-material";
import TrackActivity from "./track-activity";
import { ProjectPermissionEnum } from "../../constants";
import ViewSelectedItemsDrawer from "./view-selected-items";
import { findColumnDataOfSubmittal } from "./utils";

function SubmittalBulkLinking() {
  // State defination
  const history = useHistory();
  const { search, state } = useLocation() as { search: string; state: any };
  const { projectId } = useParams<{ projectId: string }>();
  const { gqlClientForProject } = useContext(ProjectContext);

  const [submittalList, setSubmittalList] = useState(
    [] as Array<SubmittalType>
  );
  const [selectedSubmittal, setSelectedSubmittal] = useState<SubmittalType>();
  const [submittalSearch, setSubmittalSearch] = useState("");

  const [activityList, setActivityList] = useState([] as Array<any>);
  const [materialList, setMaterialList] = useState([] as Array<MaterialType>);
  const [selectedMaterial, setSelectedMaterial] = useState<
    MaterialLinkedType | undefined
  >(undefined);
  const [showActivityLinking, setShowActivityLinking] = useState(true);
  const [loadingSubmittalDetail, setLoadingSubmittalDetail] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const [selectedBulkSubmittalIds, setSelectedBulkSubmittalIds] = useState<
    string[]
  >(state?.selectedRows?.map((x: any) => x.id) || []);

  const [showViewSelectedDrawer, setViewSelectedDrawer] = useState(false);

  const { tokenContents, projectDetails } = useContext(ProjectContext);
  const isPermissionNotGrant = isPermissionNotGrantted(
    ProjectPermissionEnum.EditLinkingPage,
    tokenContents?.role!
  );

  const { data: submittalsRaw, loading: loadingSubmittalsRow } = useQuery(
    QUERY_SUBMITTALS_LINKING_TABLE,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: tasksData, refetch: reFetchActivity } = useQuery(
    QUERY_GANTT_TASKS_LIST,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: projectMaterials } = useQuery(QUERY_PROJECT_MATERIALS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    fetchPolicy: "no-cache"
  });
  const { data: submittalData } = useSubscription(
    SUBSCRIPTION_SUBMITTALS_LINKING,
    {
      client: gqlClientForProject,
      variables: {
        ids: selectedBulkSubmittalIds
      },
      shouldResubscribe: true,
      skip: !gqlClientForProject || !selectedBulkSubmittalIds.length,
      onError: () => {
        setLoadingSubmittalDetail(false);
      }
    }
  );

  // Api Subscription End

  const projectWithSpecSection = projectDetails
    ? projectDetails?.spec_section
    : null;

  useEffect(() => {
    setSubmittalList(submittalsRaw?.submittals || []);

    if (selectedBulkSubmittalIds.length) {
      setLoadingSubmittalDetail(true);
    } else {
      const submittalId = new URLSearchParams(search).get("SubmittalId");
      const selectedSubmittalObj = submittalsRaw?.submittals?.find(
        (item: SubmittalType) => item?.id === submittalId
      );
      if (selectedSubmittalObj) {
        setSelectedBulkSubmittalIds([selectedSubmittalObj.id]);
        setLoadingSubmittalDetail(true);
      }
    }
  }, [
    submittalsRaw?.submittals?.sort(
      (current: SubmittalType, next: SubmittalType) =>
        (+current?.submittal_id || 0) - (+next?.submittal_id || 0)
    )
  ]);

  useEffect(() => {
    if (submittalData?.submittals && submittalData?.submittals.length) {
      const submittalByIdObj = findColumnDataOfSubmittal(
        submittalData.submittals
      ) as SubmittalType;

      if (selectedBulkSubmittalIds.length)
        submittalByIdObj.ids = selectedBulkSubmittalIds;
      else submittalByIdObj.ids = [submittalByIdObj.id];

      (submittalByIdObj?.submittal_material_links || []).sort(
        (current: MaterialLinkedType, next: MaterialLinkedType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      (submittalByIdObj?.submittal_schedule_links || []).sort(
        (current: ScheduleLinkingType, next: ScheduleLinkingType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      setSubmittalList(
        submittalList.map((value: SubmittalType) => {
          if (value.id === submittalByIdObj.id) {
            return submittalByIdObj;
          }
          return value;
        })
      );
      setSelectedSubmittal(submittalByIdObj);
      const selectedMaterialObj =
        submittalByIdObj?.submittal_material_links?.find(
          (item: MaterialLinkedType) =>
            item?.material?.id === selectedMaterial?.material?.id
        );
      if (selectedMaterialObj) {
        setSelectedMaterial(selectedMaterialObj);
      } else if (
        submittalByIdObj?.material_tracking &&
        submittalByIdObj?.submittal_material_links?.length > 0
      ) {
        const drivingIndex =
          submittalByIdObj?.submittal_material_links?.findIndex(
            (item: MaterialLinkedType) => {
              return item.driving_material;
            }
          );
        setSelectedMaterial(
          submittalByIdObj?.submittal_material_links[
            drivingIndex === -1 ? 0 : drivingIndex
          ]
        );
      } else {
        setSelectedMaterial(undefined);
      }
      setLoadingSubmittalDetail(false);
    }
  }, [submittalData?.submittals]);

  useEffect(() => {
    setActivityList(
      (tasksData?.gantt_tasks || [])
        ?.filter((ts: any) => ts.type !== 3 && ts.type !== 4)
        .sort((current: any, next: any) =>
          current?.source_task_id?.localeCompare(next?.source_task_id)
        )
    );
  }, [tasksData]);
  useEffect(() => {
    setMaterialList(
      projectMaterials?.material?.sort(
        (current: MaterialType, next: MaterialType) => {
          return (+current?.material_id || 0) - (+next?.material_id || 0);
        }
      ) || ([] as Array<MaterialType>)
    );
  }, [projectMaterials]);

  // Effects Handeling End

  const getSelectedSubmittalLabel = () => {
    const selectedSubmittalObj = submittalList?.find((item: SubmittalType) => {
      return selectedBulkSubmittalIds.includes(item?.id);
    });

    const submittalId = projectWithSpecSection
      ? mergedStrings([
          selectedSubmittalObj?.spec_no || "",
          selectedSubmittalObj?.submittal_id || ""
        ])
      : selectedSubmittalObj?.submittal_id || "";

    const titleStr = (
      <Tooltip
        title={
          <span>
            <i className="text-[#3b3b3bcc]">
              {mergedStrings([
                submittalId,
                selectedSubmittalObj?.spec_no || "",
                selectedSubmittalObj?.spec_name || ""
              ])}
            </i>
            <div>{selectedSubmittalObj?.title || ""}</div>
          </span>
        }
      >
        <span className="flex-grow items-center truncate space-x-2">
          <i>
            {mergedStrings([
              submittalId,
              selectedSubmittalObj?.spec_no || "",
              selectedSubmittalObj?.spec_name || ""
            ])}
          </i>
          <span>{selectedSubmittalObj?.title || ""}</span>
        </span>
      </Tooltip>
    );

    return selectedSubmittalObj ? (
      <div className="flex items-center linking-container--nav__dropdown--selected">
        {titleStr}
        {(selectedSubmittalObj?.submittal_material_links.filter(
          (material: MaterialLinkedType) => {
            return !material.material.implicit;
          }
        )?.length || 0) +
          (selectedSubmittalObj?.submittal_schedule_links?.length || 0) >
        0 ? (
          <div className="flex items-center">
            <LinkIcon />
            <DownOutlined className="ml-1" />
          </div>
        ) : (
          <div className="flex items-center">
            <DownOutlined className="ml-4" />
          </div>
        )}
      </div>
    ) : (
      <div className="flex items-center linking-select-empty">
        Select a Submittal
        <DownOutlined className="ml-4" />
      </div>
    );
  };

  const menu = () => {
    if (loadingSubmittalsRow) {
      return (
        <div className="linking-container--nav__loading">
          <LoadingOutlined />
        </div>
      );
    }
    if (submittalList.length === 0) {
      return (
        <div className="linking-container--nav__loading">
          No Submittal Available
        </div>
      );
    }
    return (
      <div className="linking-container--nav__dropdown">
        <div className="linking-container--nav__dropdown--search">
          <Search
            className="linking-search"
            placeholder="Search Submittal Here"
            value={submittalSearch}
            onChange={(e) => setSubmittalSearch(e.currentTarget.value)}
          />
        </div>
        {submittalList
          .filter((item) => {
            const label =
              `${item.submittal_id} ${item?.title}`.toLocaleLowerCase();
            return label.includes(submittalSearch.toLocaleLowerCase());
          })
          .map((item) => {
            const submittalId = projectWithSpecSection
              ? mergedStrings([item.spec_no || "", item.submittal_id || ""])
              : item.submittal_id || "";
            return (
              <div
                className="linking-container--nav__option flex items-center justify-between"
                key={item.id}
                onClick={() => {
                  setOpenDropdown(false);
                  if (selectedBulkSubmittalIds.includes(item.id)) {
                    return;
                  }
                  history.push(
                    `/project/${projectId}/schedule/submittal-linking?&SubmittalId=${item.id}`
                  );
                  setLoadingSubmittalDetail(true);
                  setSelectedBulkSubmittalIds([item.id]);
                  setSelectedSubmittal(undefined);
                  setSelectedMaterial(undefined);
                }}
              >
                <Tooltip
                  mouseEnterDelay={0.5}
                  title={
                    <div className="space-y-1 py-1">
                      <i className="text-[#3b3b3bcc]">
                        {mergedStrings([
                          submittalId,
                          item.spec_no || "",
                          item.spec_name || ""
                        ])}
                      </i>
                      <div>{item.title || ""}</div>
                    </div>
                  }
                >
                  <span className="items-center w-full truncate">
                    <span className="space-x-2">
                      <i>
                        {mergedStrings([
                          submittalId,
                          item.spec_no || "",
                          item.spec_name || ""
                        ])}
                      </i>
                      <span>{item.title || ""}</span>
                    </span>
                  </span>
                </Tooltip>
                {(item?.submittal_material_links?.filter(
                  (material: MaterialLinkedType) => {
                    return !material?.material?.implicit;
                  }
                ).length || 0) +
                  (item?.submittal_schedule_links?.length || 0) >
                0 ? (
                  <LinkIcon />
                ) : (
                  ""
                )}
              </div>
            );
          })}
      </div>
    );
  };

  const onMaterialClick = (material: MaterialLinkedType) => {
    setSelectedMaterial(material);
  };

  const forSingleSubmittal = (
    <div className="linking-container--nav justify-between">
      <div className="flex items-center">
        <div className="flex items-center">
          <SubmittalIcon className="mr-2" />
          <h3>SUBMITTAL</h3>
        </div>

        <Dropdown
          overlay={menu()}
          className="mx-4 linking-container--nav__select"
          trigger={["click"]}
          open={openDropdown}
          onOpenChange={(open) => {
            setOpenDropdown(open);
          }}
        >
          <Button>{getSelectedSubmittalLabel()}</Button>
        </Dropdown>

        {selectedSubmittal && (
          <>
            <Tooltip title="Open Submittal Detail">
              <Link
                className="flex linking-container--nav__redirectlinkIcon"
                to={`/project/${projectId}/submittals/${selectedSubmittal.id}`}
                target="_blank"
              >
                <RedirectIcon />
              </Link>
            </Tooltip>
            <div>
              <TrackMaterial
                selectedSubmittal={selectedSubmittal}
                canEdit={!isPermissionNotGrant}
              />
            </div>
          </>
        )}
      </div>
      <GoverningLegend />
    </div>
  );

  const forBulkSubmittals = (
    <div className="linking-container--nav flex items-center">
      <div className="flex items-center">
        <SubmittalIcon className="mr-2" />
        <h3>SUBMITTAL</h3>
      </div>

      <div className="py-1 pl-4">
        <div className="linking-border p-1  text-sm flex w-[467px] space-x-1 rounded-sm items-center">
          <div className="font-bold pl-3">
            {selectedBulkSubmittalIds.length}
          </div>
          <div className="grow">Submittals Selected from Log</div>
          <Space
            className="linking-border rounded p-1 cursor-pointer"
            onClick={() => {
              setViewSelectedDrawer(true);
            }}
          >
            View
          </Space>
          <Space
            className="linking-border rounded p-1 cursor-pointer"
            onClick={() => {
              setSelectedBulkSubmittalIds([]);
              setSelectedSubmittal(undefined);
            }}
          >
            Clear
          </Space>
        </div>
      </div>
      {selectedSubmittal ? (
        <TrackMaterial
          selectedSubmittal={selectedSubmittal}
          canEdit={!isPermissionNotGrant}
        />
      ) : (
        <div className="w-32 bg-purple-300" />
      )}
      <div className="flex items-center space-x-2">
        <InfoCircleOutlined />
        <div>
          Links which are not common to all the selected submittals are not
          displayed here.
        </div>
      </div>
    </div>
  );

  const singleSubmittalInstructions = (
    <div className="linking-group--right--content">
      <div className="linking-group--right--content__instruction">
        <p className="flex items-strech">
          <b className="mr-1 min-w-[60px]">Step 1:</b>{" "}
          <span className="grow">
            Choose a Submittal from the dropdown menu.
          </span>
        </p>
      </div>
      <div className="linking-group--right--content__instruction">
        <p className="flex items-strech">
          <b className="mr-1 min-w-[60px]">Step 2:</b>{" "}
          <span className="grow">
            Click &quot;Associate Material&quot; to link between a Material and
            the selected Submittal.
          </span>
        </p>
      </div>
      <div className="linking-group--right--content__instruction">
        <p className="flex items-strech">
          <b className="mr-1 min-w-[60px]">Step 3:</b>{" "}
          <span className="grow">
            Click &quot;Associate Activity&quot; to link an Activity with the
            associated Material.
          </span>
        </p>
      </div>
      <div className="linking-group--right--content__instruction">
        <p className="flex items-strech">
          <b className="mr-1 min-w-[60px]">Step 4:</b>{" "}
          <span className="grow">
            Check the &quot;Link submittal with activities&quot; checkbox and
            click &quot;Associate Activity&quot; to directly connect the
            selected Submittal with an Activity.
          </span>
        </p>
      </div>
      <div className="linking-group--right--content__instruction">
        <p className="flex items-strech">
          <b className="mr-1 min-w-[60px]">Step 5:</b>{" "}
          <span className="grow">
            Material tracking is enabled by default. To detach the linked
            Material from the Submittal, disable the checkbox &quot;Track
            Material&quot;. If you wish to monitor the material later on, simply
            re-check the &quot;Track Material&quot; checkbox.
          </span>
        </p>
      </div>
    </div>
  );

  const bulkSubmittalInstructions = (
    <div className="text-sm px-3 py-2 text-[#000000CC]">
      <div className="font-semibold py-2">To Link:</div>
      <ul className="list-disc">
        <li>
          Click &#8220;Associate Material&#8221; to link between a Material and
          the selected Submittals.
        </li>
        <li>
          Click &#8220;Associate Activity&#8221; to link an Activity with the
          associated Material.
        </li>
        <li>
          Check the &#8220;Link submittal with activities&#8221; checkbox and
          click &#8220;Associate Activity&#8221; to directly connect the
          selected Submittals with an Activity.
        </li>
        <li>
          Material tracking is enabled by default. To detach the linked Material
          from the Submittal, disable the checkbox &#8220;Track Material&#8221;.
          If you wish to monitor the material later on, simply re-check the
          &#8220;Track Material&#8221; checkbox.
        </li>
      </ul>

      <div className="font-semibold py-2">Changing the Selection:</div>
      <ul className="list-disc">
        <li>
          To add or remove items from the Submittals selected, go back to the
          Submittal Log page and reselect the Submittals you wish to Bulk Link.
        </li>
      </ul>

      <div className="font-semibold py-2">Note:</div>
      <ul className="list-disc">
        <li>
          Individual Submittals in your selection may already be linked with
          different entities, such links are not shown here.
        </li>
        <li>
          Some submittals in your selection may already be linked to common
          entities, such links are not shown here.
        </li>
        <li>
          If all the selected Submittals are linked to a common entity, such
          links are displayed here.
        </li>
      </ul>
    </div>
  );

  return (
    <ErrorBoundary>
      <div className="linking-page flex flex-col text-xs">
        <div
          className={`linking-container ${
            selectedSubmittal ? "" : "linking-container--empty"
          }`}
        >
          {selectedBulkSubmittalIds.length > 1
            ? forBulkSubmittals
            : forSingleSubmittal}
          {selectedSubmittal ? (
            <div className="linking-group">
              <div
                className={`linking-group--left ${
                  selectedSubmittal?.material_tracking && showActivityLinking
                    ? "show-material"
                    : ""
                }`}
              >
                {selectedSubmittal?.material_tracking && (
                  <MaterialLinking
                    materialList={materialList}
                    selectedSubmittal={selectedSubmittal}
                    activityList={activityList}
                    userSelectedMaterial={selectedMaterial}
                    onMaterialClick={onMaterialClick}
                    reFetchActivity={reFetchActivity}
                    canEdit={!isPermissionNotGrant}
                  />
                )}
                <section className="flex justify-end p-[10px]">
                  <TrackActivity
                    selectedSubmittal={selectedSubmittal}
                    onChanges={(value: boolean) =>
                      setShowActivityLinking(value)
                    }
                    canEdit={!isPermissionNotGrant}
                  />
                </section>
                {showActivityLinking && (
                  <ActivityLinking
                    activityList={activityList}
                    selectedSubmittal={selectedSubmittal}
                    reFetchActivity={reFetchActivity}
                    canEdit={!isPermissionNotGrant}
                  />
                )}
              </div>
              <div className="linking-group--right" id="linkingOptionContainer">
                <div className="linking-group--right--nav">
                  <h2>Instructions</h2>
                </div>
                {selectedBulkSubmittalIds.length > 1
                  ? bulkSubmittalInstructions
                  : singleSubmittalInstructions}
              </div>
            </div>
          ) : loadingSubmittalDetail ? (
            <div className="linking-loading--container">
              <div>
                <GridLoadingIndicator />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {showViewSelectedDrawer && (
          <ViewSelectedItemsDrawer
            instruction="Add more to the selection from the Submittal Log page."
            isOpen={showViewSelectedDrawer}
            setOpen={setViewSelectedDrawer}
            items={state?.selectedRows.map((item: any) => ({
              name: projectWithSpecSection
                ? mergedStrings([
                    item.spec_no || "",
                    item.submittal_sequence_id || "",
                    item.title || ""
                  ])
                : mergedStrings([
                    item.submittal_sequence_id || "",
                    item.title || ""
                  ])
            }))}
            key="ViewSelectedItemsDrawer"
            title="Selected Submittals"
          />
        )}
      </div>
    </ErrorBoundary>
  );
}

export default SubmittalBulkLinking;
