import { Button } from "antd";
import BackArrowIcon from "components/svg-icons/back-arrow-icon";
import ForwardArrowIcon from "components/svg-icons/forward-arrow-icon";
import { Dispatch, SetStateAction } from "react";

function ResponsiveFooter({
  leadTimeRequestData,
  activeMaterialIndex,
  onMaterialNavigation,
  setIsMaterialListDrawerOpen
}: {
  leadTimeRequestData: any;
  activeMaterialIndex: number;
  onMaterialNavigation: (materialIndex: number) => void;
  setIsMaterialListDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="md:hidden w-full flex items-center shrink-0 px-5 py-3 space-x-2  bg-white">
      <div className="flex items-center justify-start grow shrink-0">
        <Button
          htmlType="button"
          type="primary"
          onClick={() => {
            setIsMaterialListDrawerOpen(true);
          }}
        >
          All Materials
        </Button>
      </div>
      <div className="flex items-center justify-end gap-2">
        <Button
          htmlType="button"
          className="flex items-center gap-2"
          onClick={() => {
            onMaterialNavigation(activeMaterialIndex - 1);
          }}
          disabled={activeMaterialIndex === 0}
        >
          <BackArrowIcon
            width={16}
            height={16}
            disabled={activeMaterialIndex === 0}
          />{" "}
          Previous
        </Button>
        <Button
          htmlType="button"
          className="flex items-center gap-2"
          onClick={() => {
            onMaterialNavigation(activeMaterialIndex + 1);
          }}
          disabled={
            activeMaterialIndex === leadTimeRequestData.materials.length - 1
          }
        >
          Next{" "}
          <ForwardArrowIcon
            width={16}
            height={16}
            disabled={
              activeMaterialIndex === leadTimeRequestData.materials.length - 1
            }
          />
        </Button>
      </div>
    </div>
  );
}

export default ResponsiveFooter;
