function BackArrowIcon(props: {
  width?: number;
  height?: number;
  disabled?: boolean;
}) {
  const { width = 24, height = 24, disabled = false } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 -960 960 960"
      width={width}
      fill={disabled ? "#999" : "#5f6368"}
    >
      <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
    </svg>
  );
}

export default BackArrowIcon;
