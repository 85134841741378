/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Button, Checkbox, Dropdown, Tooltip } from "antd";
import ErrorBoundary from "components/error-boundary";

import "./activity-grid.scss";
import { useSubscription } from "@apollo/client";
import { ProjectContext } from "context/ProjectProvider";
import { SUBSCRIPTION_GET_SCHEDULE_VERSIONS } from "services/graphQL/subscriptions";
import { Link } from "react-router-dom";
import { makeScheduleAsActive } from "services/schedule-versions-services";
import { GridLoadingIndicator } from "components/widgets";
import Search from "antd/lib/input/Search";
import { DateFilter, DateUtils } from "utils/dateutils";
import NoLinkIcon from "components/svg-icons/no-link-icon";
import ExpandIconPath from "assets/svg/expand-icon.svg";
import CollapseIconPath from "assets/svg/collapse-icon.svg";
import {
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
  RowNode
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ScheduleTaskType } from "./models";

interface IActivityProps {
  activityList: Array<ScheduleTaskType>;
  selectedList: { [key: string]: any };
  onToggleSelect: (id: string, params: { [key: string]: any }) => void;
  reFetchActivity?: () => void;
  apiCallBack?: { [key: string]: any };
}

interface IActivityGridProps {
  activityList: Array<ScheduleTaskType>;
  selectedList: { [key: string]: any };
  scheduleListData?: any;
  hasActive: boolean;
  loadingScheduleVersion: boolean;
  overlayLoading: boolean;
  onToggleSelect: (id: string, params: { [key: string]: any }) => void;
  reFetchActivity?: () => void;
  setActiveSchedule?: (fileId: string) => void;
  apiCallBack?: { [key: string]: any };
}

function CellWithDateCheckbox(params: any) {
  const { data, onCheckBoxAction, isStartDate } = params;

  if (isStartDate) {
    const startDate = data?.start_date
      ? DateUtils.format(data.start_date, "MM-DD-YYYY")
      : "";

    if (startDate === "") return "";

    return (
      <div className="flex w-full justify-end items-center">
        {data.type !== 5 ? (
          <Checkbox
            checked={data.linked_to_end_date === false}
            onChange={(event) => {
              let activity = {};
              let toBeUpdatedlinkedToEndDate = data.id.linked_to_end_date;
              if (event.target.checked) {
                toBeUpdatedlinkedToEndDate = false;
              } else {
                toBeUpdatedlinkedToEndDate = null;
              }
              let action = "";
              if (
                data.linked_to_end_date === true &&
                toBeUpdatedlinkedToEndDate === false
              ) {
                action = "update";
              }
              activity = {
                id: data.id,
                linked_to_end_date: toBeUpdatedlinkedToEndDate,
                action
              };
              onCheckBoxAction(activity);
            }}
          >
            <i>{startDate}</i>
          </Checkbox>
        ) : (
          <i className="mr-2">{startDate}</i>
        )}
      </div>
    );
  }

  const endDate = data?.end_date
    ? DateUtils.format(data.end_date, "MM-DD-YYYY")
    : "";
  if (endDate === "") return "";

  return (
    <div className="flex w-full justify-end items-center">
      {data.type !== 5 ? (
        <Checkbox
          checked={data.linked_to_end_date === true}
          onChange={(event) => {
            let activity = {};
            let toBeUpdatedlinkedToEndDate: any = data.linked_to_end_date;
            if (event.target.checked) {
              toBeUpdatedlinkedToEndDate = true;
            } else {
              toBeUpdatedlinkedToEndDate = null;
            }
            let action = "";
            if (
              data.linked_to_end_date === false &&
              toBeUpdatedlinkedToEndDate === true
            ) {
              action = "update";
            }
            activity = {
              id: data.id,
              linked_to_end_date: toBeUpdatedlinkedToEndDate,
              action
            };
            onCheckBoxAction(activity);
          }}
        >
          <i>{endDate}</i>
        </Checkbox>
      ) : (
        <i className="mr-2">{endDate}</i>
      )}
    </div>
  );
}

function ActivityGridList({
  activityList,
  selectedList,
  hasActive,
  loadingScheduleVersion,
  scheduleListData,
  overlayLoading,
  onToggleSelect,
  setActiveSchedule,
  apiCallBack
}: IActivityGridProps) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [treeActivityList, setTreeActivityList] =
    useState<Array<ScheduleTaskType>>();
  const gridRef = useRef<AgGridReact<ScheduleTaskType>>(null);
  const [isGridReady, setGridReady] = useState(false);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [selectedActivities, setSelectedActivities] = useState<any>();

  function setNodesCollapseandExpand(id: string, isExpanded: boolean) {
    gridRef.current?.api.forEachNode((node) => {
      if (node.data?.source_task_id === id) {
        // const isExpanded = !node.expanded;
        gridRef.current?.api.setRowNodeExpanded(node, isExpanded);
        // eslint-disable-next-line array-callback-return
        node.allLeafChildren.map((leafNode) => {
          gridRef.current?.api.setRowNodeExpanded(
            leafNode,
            leafNode.expanded === isExpanded
              ? leafNode.expanded
              : !leafNode.expanded
          );
        });
      }
    });
  }

  const cellWithTaskId = (params: any) => {
    if (params.data.type !== 5) {
      return params.data.source_task_id;
    }

    if (params?.node?.allChildrenCount) {
      return params.data.source_task_id;
    }

    return (
      <Tooltip
        title="You can only link an activity, milestones can't be linked."
        placement="topLeft"
      >
        <div className="flex items-center">
          <div className="flex h-5 w-5 items-center">
            <NoLinkIcon fill="#3B3B3B33" size={18} />
          </div>
          <i className="mr-2">{params.data.source_task_id}</i>
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const [columnDefs] = useState<ColDef[]>([
    {
      colId: "source_task_id",
      field: "source_task_id",
      headerName: "ID",
      headerTooltip: "ID",
      tooltipValueGetter: (params) => {
        if (params.data.type === 5 && !params.node?.allChildrenCount) {
          return "";
        }
        return params.value;
      },
      showRowGroup: true,
      filter: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: cellWithTaskId
      },
      sort: "desc",
      width: 80
    },
    {
      colId: "text",
      field: "text",
      headerName: "Name",
      headerTooltip: "NAME",
      tooltipField: "text",
      filter: true,
      minWidth: 150
    },
    {
      colId: "start_date",
      field: "start_date",
      headerName: "Start Date",
      headerTooltip: "START DATE",
      headerClass: "dateHedderCell",
      cellRenderer: CellWithDateCheckbox,
      cellRendererParams: {
        isStartDate: true,
        onCheckBoxAction: (activity: any) => {
          setSelectedActivities(activity);
        }
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: (params) =>
        params.value ? DateUtils.format(params.value, "MM-DD-YYYY") : "",
      minWidth: 150,
      maxWidth: 150,
      filter: true
    },
    {
      colId: "end_date",
      field: "end_date",
      headerName: "End Date",
      headerTooltip: "END DATE",
      headerClass: "dateHedderCell",
      cellRenderer: CellWithDateCheckbox,
      cellRendererParams: {
        isStartDate: false,
        onCheckBoxAction: (activity: any) => {
          setSelectedActivities(activity);
        }
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: (params) =>
        params.value ? DateUtils.format(params.value, "MM-DD-YYYY") : "",
      minWidth: 150,
      maxWidth: 150,
      filter: true
    }
  ]);

  function getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    const result: (string | MenuItemDef)[] = [
      {
        // custom item
        name: "Expand All",
        action: () => {
          setNodesCollapseandExpand(
            params?.node?.data?.source_task_id || "",
            true
          );
        },
        icon: `<img src='${ExpandIconPath}'>`
      },
      {
        // custom item
        name: "Collapse All",
        action: () => {
          setNodesCollapseandExpand(
            params?.node?.data?.source_task_id || "",
            false
          );
        },
        icon: `<img src='${CollapseIconPath}'>`
      }
    ];

    return (params?.node?.allLeafChildren || []).length > 1 ? result : [];
  }

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      menuTabs: [],
      getContextMenuItems,
      cellRendererParams: {
        suppressCount: true
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridOptions: GridOptions = {
    defaultColDef,
    suppressCopyRowsToClipboard: true,
    getContextMenuItems
  };

  useEffect(() => {
    if (activityList.length) {
      const importLogId = activityList?.[0]?.import_log_id || "";
      setSelectedSchedule(importLogId);
    }
  }, [activityList]);

  useEffect(() => {
    if (activityList) {
      const filteredTasks = activityList?.filter(
        (ts: ScheduleTaskType) => ts.type !== 3 && ts.type !== 4
      );

      const linkedTasks = { ...selectedList };
      const allTasks: any[] = [];

      filteredTasks.forEach((tk) => {
        const task = { ...tk };
        task.linked_to_end_date = null;
        Object.keys(linkedTasks).forEach((key) => {
          if (tk.id === key) {
            task.linked_to_end_date = linkedTasks[key].linked_to_end_date;
          }
        });

        allTasks.push(task);
      });

      const addParent = (t: ScheduleTaskType, org: Array<any>) => {
        if (t.parent) {
          const parent = allTasks?.find(
            (ts: ScheduleTaskType) => ts.id === t.parent
          );
          if (parent) addParent(parent, org);
        }
        org.push(t.id);
      };
      const tree = allTasks?.map((t: ScheduleTaskType) => {
        const org: any[] = [];
        if (t) addParent(t, org);
        return { org, ...t };
      });
      setTreeActivityList(tree);
    }
  }, [activityList]);

  useEffect(() => {
    if (apiCallBack) {
      setSelectedActivities(apiCallBack);
    }
  }, [apiCallBack]);

  useEffect(() => {
    if (!selectedActivities) return;
    if (
      !selectedList[selectedActivities.id] &&
      selectedActivities.action === "update"
    )
      return;

    const allNodes: Array<RowNode> =
      gridRef?.current?.api?.getRenderedNodes() || [];

    if (selectedActivities.id && allNodes?.length > 0) {
      const rowNode: any = allNodes.find((node: RowNode) => {
        return selectedActivities.id === node.key;
      });

      if (rowNode?.data) {
        const rowData: any = rowNode.data;
        const action = selectedActivities?.action || "";
        if (action === "deleted") {
          rowData.linked_to_end_date = null;
          rowNode.setData(rowData);
        } else if (action === "cancelled") {
          rowData.linked_to_end_date =
            selectedList[rowNode.key].linked_to_end_date;
          rowNode.setData(rowData);
        } else {
          rowData.linked_to_end_date = selectedActivities.linked_to_end_date;
          rowNode.setData(rowData);
          onToggleSelect(rowNode?.key, {
            linked_to_end_date: selectedActivities.linked_to_end_date,
            selectedList
          });
        }

        setSelectedActivities(null);
      }
    }
  }, [selectedActivities, selectedList]);

  const getSelectedScheduleLabel = () => {
    const selectedScheduleObj = scheduleListData?.schedule_import_details?.find(
      (e: any) => e?.import_log?.id === selectedSchedule
    );
    return `${selectedScheduleObj?.schedule_name}_${selectedScheduleObj?.schedule_date}`;
  };

  const getDataPath = useMemo(() => {
    return (data: any) => {
      return data.org;
    };
  }, []);

  return (
    <ErrorBoundary>
      <div className="activity-grid">
        {!hasActive && scheduleListData?.schedule_import_details?.length > 1 ? (
          <div className="activity-grid--header ">
            <Dropdown
              overlay={
                <div className="activity-grid--version skip-listing-window">
                  {scheduleListData?.schedule_import_details?.map(
                    (item: any) => {
                      return (
                        <div
                          className="activity-grid--version__option"
                          onClick={() => {
                            if (item?.import_log?.id === selectedSchedule) {
                              setOpenDropdown(false);
                              return;
                            }
                            setActiveSchedule &&
                              setActiveSchedule(item?.import_log?.id);
                            setSelectedSchedule(item?.import_log?.id);
                            setOpenDropdown(false);
                          }}
                        >
                          {item?.schedule_name &&
                            `${item?.schedule_name}_${item?.schedule_date}`}
                        </div>
                      );
                    }
                  )}
                </div>
              }
              trigger={["click"]}
              open={openDropdown}
              onOpenChange={(open) => {
                setOpenDropdown(open);
              }}
            >
              <Button loading={loadingScheduleVersion}>
                {selectedSchedule.length
                  ? getSelectedScheduleLabel()
                  : "Select Schedule Version"}
              </Button>
            </Dropdown>
            <Search
              className="linking-search"
              placeholder="Search Activity"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
                gridRef.current?.api?.setQuickFilter(e.target.value);
              }}
            />
          </div>
        ) : (
          <Search
            className="linking-search"
            placeholder="Search Activity"
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
              gridRef.current?.api?.setQuickFilter(e.target.value);
            }}
          />
        )}
        <div className="h-full">
          <div className="h-[calc(100vh-231px)]">
            <div style={containerStyle}>
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact<ScheduleTaskType>
                  ref={gridRef}
                  rowData={treeActivityList}
                  columnDefs={columnDefs}
                  gridOptions={gridOptions}
                  defaultColDef={defaultColDef}
                  animateRows
                  headerHeight={40}
                  onGridReady={() => {
                    setGridReady(true);
                    gridRef.current?.api.sizeColumnsToFit();
                  }}
                  treeData
                  loadingOverlayComponent={GridLoadingIndicator}
                  rowSelection="single"
                  groupDefaultExpanded={-1}
                  getDataPath={getDataPath}
                  groupDisplayType="custom"
                  suppressCellFocus
                  suppressDragLeaveHidesColumns
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                />
              </div>
            </div>
          </div>
        </div>

        {overlayLoading ? (
          <div className="activity-grid--overlay-loading">
            <div>
              <GridLoadingIndicator />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </ErrorBoundary>
  );
}

function ActivityGrid({
  activityList,
  selectedList,
  onToggleSelect,
  reFetchActivity,
  apiCallBack
}: IActivityProps) {
  const [hasActive, setHasActive] = useState(true);
  const [enablingSchedule, setEnablingSchedule] = useState(false);
  const { gqlClientForProject, tokenRetrievalState } =
    useContext(ProjectContext);
  const [refresh, setRefresh] = useState(false);
  const { data: scheduleListData, loading } = useSubscription(
    SUBSCRIPTION_GET_SCHEDULE_VERSIONS,
    {
      shouldResubscribe: true,
      client: gqlClientForProject
    }
  );

  const setActiveSchedule = (fileId: string) => {
    setEnablingSchedule(true);
    makeScheduleAsActive(fileId, tokenRetrievalState?.token)
      .then(() => {
        setRefresh(true);
        reFetchActivity && reFetchActivity();
      })
      .finally(() => {
        setEnablingSchedule(false);
      });
  };

  useEffect(() => {
    if (scheduleListData?.schedule_import_details?.length) {
      const scheduleImportDetails =
        scheduleListData?.schedule_import_details || [];
      const hasActiveTemp = scheduleImportDetails.find((e: any) => e.is_active);
      setHasActive(!!hasActiveTemp);
      if (hasActiveTemp) {
        return;
      }
      if (activityList.length > 0) {
        return;
      }
      const id = scheduleListData?.schedule_import_details[0]?.import_log?.id;
      setActiveSchedule(id);
    }
  }, [scheduleListData]);

  if (activityList?.length > 0) {
    return (
      <ActivityGridList
        {...{
          activityList,
          selectedList,
          hasActive,
          loadingScheduleVersion: loading,
          overlayLoading: enablingSchedule,
          onToggleSelect,
          reFetchActivity,
          scheduleListData,
          setActiveSchedule,
          apiCallBack
        }}
      />
    );
  }

  if (loading || refresh) {
    return (
      <div className="activity-grid--loading">
        <div>
          <GridLoadingIndicator />
        </div>
      </div>
    );
  }

  if (scheduleListData?.schedule_import_details?.length === 0) {
    return (
      <div className="w-full text-center pt-3 activity-grid--no-schedule mt-24">
        No activities found. Click{" "}
        <Link to="project-schedule" className="italic">
          here
        </Link>{" "}
        to upload a schedule
      </div>
    );
  }

  return (
    <div className="activity-grid--loading">
      <div>
        <GridLoadingIndicator />
      </div>
    </div>
  );
}

export default ActivityGrid;
