import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Button, Drawer, Spin } from "antd";
import moment from "moment";
import ErrorBoundary from "components/error-boundary";
import MaterialPlanningNavbar from "./navbar";
import { FailureSection, VerticalNavList, LeadTimeErrorCodes } from "./widgets";
import "./material-planning.scss";
import { EstimateStatus, IMaterial } from "./type-definitions";
import MaterialDetailsSection from "./material-details-section";
import MaterialDurationsSection from "./material-durations-section";
import { getLeadTimeRequestDetails } from "./services";
import RequestDetailsSection from "./request-details-section";
import ResponsiveFooter from "./responsive-footer";
import IntroSection from "./intro-section";

function MaterialPlanning() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const leadTimeRequestToken: string | null = params.get("token");

  const [leadTimeRequestData, setLeadTimeRequestData] = useState<any>(null);
  const [loadingLeadTimeRequestData, setLoadingLeadTimeRequestData] =
    useState(true);

  const [leadTimeDataFetchErrorCode, setLeadTimeDataFetchErrorCode] =
    useState<LeadTimeErrorCodes | null>(null);

  // Note: kept at a global level so that it can be used at other places
  const [submittingLeadTime, setSubmittingLeadTime] = useState(false);

  const [activeMaterialIndex, setActiveMaterialIndex] = useState(0);

  const [isMaterialListDrawerOpen, setIsMaterialListDrawerOpen] =
    useState(false);

  const [showIntroSection, setShowIntroSection] = useState(false);

  const materialDetailsSectionRef = useRef<HTMLDivElement>(null);

  const getLeadTimeData = useCallback(async () => {
    if (!leadTimeRequestToken) {
      setLeadTimeDataFetchErrorCode(LeadTimeErrorCodes.JWT_INVALID_OR_EXPIRED);
      return;
    }

    setLoadingLeadTimeRequestData(true);
    const response = await getLeadTimeRequestDetails(leadTimeRequestToken!);
    if (response.success) {
      if (response.data.timezone_id) {
        moment.tz.setDefault(response.data.timezone_id);
      }

      setTimeout(() => {
        // so that moment timezone is properly set before rendering
        setLeadTimeRequestData(response.data);
        setLoadingLeadTimeRequestData(false);
      });
    }

    if (response?.error) {
      if (response?.error?.code === "ERR_BAD_REQUEST") {
        setLeadTimeDataFetchErrorCode(
          LeadTimeErrorCodes.JWT_INVALID_OR_EXPIRED
        );
      } else {
        setLeadTimeDataFetchErrorCode(LeadTimeErrorCodes.FAILED_TO_FETCH_DATA);
      }
    }
  }, [leadTimeRequestToken]);

  useEffect(() => {
    getLeadTimeData();
  }, [getLeadTimeData]);

  const selectedMaterial = leadTimeRequestData?.materials[activeMaterialIndex];

  // close the material list drawer on window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMaterialListDrawerOpen(false);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setIsMaterialListDrawerOpen(false);
      });
    };
  }, []);

  const hasNoSubmissions = useMemo(() => {
    return leadTimeRequestData?.materials.every(
      (material: IMaterial) =>
        material.estimate_status === EstimateStatus.PENDING
    );
  }, [leadTimeRequestData]);

  useEffect(() => {
    if (hasNoSubmissions) {
      setShowIntroSection(true);
    }
  }, [hasNoSubmissions]);

  const onUserSelectsMaterial = (materialIndex: number) => {
    setSubmittingLeadTime(false);
    setActiveMaterialIndex(materialIndex);

    const selectedMaterialDOMElement = materialDetailsSectionRef.current;
    selectedMaterialDOMElement?.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (leadTimeDataFetchErrorCode) {
    return (
      <FailureSection
        errorCode={leadTimeDataFetchErrorCode}
        onRetry={() => {
          setLeadTimeDataFetchErrorCode(null);
          getLeadTimeData();
        }}
      />
    );
  }

  if (!leadTimeRequestData && loadingLeadTimeRequestData) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="material-planning-page h-[100dvh] flex flex-col overflow-y-hidden">
      <div className="shrink-0 overflow-x-auto">
        <ErrorBoundary>
          <MaterialPlanningNavbar
            userName={`${leadTimeRequestData.assignee_first_name} ${leadTimeRequestData.assignee_last_name}`}
            companyName={
              leadTimeRequestData.assignee_vendor_name ||
              leadTimeRequestData.assignee_organization_name
            }
          />
        </ErrorBoundary>
      </div>

      <div className="grow flex flex-col overflow-y-hidden">
        <div className="md:px-10 w-full border-solid border-0 border-b border-color-1 pb-0.5">
          <div className="max-h-[40dvh] overflow-y-auto">
            <ErrorBoundary>
              <RequestDetailsSection requestDetails={leadTimeRequestData} />
            </ErrorBoundary>
          </div>
        </div>

        {showIntroSection ? (
          <div className="grow min-h-0 w-full flex bg-white overflow-y-auto">
            <IntroSection
              onStart={() => setShowIntroSection(false)}
              requestDetails={leadTimeRequestData}
            />
          </div>
        ) : (
          <div className="grow w-full flex bg-white overflow-hidden">
            <div className="w-[250px] lg:w-[350px] overflow-y-auto shrink-0 border-0 border-r border-solid border-color-2 hidden md:block">
              <div>
                <div className="p-5 text-sm font-semibold text-color-1">
                  Requested Materials
                </div>
                <ErrorBoundary>
                  <VerticalNavList
                    items={leadTimeRequestData?.materials}
                    onChange={onUserSelectsMaterial}
                    activeKey={selectedMaterial?.id ?? null}
                  />
                </ErrorBoundary>
              </div>
            </div>
            <div className="grow overflow-hidden material-details-section vertical-shadow md:no-vertical-shadow py-0.5">
              <div
                className="h-full max-h-full overflow-y-auto relative"
                ref={materialDetailsSectionRef}
              >
                {selectedMaterial && (
                  <div className="flex items-start justify-between flex-wrap">
                    <div className="w-full lg:sticky lg:top-0 lg:w-1/2">
                      <ErrorBoundary>
                        <MaterialDetailsSection
                          material={selectedMaterial}
                          key={selectedMaterial.id}
                        />
                      </ErrorBoundary>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <ErrorBoundary>
                        <MaterialDurationsSection
                          material={selectedMaterial}
                          key={selectedMaterial.id}
                          token={leadTimeRequestData.token}
                          submittingState={[
                            submittingLeadTime,
                            setSubmittingLeadTime
                          ]}
                          onSubmitSuccess={() => {
                            getLeadTimeData();
                          }}
                        />
                      </ErrorBoundary>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!showIntroSection && leadTimeRequestData.materials.length > 1 && (
          <ResponsiveFooter
            leadTimeRequestData={leadTimeRequestData}
            activeMaterialIndex={activeMaterialIndex}
            onMaterialNavigation={onUserSelectsMaterial}
            setIsMaterialListDrawerOpen={setIsMaterialListDrawerOpen}
          />
        )}
        <Drawer
          placement="left"
          closable={false}
          onClose={() => {
            setIsMaterialListDrawerOpen(false);
          }}
          open={isMaterialListDrawerOpen}
          key="left"
          className="material-planning-page material-list-drawer"
          maskClosable
        >
          <div className="h-full max-h-full flex flex-col">
            <div className="p-5 text-sm font-semibold text-color-1 shrink-0">
              Requested Materials
            </div>
            <div className="grow overflow-y-auto">
              <ErrorBoundary>
                <VerticalNavList
                  items={leadTimeRequestData?.materials}
                  onChange={(material) => {
                    onUserSelectsMaterial(material);
                    setIsMaterialListDrawerOpen(false);
                  }}
                  activeKey={selectedMaterial?.id ?? null}
                />
              </ErrorBoundary>
            </div>
            <div className="shrink-0 px-5 py-3">
              <Button
                htmlType="button"
                type="primary"
                onClick={() => {
                  setIsMaterialListDrawerOpen(false);
                }}
                className="w-full"
              >
                Close
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default MaterialPlanning;
