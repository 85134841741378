/* eslint-disable import/order */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Tooltip, message } from "antd";
import { useContext, useMemo, useRef, useState } from "react";
import { ExclamationCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import modal from "antd/lib/modal";
import { ProjectContext } from "context/ProjectProvider";
import {
  MUTATION_INSERT_LINKING_MATERIAL_SCHEDULE_BULK,
  MUTATION_DELETE_LINKING_MATERIAL_SCHEDULE
} from "services/graphQL/mutations";
import { useParams } from "react-router";
import {
  MaterialLinkedType,
  ScheduleLinkingType,
  ScheduleTaskType
} from "./models";
import "./material-card.scss";
import ActivityGrid from "./activity-grid";
import ListingWindow from "./listing-window";
import { Link } from "react-router-dom";
import ErrorBoundary from "components/error-boundary";
import MaterialIcon from "components/svg-icons/material-icon";
import ActivityIcon from "components/svg-icons/activity-icon";
import { DateUtils } from "utils/dateutils";
import { ErrorMessages } from "constants/index";
import { mergedStrings } from "utils/utils";

interface IMaterialCardProps {
  activityList: Array<ScheduleTaskType>;
  material: MaterialLinkedType;
  selected: boolean;
  submittalId: string;
  onMaterialClick: (material: MaterialLinkedType) => void;
  onUnlink: any;
  reFetchActivity?: () => void;
  canEdit: boolean;
}
function MaterialCard({
  material,
  activityList,
  selected,
  onMaterialClick,
  submittalId,
  onUnlink,
  reFetchActivity,
  canEdit
}: IMaterialCardProps) {
  const buttonRef = useRef<HTMLElement>(null);
  const [openActivityList, setOpenActivityList] = useState(false);
  const { gqlClientForProject } = useContext(ProjectContext);
  const { projectId } = useParams<{ projectId: string }>();
  const [apiCallBack, setApiCallBack] = useState<any>();

  const [linkingMaterialScheduleMutation] = useMutation(
    MUTATION_INSERT_LINKING_MATERIAL_SCHEDULE_BULK,
    {
      client: gqlClientForProject
    }
  );
  const [deleteLinkingMaterialScheduleMutation] = useMutation(
    MUTATION_DELETE_LINKING_MATERIAL_SCHEDULE,
    {
      client: gqlClientForProject
    }
  );

  const onLinkingMaterialSchedule = (
    selectedScheduleId: String,
    linked_to_end_date: boolean
  ) => {
    const materialIds = material?.material?.ids || [material?.material?.id];
    const objects = materialIds.map((id) => ({
      task_id: selectedScheduleId,
      material_id: id,
      linked_to_end_date
    }));

    linkingMaterialScheduleMutation({
      variables: {
        objects
      }
    }).then((res) => {
      if (res.data) {
        console.log(res.data);
      }
      if (res.errors) {
        if (res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)) {
          message.error(ErrorMessages.linkingErrorMsg);
          return;
        }
        message.error(res.errors[0].message);
      }
    });
  };

  const onRemoveLinkingMaterialSchedule = (scheduleId: string) => {
    const materialData = material?.material;
    modal.confirm({
      title: "Do you want to unlink?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      className: "skip-listing-window",
      onOk() {
        const materialIds = materialData.ids || [materialData.id];
        deleteLinkingMaterialScheduleMutation({
          variables: {
            where: {
              material_id: { _in: materialIds },
              task_id: { _eq: scheduleId }
            }
          }
        }).then((res) => {
          const activity = {
            id: scheduleId,
            action: "deleted"
          };
          setApiCallBack(activity);
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
        });
      },
      onCancel() {
        const activity = {
          id: scheduleId,
          action: "cancelled"
        };
        setApiCallBack(activity);
      },
      okText: "Yes",
      cancelText: "No"
    });
  };
  const onChange = (id: string, params?: any) => {
    const selectedActivity = Object.keys(params.selectedList)?.find(
      (scheduleId: string) => scheduleId === id
    );
    if (selectedActivity) {
      if (!params || (params && params.linked_to_end_date === null)) {
        onRemoveLinkingMaterialSchedule(id);
      } else {
        const materialData = material?.material;
        const materialIds = materialData.ids || [materialData.id];
        deleteLinkingMaterialScheduleMutation({
          variables: {
            where: {
              material_id: { _in: materialIds },
              task_id: { _eq: id }
            }
          }
        }).then((res) => {
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
          if (!res.errors) {
            onLinkingMaterialSchedule(id, params.linked_to_end_date);
          }
        });
      }
    } else {
      onLinkingMaterialSchedule(id, params.linked_to_end_date);
    }
  };

  const selectedList = useMemo(() => {
    const tmpSelectedListObj: any = {};
    material?.material?.material_schedule_links?.forEach(
      (item: ScheduleLinkingType) => {
        tmpSelectedListObj[item.gantt_task.id] = {
          linked_to_end_date: item.linked_to_end_date
        };
      }
    );
    return tmpSelectedListObj || {};
  }, [material?.material?.material_schedule_links]);

  const title = !material?.material?.implicit ? (
    <span>
      <i className="text-[#3b3b3bcc]">{material?.material?.material_id}</i>
      <i className="text-[#3b3b3bcc] ml-2">
        {mergedStrings([
          material?.material?.spec_section_no || "",
          material?.material?.spec_section_name || ""
        ])}
      </i>
      <div>{material?.material?.name}</div>
    </span>
  ) : (
    `Material for Submittal ${submittalId}`
  );

  return (
    <ErrorBoundary>
      <div>
        <div
          className={`cursor-pointer material-card--item flex justify-between ${
            selected ? "selected" : ""
          }`}
          onClick={() => {
            onMaterialClick(material);
          }}
        >
          <MaterialIcon className="mr grow-none" size={20} fill="#a8a8a8" />
          <Tooltip
            className="flex-grow truncate"
            placement="topLeft"
            title={title}
          >
            <Link
              className="grow-none"
              to={`/project/${projectId}/materials/${material?.material?.id}`}
            >
              <i>{material?.material?.material_id}</i>
            </Link>

            <i className="mr-2">
              {material?.material?.spec_section_no || ""}{" "}
              {material?.material?.spec_section_no?.length > 0 &&
              material?.material?.spec_section_name?.length
                ? "-"
                : ""}
              {material?.material?.spec_section_name || ""}{" "}
            </i>
            {material?.driving_material ? (
              <span className="text-base mr-1 pt-[10px] leading-[0]">*</span>
            ) : (
              ""
            )}
            <span>
              {material?.material?.implicit
                ? `Material for Submittal ${submittalId}`
                : material?.material?.name}
            </span>
          </Tooltip>
          {!material?.material?.implicit ? (
            <Button
              size="small"
              onClick={(e: any) => {
                e.stopPropagation();
                onUnlink(material?.material?.id);
              }}
              type="link"
              disabled={material?.material?.implicit || !canEdit}
              className="linking--unlink-button grow-none skip-listing-window"
            >
              Unlink <LinkOutlined />
            </Button>
          ) : (
            ""
          )}
        </div>
        {material?.material?.material_schedule_links?.map(
          (task: ScheduleLinkingType) => {
            const taskData = activityList.find(
              (item: ScheduleTaskType) => item.id === task.gantt_task.id
            );
            if (taskData) {
              return (
                <div
                  key={task.gantt_task.id}
                  className="material-card--item flex justify-between"
                  style={{
                    marginLeft: "5%"
                  }}
                >
                  <ActivityIcon
                    size={20}
                    fill="#a8a8a8"
                    className="grow-none"
                  />
                  <i className="mr-2 grow-none">{taskData.source_task_id}</i>
                  <Tooltip
                    title={taskData.text}
                    className="flex grow truncate"
                    placement="topLeft"
                  >
                    {task.driving_task ? (
                      <span className="text-base mr-1 pt-[10px] leading-[0]">
                        *
                      </span>
                    ) : (
                      ""
                    )}
                    {taskData.text}{" "}
                    {taskData?.start_date &&
                    task.linked_to_end_date === false ? (
                      <i>
                        Start Date :{" "}
                        {taskData?.start_date
                          ? DateUtils.format(taskData?.start_date)
                          : ""}
                      </i>
                    ) : null}
                    {taskData?.end_date && task.linked_to_end_date === true ? (
                      <i>
                        End Date :{" "}
                        {taskData?.end_date
                          ? DateUtils.format(taskData?.end_date)
                          : ""}
                      </i>
                    ) : null}
                  </Tooltip>
                  <Button
                    size="small"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onChange(taskData.id, {
                        linked_to_end_date: null,
                        selectedList
                      });
                    }}
                    type="link"
                    disabled={!canEdit}
                    className="linking--unlink-button grow-none skip-listing-window"
                  >
                    Unlink <LinkOutlined />
                  </Button>
                </div>
              );
            }
            return "";
          }
        )}

        <div className="flex justify-end mb-4" style={{ marginRight: "6px" }}>
          <Button
            ref={buttonRef}
            disabled={!canEdit}
            onClick={() => setOpenActivityList(true)}
          >
            + Associate Activity
          </Button>
          {openActivityList ? (
            <ListingWindow
              onClose={() => setOpenActivityList(false)}
              title="List of Activities"
              isFromSubmittals={undefined}
            >
              <div className="material-card--options">
                <ActivityGrid
                  activityList={activityList}
                  selectedList={selectedList}
                  onToggleSelect={onChange}
                  reFetchActivity={reFetchActivity}
                  apiCallBack={apiCallBack}
                />
              </div>
            </ListingWindow>
          ) : (
            ""
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default MaterialCard;
