import { Button, Divider, message, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useMemo } from "react";
import { DateUtils } from "utils/dateutils";
import { useRequestDurations } from "./hooks/request-durations";

function RequestedDurationDetails({
  selectedItem,
  onCancel
}: {
  selectedItem: { id: string };
  onCancel: () => void;
}) {
  const {
    data: queryResponse,
    loading,
    sendInvite,
    sendingInvite
  } = useRequestDurations([selectedItem.id]);

  const materialData: any = useMemo(() => {
    if (!queryResponse) return null;
    const material = queryResponse.material[0];
    return {
      id: material.id,
      name: material.name,
      materialAssignee: material.material_assignee,
      assigneeName: material.material_assignee
        ? `${material.material_assignee?.first_name} ${material.material_assignee?.last_name}`
        : "",
      assigneeEmail: material.material_assignee?.email,
      tradePartner: material?.materials_trade_partner?.name,
      estimatedInvite: material?.estimate_invite_features[0]?.estimated_invite,
      inviteCreatedAt:
        material?.estimate_invite_features[0]?.estimated_invite?.created_at
    };
  }, [queryResponse]);

  const resendInvite = async () => {
    const variables = {
      sendEstimateInviteData: [
        {
          assignee: materialData?.materialAssignee.id,
          material_ids: [materialData.id],
          notes: materialData?.estimatedInvite?.notes
        }
      ]
    };

    const response = await sendInvite(variables);

    if (response.success) {
      message.success("Request sent successfully via email.");
      onCancel();
    }
  };

  if (loading && !queryResponse)
    return (
      <div
        className="min-h-[260px] flex justify-center items-center"
        data-testid="loading-spinner"
      >
        <Spin size="small" />
      </div>
    );

  return (
    <div className="px-4 py-3">
      <div className="text-sm">
        A request was recently sent to the Subcontractor to enter Lead Times.
      </div>
      <div className="my-5 space-y-1">
        {materialData?.materialAssignee && (
          <div>
            <div className="text-sm font-semibold">
              {materialData?.tradePartner}
            </div>
            <div className="text-xs">
              {materialData?.assigneeName} ({materialData?.assigneeEmail})
            </div>
          </div>
        )}
        <div className="text-xs">{materialData?.name}</div>
        {materialData?.inviteCreatedAt && (
          <div className="text-xs">
            {DateUtils.format(materialData?.inviteCreatedAt)}
          </div>
        )}
      </div>
      <div>
        <div className="form-field-heading-2">Notes</div>
        <TextArea
          rows={2}
          autoSize={{ minRows: 2, maxRows: 2 }}
          disabled
          value={materialData?.estimatedInvite?.notes}
          title={materialData?.estimatedInvite?.notes ? "" : "No notes added"}
        />
      </div>
      <Divider className="m-0 p-0 mt-3 pt-2.5" />
      <div className="flex justify-end gap-2">
        <Button
          type="default"
          onClick={onCancel}
          data-testid="cancel-button"
          disabled={sendingInvite}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          data-testid="send-request-button"
          onClick={resendInvite}
          loading={sendingInvite}
        >
          Resend Request
        </Button>
      </div>
    </div>
  );
}

export default RequestedDurationDetails;
