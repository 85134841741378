import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Popover, Select, message } from "antd";
import {
  createProjectIntegration,
  getCompanyAccounts
} from "services/integration-service";
import WarningIcon from "components/svg-icons/warning-icon";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IntegrationType } from "pages/subscription-settings/integrations-tab-new/utils";
import { useWatch } from "antd/lib/form/Form";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { ProjectIntegrationsTabSharedContext } from "../project-integrations-tab-context";

export default function ProjectProcoreInitialIntegration(props: any) {
  const { onProjectIntegrated } = props;
  const { sharedState, dispatch } = useContext(
    ProjectIntegrationsTabSharedContext
  );

  const { tokenRetrievalState }: TProjectContext = useContext(ProjectContext);

  const [form] = Form.useForm();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [projects, setProjects] = useState<any>(null);

  const [submittingForm, setSubmittingForm] = useState(false);

  const companies = useMemo(() => {
    const comp: any = [];
    const procoreSubConfigs = sharedState.subscriptionIntegrationConfigs.filter(
      (subConfig: any) => {
        return (
          subConfig.integration.system ===
          IntegrationType[IntegrationType.PROCORE]
        );
      }
    );
    procoreSubConfigs.forEach((procoreConfig: any) => {
      comp.push({
        name: procoreConfig.properties.PROCORE_COMPANY_NAME,
        id: procoreConfig.properties.PROCORE_COMPANY_ID,
        subscriptionId: procoreConfig.id
      });
    });
    return comp;
  }, [sharedState.subscriptionIntegrationConfigs]);

  const selectedCompanyId = useWatch("account_id", form);

  useEffect(() => {
    if (!selectedCompanyId || !tokenRetrievalState.token) return;
    const getAccounts = async () => {
      const foundCompany = companies.find((company: any) => {
        return company.id === selectedCompanyId;
      });

      // API has been changed in AutoDesk but it was decided to keep the same in Procore (CJ, HB)
      const accountsResp = await getCompanyAccounts(
        sharedState.system,
        {
          subscriptionConfigId: foundCompany.subscriptionId
        },
        tokenRetrievalState?.token
      );

      if (accountsResp.success) {
        setProjects(accountsResp.data);
      }
    };
    getAccounts();
  }, [
    companies,
    selectedCompanyId,
    sharedState.system,
    tokenRetrievalState.token
  ]);

  const onFinish = (values: any) => {
    setSubmittingForm(true);
    const foundCompany = companies.find((company: any) => {
      return company.id === values.account_id;
    });

    const foundProject = projects.find((project: any) => {
      return project.id === values.project_id;
    });
    const payload: any = [
      {
        properties: {
          integration_name: sharedState?.additionalData.newIntegrationName,
          procore_project_id: values.project_id.toString(),
          procore_project_name: foundProject.name,
          project_alert_email: values.alert_email.join(",")
        },
        subscription_config_id: foundCompany.subscriptionId
      }
    ];

    const saveData = async () => {
      const createResponse = await createProjectIntegration(
        sharedState.system,
        payload,
        tokenRetrievalState.token
      );

      setSubmittingForm(false);

      if (createResponse.success) {
        message.success("Integration created successfully.");
        onProjectIntegrated(createResponse);
        dispatch({
          type: "SET_ACTIVE_LIST_VIEW"
        });
      } else {
        message.error("Failed to create integration.");
      }
    };
    saveData();
  };

  const confirmPopoverContent = (
    <div>
      <div className="flex justify-center">
        <WarningIcon />
      </div>
      <div className="flex justify-center text-lg font-medium">
        Please confirm your action
      </div>
      <div className="mt-2 mb-3.5">
        Please review and verify the entered details before you save.
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Button
          onClick={() => {
            setShowConfirmDialog(false);
          }}
          disabled={submittingForm}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
          loading={submittingForm}
        >
          Save
        </Button>
      </div>
    </div>
  );

  const companyListOptions = useMemo(() => {
    return companies?.map((company: any) => {
      return {
        label: company.name,
        value: company.id
      };
    });
  }, [companies]);

  const projectListOptions = useMemo(() => {
    return projects?.map((project: any) => {
      return {
        label: project.name,
        value: project.id
      };
    });
  }, [projects]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="h-full flex flex-col"
    >
      <div className="h-full relative">
        <div className="grow w-full md:max-w-screen-sm mx-auto py-4">
          <div className="mt-7 w-[500px]">
            <Form.Item
              name="account_id"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>ACCOUNT NAME</span>
                </div>
              }
              rules={[{ required: true, message: "Please select an Account" }]}
              requiredMark={false}
            >
              <Select
                showSearch={false}
                // mode="multiple"
                options={companyListOptions}
                // tagRender={tagRender}
                placeholder="Select an Account"
                className="normal-case"
                loading={!companyListOptions}
                allowClear={false}
                showArrow
              />
            </Form.Item>
            <Form.Item
              name="project_id"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>PROJECT NAME</span>
                </div>
              }
              rules={[{ required: true, message: "Please select a Project" }]}
              requiredMark={false}
            >
              <Select
                showSearch={false}
                options={projectListOptions}
                placeholder="Select a Project"
                className="normal-case"
                loading={selectedCompanyId && !projectListOptions}
                allowClear={false}
                showArrow
                disabled={!selectedCompanyId || !projectListOptions}
              />
            </Form.Item>
            <Form.Item
              name="alert_email"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>Notification Recipients</span>
                  <Popover
                    content={
                      <div className="text-sm font-medium">
                        Enter the email address of users who need to be notified
                        in case of failure in authorization.
                      </div>
                    }
                  >
                    <InfoCircleOutlined className="pl-1 -mt-0.5" />
                  </Popover>
                </div>
              }
              rules={[
                { required: true, message: "Please enter at least one email" }
              ]}
            >
              <Select
                className="normal-case"
                mode="tags"
                placeholder="Email IDs"
                notFoundContent={null}
                dropdownStyle={{ display: "none" }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <Divider className="mx-0 pb-0 mb-0" />
      <footer className="p-6 flex justify-end gap-x-4">
        <Button
          size="middle"
          onClick={() => {
            dispatch({
              type: "SET_ACTIVE_LIST_VIEW"
            });
          }}
          disabled={submittingForm}
        >
          Back
        </Button>
        <Popover
          placement="topRight"
          content={confirmPopoverContent}
          destroyTooltipOnHide
          open={showConfirmDialog}
        >
          <Button
            size="middle"
            type="primary"
            onClick={async () => {
              try {
                await form.validateFields();
                // console.log("validationResult ", validationResult);
                setShowConfirmDialog(true);
              } catch (ex) {
                console.log(ex);
              }
            }}
            disabled={submittingForm}
          >
            Save
          </Button>
        </Popover>
      </footer>
    </Form>
  );
}
