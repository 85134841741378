import { gql } from "@apollo/client";
import { isPermissionNotGrantted } from "context/ProjectProvider";
import { getLoggedinUserId } from "services/auth";
import { matchUserProjectRole } from "utils/utils";
import {
  ESubmittalStatus,
  EUserRoleName,
  EUserTypes,
  ProjectPermissionEnum
} from "../../constants";

export const getEditableFields = (
  submittalViewState: {
    status: number;
    userType: unknown;
  },
  updatedData: any,
  tokenContents: any
) => {
  if (!updatedData) return [];

  if (updatedData && updatedData?.workflow_status === ESubmittalStatus.VOID)
    return [];

  const userId = getLoggedinUserId();

  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserSC = matchUserProjectRole(
    EUserTypes.SUBCONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserArchitect = matchUserProjectRole(
    EUserTypes.ARCHITECT,
    tokenContents?.role
  );

  const isCurrentUserEngineer = matchUserProjectRole(
    EUserTypes.ENGINEER,
    tokenContents?.role
  );

  const isCurrentUserOwner = matchUserProjectRole(
    EUserTypes.OWNER,
    tokenContents?.role
  );

  const assignedToSelf =
    updatedData.assignee && updatedData.assignee === userId;

  if (submittalViewState.status === ESubmittalStatus.DONE) {
    return [];
  }

  if (isCurrentUserGC) {
    let generalInfoFields: string[] = [];
    if (tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin]) {
      generalInfoFields = [
        "title",
        "custom_spec_section",
        "spec_section",
        "type",
        "description",
        "priority",
        "watchers",
        "float",
        "submittal_number"
      ];
    }

    if (submittalViewState.status === ESubmittalStatus.CREATE) {
      return [
        "title",
        "spec_section",
        "type",
        "issue_date",
        "due_date",
        "ROJ",
        "responsible_contractor",
        "gc_reviewer",
        "design_reviewer",
        "submitter",
        "assignee",
        "gc_representative",
        "description",
        "comment",
        "attachment",
        "assign",
        "review_status",
        "submittal_manager",
        "received_from",
        "custom_spec_section",
        "priority",
        "watchers",
        "float",
        "submittal_number"
      ];
    }
    if (
      assignedToSelf &&
      submittalViewState.status === ESubmittalStatus.REVIEW &&
      updatedData.gc_reviewer === userId
    ) {
      return [
        ...["comment", "attachment", "assign", "review_status"],
        ...generalInfoFields
      ];
    }
    if (assignedToSelf) {
      return [...["comment", "attachment"], ...generalInfoFields];
    }
    if (tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin]) {
      return generalInfoFields;
    }
    return [];
  }

  if (isCurrentUserSC) {
    if (
      assignedToSelf &&
      updatedData.submitter === userId &&
      submittalViewState.status === ESubmittalStatus.SUBMIT
    ) {
      return ["comment", "attachment", "assign"];
    }
    if (
      assignedToSelf &&
      updatedData.submitter !== userId &&
      submittalViewState.status === ESubmittalStatus.SUBMIT
    ) {
      return ["attachment", "comment"];
    }
    return [];
  }

  if (isCurrentUserArchitect) {
    if (assignedToSelf) {
      return ["comment", "attachment", "assign", "review_status"];
    }
    return [];
  }

  if (isCurrentUserEngineer) {
    if (!assignedToSelf) {
      return [];
    }
    if (assignedToSelf) {
      return ["comment", "attachment", "assign", "review_status"];
    }
    return [];
  }

  if (isCurrentUserOwner) {
    if (
      assignedToSelf &&
      updatedData.submitter === userId &&
      submittalViewState.status === ESubmittalStatus.SUBMIT
    ) {
      return ["comment", "attachment", "assign"];
    }
    return [];
  }
  return [];
};

export const agGridCheckFieldEditable = (params: any) => {
  if (
    isPermissionNotGrantted(
      ProjectPermissionEnum.InlineEditSubmittal,
      params.context.tokenContents?.role
    )
  )
    return false;

  let submittalStatus = ESubmittalStatus.CREATE;
  if (params.data.history && params.data.history[0]) {
    submittalStatus = params.data.history[0].status;
  }

  submittalStatus = submittalStatus || ESubmittalStatus.SUBMIT;

  const editableFields = getEditableFields(
    {
      userType: params.context.tokenContents?.userType,
      status: submittalStatus
    },
    params.data,
    params.context.tokenContents
  );
  if (editableFields.includes(params.colDef.colId)) return true;
  return false;
};

export const updateSubmittalOne = async (
  variables: any,
  customGQLClient: any
) => {
  let keysString = "";
  Object.keys(variables).forEach((key: any) => {
    keysString += `${key}: $${key}
    `;
  });
  const mutationStr = `mutation MyMutation($assignee: String = "", $assign_to: String = "", $assignee_unregistered: String = "", $comment: String = "", $deleted: Boolean = false, $description: String = "", $design_reviewer: String = "", $design_reviewer_unregistered: String = "", $design_reviewer_vendor_unregistered: String = "", $due_date: String = "", $gc_reviewer: String = "", $governing_date: String = "", $issue_date: String = "", $on_behalf_of: String = "", $on_behalf_of_unregistered: String = "", $on_behalf_of_vendor: String = "", $para: String = "", $received_from: String = "", $responsible_contractor: String = "", $responsible_contractor_unregistered: String = "", $revision: Int = 10, $ROJ: String = "", $spec_name: String = "", $spec_no: String = "", $spec_section_id: String = "", $status: Int = 10, $sub_section_heading: String = "", $submittal_id: String = "", $submittal_manager: String = "", $submittal_response_id: Int = 10, $submitter: String = "", $submitter_unregistered: String = "", $title: String = "", $type: Int = 10, $actual_milestone_date: String = "") {
    update_submittal_one(
        ${keysString}
    ) {
      message
    }
  }
  `;

  const query = gql(mutationStr);
  let result: any;
  try {
    result = await customGQLClient.mutate({
      mutation: query,
      variables
    });
  } catch (ex: any) {
    console.error("update_submittal_one ", ex);
    return ex;
  }
  return result;
};
