import { Button, Checkbox, message } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import Search from "antd/lib/input/Search";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import Tooltip from "antd/es/tooltip";
import { mergedStrings } from "utils/utils";
import { useMutation } from "@apollo/client";
import modal from "antd/lib/modal";
import { ProjectContext } from "context/ProjectProvider";
import {
  MUTATION_INSERT_LINKING_SUBMITTAL_MATERIAL,
  MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL
} from "services/graphQL/mutations";
import { ErrorMessages } from "constants/index";
import ErrorBoundary from "components/error-boundary";
import { Link, useParams } from "react-router-dom";
import {
  MaterialLinkedType,
  MaterialType,
  ScheduleTaskType,
  SubmittalType
} from "./models";
import MaterialCard from "./material-card";
import "./material-linking.scss";
import ListingWindow from "./listing-window";

interface IMaterialLinkingProps {
  materialList: Array<MaterialType>;
  selectedSubmittal: SubmittalType | undefined;
  activityList: Array<ScheduleTaskType>;
  userSelectedMaterial: MaterialLinkedType | undefined;
  onMaterialClick: (material: MaterialLinkedType) => void;
  reFetchActivity?: () => void;
  canEdit: boolean;
}

function MaterialLinking({
  materialList,
  selectedSubmittal,
  activityList,
  userSelectedMaterial,
  onMaterialClick,
  reFetchActivity,
  canEdit
}: IMaterialLinkingProps) {
  const buttonRef = useRef<HTMLElement>(null);
  const materialLinking = useRef<HTMLDivElement>(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openMaterialList, setOpenMaterialList] = useState(false);
  const [isTrackMaterialDate, setTrackMaterialDate] = useState(true);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [filteredMatList, setFilteredMatList] = useState<Array<MaterialType>>(
    []
  );
  const [isSuggested, setSuggested] = useState(true);
  const [linkedMaterialsWithSubmittal, setLinkedMaterialsWithSubmittal] =
    useState<Array<MaterialLinkedType>>([]);

  const { projectId } = useParams<{ projectId: string }>();

  const { gqlClientForProject } = useContext(ProjectContext);

  useEffect(() => {
    if (selectedSubmittal?.spec_no) {
      if (isSuggested) {
        const specNumber = selectedSubmittal.spec_no?.replace(/ /g, "");
        const suggestedList = materialList.filter(
          (material: any) =>
            material.spec_section_no?.replace(/ /g, "") === specNumber
        );
        setFilteredMatList(suggestedList);
      } else {
        setFilteredMatList(materialList);
      }
    } else if (isSuggested) {
      setFilteredMatList([]);
    } else {
      setFilteredMatList(materialList);
    }
  }, [materialList, isSuggested, selectedSubmittal?.spec_no]);

  useEffect(() => {
    let linkedMaterials = selectedSubmittal?.submittal_material_links;
    if (linkedMaterials) {
      const hasImplicitMaterial = linkedMaterials.some(
        (x) => x.material.implicit
      );
      if (hasImplicitMaterial && linkedMaterials.length > 1) {
        linkedMaterials = linkedMaterials.filter((x) => !x.material.implicit);
        onMaterialClick(linkedMaterials[0]);
      }
      setLinkedMaterialsWithSubmittal(linkedMaterials);
      setSelectedIds(
        linkedMaterials.map((material: MaterialLinkedType) => {
          return material.material.id;
        }) || []
      );
    } else {
      setLinkedMaterialsWithSubmittal([]);
      setSelectedIds([]);
    }

    const element = materialLinking.current;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    element && element?.scrollTo(0, element.scrollHeight);
  }, [onMaterialClick, selectedSubmittal?.submittal_material_links]);

  useEffect(() => {
    setTrackMaterialDate(!!selectedSubmittal?.material_tracking);
  }, [selectedSubmittal?.material_tracking]);
  const [deleteLinkingSubmittalMaterialMutaion] = useMutation(
    MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL,
    {
      client: gqlClientForProject
    }
  );
  const [linkingSubmittalMaterialMutation] = useMutation(
    MUTATION_INSERT_LINKING_SUBMITTAL_MATERIAL,
    {
      client: gqlClientForProject
    }
  );

  const onLinkingSubmittalMaterialMutation = (selectedMaterialId: string) => {
    const objects = selectedSubmittal?.ids.map((id) => ({
      submittal_id: id,
      material_id: selectedMaterialId
    }));
    linkingSubmittalMaterialMutation({
      variables: {
        objects
      }
    }).then((res) => {
      if (res.data) {
        console.log(res.data);
      }
      if (res.errors) {
        if (res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)) {
          message.error(ErrorMessages.linkingErrorMsg);
          return;
        }
        message.error(res.errors[0].message);
      }
    });
  };

  const onAllLinkUnlinkedSuggestedItems = (checked: boolean) => {
    const materialIds = filteredMatList
      .filter((m) =>
        checked
          ? !selectedIds.some((id) => id === m.id)
          : selectedIds.some((id) => id === m.id)
      )
      .map((m) => m.id as string);

    const submittalIds = selectedSubmittal?.ids || [selectedSubmittal?.id];

    const objects = [] as any[];

    submittalIds?.forEach((sub_id) => {
      materialIds.forEach((mat_id) => {
        objects.push({
          submittal_id: sub_id,
          material_id: mat_id
        });
      });
    });

    const linkVariables = {
      variables: {
        objects
      }
    };

    const unlinkVariables = {
      variables: {
        where: {
          material_id: {
            _in: materialIds
          },
          submittal_id: { _in: submittalIds }
        }
      }
    };

    modal.confirm({
      title: checked
        ? "Do you want to link all suggested items?"
        : "Do you want to unlink all suggested items?",
      icon: checked ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />,
      content: "",
      className: "skip-listing-window",
      async onOk() {
        const remainigIds = checked
          ? [...selectedIds, ...materialIds]
          : selectedIds.filter((id) => materialIds.some((mId) => mId === id));

        setSelectedIds(remainigIds);

        const mutation = checked
          ? linkingSubmittalMaterialMutation(linkVariables)
          : deleteLinkingSubmittalMaterialMutaion(unlinkVariables);

        const mutationRes = await mutation;

        if (mutationRes.data) {
          console.log(mutationRes.data);
        }
        if (mutationRes.errors) {
          if (
            mutationRes.errors[0]?.message.includes(
              ErrorMessages.uniquessViolation
            )
          ) {
            message.error(
              `${
                checked
                  ? ErrorMessages.linkingErrorMsg
                  : ErrorMessages.unLinkingErrorMsg
              }`
            );
            return;
          }
          message.error(mutationRes.errors[0].message);
        }
      },
      okText: "Yes",
      cancelText: "No"
    });
  };

  const onDeleteLinkingSubmittalMaterialMutaion = (
    selectedMaterialId: string
  ) => {
    modal.confirm({
      title: "Do you want to unlink?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      className: "skip-listing-window",
      onOk() {
        setSelectedIds(
          selectedIds.filter((id: string) => {
            return id !== selectedMaterialId;
          })
        );
        const submittalIds = selectedSubmittal?.ids || [selectedSubmittal!.id];
        deleteLinkingSubmittalMaterialMutaion({
          variables: {
            where: {
              material_id: { _eq: selectedMaterialId },
              submittal_id: { _in: submittalIds }
            }
          }
        }).then((res) => {
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
        });
      },
      okText: "Yes",
      cancelText: "No"
    });
  };

  const onChange = (selectedMaterialId: string) => {
    const selectedMaterial = linkedMaterialsWithSubmittal.find(
      (material: MaterialLinkedType) => {
        return material.material.id === selectedMaterialId;
      }
    );
    if (!selectedMaterial) {
      onLinkingSubmittalMaterialMutation(selectedMaterialId);
      setSelectedIds([...selectedIds, selectedMaterialId]);
    } else {
      onDeleteLinkingSubmittalMaterialMutaion(selectedMaterialId);
    }
  };

  return (
    <ErrorBoundary>
      <div className="material-linking" ref={materialLinking}>
        <div className="material-linking--nav !border-t-0">
          <h4>
            Associated Materials{" "}
            <span>
              {(linkedMaterialsWithSubmittal.length || 0) > 0
                ? ` (${linkedMaterialsWithSubmittal.length})`
                : ""}
            </span>
          </h4>
          <div className="flex items-center">
            <Button
              disabled={!isTrackMaterialDate || !canEdit}
              ref={buttonRef}
              onClick={() => setOpenMaterialList(true)}
            >
              + Associate Material
            </Button>
          </div>
          {openMaterialList ? (
            <ListingWindow
              onClose={() => setOpenMaterialList(false)}
              title="List of Materials"
              suggectionPanel={{
                isSuggested,
                setSuggested,
                areAllSuggestionsLinked: filteredMatList.every((m) =>
                  selectedIds.some((id) => id === m.id)
                ),
                onLinkUnlinkAll: onAllLinkUnlinkedSuggestedItems
              }}
              isFromSubmittals={false}
            >
              <div className="material-linking--options">
                {isSuggested && (
                  <div className="material-linking--suggestedLblDiv">
                    <span className="material-linking--suggestInfoLbl ml-1">
                      Suggested materials have the same spec section as the
                      selected submittal.
                    </span>
                  </div>
                )}
                <Search
                  className="linking-search"
                  placeholder="Search material name here"
                  value={searchKeyword}
                  onChange={(e: any) => setSearchKeyword(e.currentTarget.value)}
                />
                <div className="h-full">
                  <div className="overflow-auto h-[calc(100vh-270px)]">
                    {filteredMatList.length === 0 && (
                      <div className="flex justify-center items-center h-full">
                        <span className="text-[#3b3b3b]">
                          {isSuggested ? (
                            "No suggestions available. Uncheck suggested materials to view the list of materials."
                          ) : (
                            <span className="text-[#3b3b3b]">
                              No materials have been added to this project.
                              <Link to={`/project/${projectId}/materials`}>
                                {" "}
                                Click here
                              </Link>{" "}
                              to add materials.`
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                    {filteredMatList.length > 0 &&
                      filteredMatList
                        .filter((material: MaterialType) => {
                          return `${material.material_id} ${material.spec_section_no} ${material.spec_section_name} ${material?.name}`
                            .toLocaleLowerCase()
                            .includes(searchKeyword.toLocaleLowerCase());
                        })
                        .map((material: MaterialType) => {
                          const title = (
                            <span>
                              <i className="text-[#3b3b3bcc] mr-2">
                                {material?.material_id ?? ""}
                              </i>
                              <i className="text-[#3b3b3bcc]">
                                {mergedStrings([
                                  material?.spec_section_no ?? "",
                                  material?.spec_section_name ?? ""
                                ])}
                              </i>
                              <div>{material?.name}</div>
                            </span>
                          );
                          return (
                            <div
                              className={
                                material?.spec_section_no ===
                                selectedSubmittal?.spec_no
                                  ? "material-linking--suggestedOption"
                                  : "material-linking--option"
                              }
                              key={material.id}
                            >
                              <Checkbox
                                checked={
                                  !!selectedIds.find((id: string) => {
                                    return id === material.id;
                                  })
                                }
                                onChange={() => onChange(material.id)}
                              >
                                <Tooltip title={title}>
                                  <i className="mr-2">{material.material_id}</i>
                                  <i className="mr-2">
                                    {material?.spec_section_no || ""}
                                    {material?.spec_section_no?.length > 0 &&
                                    material?.spec_section_name?.length > 0
                                      ? "-"
                                      : ""}
                                    {material?.spec_section_name || ""}{" "}
                                  </i>
                                  {material.name}
                                </Tooltip>
                              </Checkbox>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </ListingWindow>
          ) : (
            ""
          )}
        </div>
        <div className="h-full pb-10">
          <div className="h-full overflow-auto overflow-x-hidden">
            {selectedSubmittal &&
              linkedMaterialsWithSubmittal.map((item: MaterialLinkedType) => {
                return (
                  <MaterialCard
                    onMaterialClick={onMaterialClick}
                    material={item}
                    key={item.material.id}
                    activityList={activityList}
                    submittalId={selectedSubmittal?.submittal_id}
                    selected={
                      userSelectedMaterial?.material.id === item.material.id
                    }
                    onUnlink={onChange}
                    reFetchActivity={reFetchActivity}
                    canEdit={canEdit}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default MaterialLinking;
