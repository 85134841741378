import { InfoCircleOutlined } from "@ant-design/icons";
import { Divider, Modal } from "antd";

export default function ViewSelectedItemsDrawer(props: {
  items: Array<{ name: string }>;
  instruction: string;
  setOpen: (open: boolean) => void;
  isOpen: boolean;
  title: string;
}) {
  const { items, instruction, setOpen, isOpen, title } = props;

  return (
    <Modal
      className="custom-drawer"
      title="Selection Details"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 92px)" }}
      footer={null}
      open={isOpen}
      onCancel={() => {
        setOpen(false);
      }}
      destroyOnClose
    >
      <div className="flex pt-2 items-center justify-center text-xs">
        <InfoCircleOutlined className="pr-1" /> {instruction}
      </div>
      <Divider />
      <div className="px-3">
        <div className="uppercase pb-2 text-[#555555D9] font-medium">
          {title} ({items.length})
        </div>
        <ul className="list-disc space-y-2 overflow-auto h-96">
          {items.map((item) => (
            <li className="text-opacity-10">{item.name}</li>
          ))}
        </ul>
      </div>
      <Divider />
    </Modal>
  );
}
