/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useQuery, useSubscription } from "@apollo/client";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useState, useEffect } from "react";
import {
  QUERY_GANTT_TASKS_LIST,
  QUERY_PROJECT_MATERIALS,
  QUERY_SUBMITTALS_LINKING_TABLE
} from "services/graphQL/queries";
import {
  DownOutlined,
  InfoCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import LinkIcon from "components/svg-icons/link-icon";
import { Dropdown, Button, Tooltip, Space } from "antd";
import { GridLoadingIndicator } from "components/widgets";
import { SUBSCRIPTION_MATERIAL_LINKING } from "services/graphQL/subscriptions";
import ErrorBoundary from "components/error-boundary";
import { useHistory, useLocation, useParams } from "react-router";
import MaterialIcon from "components/svg-icons/material-icon";
import GoverningLegend from "components/governing-legend";
import RedirectIcon from "components/svg-icons/redirect-icon";
import { Link } from "react-router-dom";
import { mergedStrings } from "utils/utils";
import {
  MaterialType,
  ScheduleLinkingType,
  ScheduleTaskType,
  SubmittalLinkedType,
  SubmittalType
} from "./models";
import "./index.scss";
import MaterialActivityLinking from "./material-activity-linking";
import MaterialSubmittalLinking from "./material-submittal-linking";
import { ProjectPermissionEnum } from "../../constants";
import ViewSelectedItemsDrawer from "./view-selected-items";
import { findColumnDataOfMaterial } from "./utils";

function MaterialLinkedBulkTab() {
  const { search, state } = useLocation() as { search: string; state: any };
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { gqlClientForProject, tokenContents } = useContext(ProjectContext);
  const [materialList, setMaterialList] = useState([] as Array<MaterialType>);
  const [showViewSelectedDrawer, setViewSelectedDrawer] = useState(false);

  const [selectedMaterial, setSelectedMaterial] = useState<
    MaterialType | undefined
  >(undefined);

  const [selectedBulkMaterialIds, setSelectedBulkMaterialIds] = useState<
    string[]
  >(state?.selectedRows?.map((x: any) => x.id) || []);

  const [materialSearch, setMaterialSearch] = useState("");
  const [activityList, setActivityList] = useState(
    [] as Array<ScheduleTaskType>
  );
  const [submittalList, setSubmittalList] = useState<Array<SubmittalType>>([]);
  const [loadingMaterialDetail, setLoadingMaterialDetail] = useState(false);
  const [selectedSubmittal, setSelectedSubmittal] = useState<SubmittalType>();
  const [openDropdown, setOpenDropdown] = useState(false);

  const isPermissionNotGrant = isPermissionNotGrantted(
    ProjectPermissionEnum.EditLinkingPage,
    tokenContents?.role!
  );

  const { data: submittalsRaw } = useQuery(QUERY_SUBMITTALS_LINKING_TABLE, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    fetchPolicy: "no-cache"
  });
  const { data: projectMaterials, loading: loadingMaterials } = useQuery(
    QUERY_PROJECT_MATERIALS,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: tasksData, refetch: reFetchActivity } = useQuery(
    QUERY_GANTT_TASKS_LIST,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );
  const { data: materialData } = useSubscription<{
    material: Array<MaterialType>;
  }>(SUBSCRIPTION_MATERIAL_LINKING, {
    client: gqlClientForProject,
    variables: {
      ids: selectedBulkMaterialIds
    },
    shouldResubscribe: true,
    skip: !selectedBulkMaterialIds.length,
    onError: () => {
      setLoadingMaterialDetail(false);
    }
  });

  useEffect(() => {
    setMaterialList(
      projectMaterials?.material?.sort(
        (current: MaterialType, next: MaterialType) => {
          return (+current?.material_id || 0) - (+next?.material_id || 0);
        }
      ) || ([] as Array<MaterialType>)
    );
    if (selectedBulkMaterialIds.length) {
      setLoadingMaterialDetail(true);
    } else {
      const materialId = new URLSearchParams(search).get("materialId");
      if (materialId) {
        const material = projectMaterials?.material.find(
          (item: MaterialType) => {
            return item.id === materialId;
          }
        );
        if (material) {
          setSelectedBulkMaterialIds([materialId]);
          setLoadingMaterialDetail(true);
        }
      }
    }
  }, [projectMaterials?.material]);

  useEffect(() => {
    setActivityList(
      (tasksData?.gantt_tasks || [])
        .filter((ts: any) => ts.type !== 3 && ts.type !== 4)
        .sort((current: any, next: any) =>
          current?.source_task_id?.localeCompare(next?.source_task_id)
        )
    );
  }, [tasksData]);

  useEffect(() => {
    if (submittalsRaw?.submittals) {
      setSubmittalList(
        submittalsRaw.submittals?.sort(
          (current: SubmittalType, next: SubmittalType) =>
            (+current?.submittal_id || 0) - (+next?.submittal_id || 0)
        )
      );
    }
  }, [submittalsRaw?.submittals]);

  useEffect(() => {
    if (materialData?.material && materialData.material.length) {
      const materialObj = findColumnDataOfMaterial(
        materialData.material
      ) as MaterialType;

      if (selectedBulkMaterialIds.length)
        materialObj.ids = selectedBulkMaterialIds;
      else materialObj.ids = [materialObj.id];

      (materialObj?.submittal_material_links || []).sort(
        (current: SubmittalLinkedType, next: SubmittalLinkedType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      (materialObj.material_schedule_links || []).sort(
        (current: ScheduleLinkingType, next: ScheduleLinkingType) => {
          return (
            new Date(current.created_at).valueOf() -
            new Date(next.created_at).valueOf()
          );
        }
      );
      setMaterialList(
        materialList.map((value: MaterialType) => {
          if (value.id === materialObj.id) {
            return materialObj;
          }
          return value;
        })
      );
      setSelectedMaterial(materialObj);
      const selectedSubmittalObj = materialObj.submittal_material_links.find(
        (item: SubmittalLinkedType) => {
          return item.submittal.id === selectedSubmittal?.id;
        }
      );
      if (selectedSubmittalObj) {
        setSelectedSubmittal(selectedSubmittalObj.submittal);
      } else if (materialObj.submittal_material_links.length > 0) {
        setSelectedSubmittal(materialObj.submittal_material_links[0].submittal);
      } else {
        setSelectedSubmittal(undefined);
      }
      setLoadingMaterialDetail(false);
    }
  }, [materialData?.material]);

  const menu = () => {
    if (loadingMaterials) {
      return (
        <div className="linking-container--nav__loading">
          <LoadingOutlined />
        </div>
      );
    }
    if (materialList.length === 0) {
      return (
        <div className="linking-container--nav__loading">
          No Material Available
        </div>
      );
    }
    return (
      <div className="linking-container--nav__dropdown">
        <div className="linking-container--nav__dropdown--search">
          <Search
            className="linking-search"
            placeholder="Search Material Here"
            value={materialSearch}
            onChange={(e: any) => setMaterialSearch(e.currentTarget.value)}
          />
        </div>
        {materialList
          .filter((item) => {
            const label =
              `${item.material_id} ${item?.name}`.toLocaleLowerCase();
            return label.includes(materialSearch.toLocaleLowerCase());
          })
          .map((item) => (
            <div
              className="linking-container--nav__option flex items-center justify-between"
              key={item.id}
              onClick={() => {
                setOpenDropdown(false);
                if (selectedBulkMaterialIds.includes(item.id)) {
                  return;
                }
                history.push(
                  `/project/${projectId}/schedule/material-linking?&materialId=${item.id}`
                );
                setSelectedBulkMaterialIds([item.id]);
                setLoadingMaterialDetail(true);
                setSelectedMaterial(undefined);
              }}
            >
              {item?.spec_section_no || item?.spec_section_name ? (
                <Tooltip
                  title={
                    <div className="space-y-1 py-1">
                      <i className=" text-[#3b3b3bcc]">
                        {mergedStrings([
                          item?.material_id || "",
                          item?.spec_section_no || "",
                          item?.spec_section_name || ""
                        ])}
                      </i>
                      <div>{item?.name || ""}</div>
                    </div>
                  }
                >
                  <span className="items-center w-full truncate space-x-2">
                    <i>
                      {mergedStrings([
                        item?.material_id || "",
                        item?.spec_section_no || "",
                        item?.spec_section_name || ""
                      ])}
                    </i>
                    <span>{item?.name || ""}</span>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <div className="space-y-1 py-1">
                      <i className="text-[#3b3b3bcc]">
                        {item?.material_id || ""}
                      </i>
                      <div>{item?.name || ""}</div>
                    </div>
                  }
                >
                  <div className="items-center w-full truncate space-x-2">
                    <i>{item?.material_id || ""}</i>
                    <span>{item?.name || ""}</span>
                  </div>
                </Tooltip>
              )}

              {item?.material_schedule_links.length ? <LinkIcon /> : ""}
            </div>
          ))}
      </div>
    );
  };
  const getSelectedMaterialLabel = () => {
    const selectedMaterialObj = materialList.find((material: MaterialType) => {
      return selectedBulkMaterialIds.includes(material.id);
    });
    if (selectedMaterialObj) {
      return (
        <div className="flex items-center linking-container--nav__dropdown--selected">
          {selectedMaterialObj?.spec_section_no ||
          selectedMaterialObj?.spec_section_name ? (
            <Tooltip
              title={
                <div className="space-y-1 py-1">
                  <i className=" text-[#3b3b3bcc]">
                    {mergedStrings([
                      selectedMaterialObj?.material_id || "",
                      selectedMaterialObj?.spec_section_no || "",
                      selectedMaterialObj?.spec_section_name || ""
                    ])}
                  </i>
                  <div>{selectedMaterialObj?.name || ""}</div>
                </div>
              }
            >
              <span className="flex-grow items-center truncate space-x-2">
                <i>
                  {mergedStrings([
                    selectedMaterialObj?.material_id || "",
                    selectedMaterialObj?.spec_section_no || "",
                    selectedMaterialObj?.spec_section_name || ""
                  ])}
                </i>
                <span>{selectedMaterialObj.name}</span>
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <div className="space-y-1 py-1">
                  <i className="text-[#3b3b3bcc]">
                    {selectedMaterialObj?.material_id || ""}
                  </i>
                  <div>{selectedMaterialObj?.name || ""}</div>
                </div>
              }
            >
              <span className="flex-grow items-center truncate space-x-2">
                <i>{selectedMaterialObj.material_id}</i>
                <span>{selectedMaterialObj.name}</span>
              </span>
            </Tooltip>
          )}

          {selectedMaterialObj?.material_schedule_links.length ? (
            <div className="flex items-center">
              <LinkIcon />
              <DownOutlined className="ml-1" />
            </div>
          ) : (
            <div className="flex items-center">
              <DownOutlined className="ml-4" />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="flex items-center linking-select-empty">
        Select a Material
        <DownOutlined className="ml-4" />
      </div>
    );
  };

  const forSingleMaterial = (
    <div className="linking-container--nav justify-between">
      <div className="flex items-center">
        <div className="flex items-center">
          <MaterialIcon className="mr-2" />
          <h3>MATERIAL</h3>
        </div>

        <Dropdown
          overlay={menu()}
          className="mx-4 linking-container--nav__select"
          trigger={["click"]}
          open={openDropdown}
          onOpenChange={(open) => {
            setOpenDropdown(open);
          }}
        >
          <Button>{getSelectedMaterialLabel()}</Button>
        </Dropdown>
        {selectedMaterial ? (
          <Tooltip title="Open Material Detail">
            <Link
              className="flex linking-container--nav__redirectlinkIcon"
              to={`/project/${projectId}/materials/${selectedMaterial.id}`}
              target="_blank"
            >
              <RedirectIcon />
            </Link>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
      <GoverningLegend />
    </div>
  );

  const forBulkMaterials = (
    <div className="linking-container--nav flex items-center">
      <div className="flex items-center">
        <MaterialIcon className="mr-2" />
        <h3>MATERIAL</h3>
      </div>

      <div className="py-1 px-4">
        <div className="linking-border p-1  text-sm flex w-[467px] space-x-1 rounded-sm items-center">
          <div className="font-bold pl-3">{selectedBulkMaterialIds.length}</div>
          <div className="grow">Materials Selected from Log</div>
          <Space
            className="linking-border rounded p-1 cursor-pointer"
            onClick={() => {
              setViewSelectedDrawer(true);
            }}
          >
            View
          </Space>
          <Space
            className="linking-border rounded p-1 cursor-pointer"
            onClick={() => {
              setSelectedBulkMaterialIds([]);
              setSelectedMaterial(undefined);
            }}
          >
            Clear
          </Space>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <InfoCircleOutlined />
        <div>
          Links which are not common to all the selected materials are not
          displayed here.
        </div>
      </div>
    </div>
  );

  const singleMaterialInstructions = (
    <div className="linking-group--right--content">
      <div className="linking-group--right--content__instruction">
        <p className="flex items-strech">
          <b className="mr-1 min-w-[60px]">Step 1:</b>{" "}
          <span className="grow">
            Choose a Material from the dropdown menu.
          </span>
        </p>
      </div>
      <div className="linking-group--right--content__instruction">
        <p className="flex items-strech">
          <b className="mr-1 min-w-[60px]">Step 2:</b>{" "}
          <span className="grow">
            Click &quot;Associate Activity&quot; to link an Activity and
            selected Material. To link a Submittal and the selected Material,
            click &quot;Associate Submittal&quot;.
          </span>
        </p>
      </div>
    </div>
  );

  const bulkMaterialInstructions = (
    <div className="text-sm px-3 py-2 text-[#000000CC]">
      <div className="font-semibold py-2">To Link:</div>
      <ul className="list-disc">
        <li>
          Click &#8220;Associate Activity&#8221; to link an Activity and the
          selected Materials. To link a Submittal and the selected Materials,
          click &#8220;Associate Submittal&#8221;.
        </li>
      </ul>

      <div className="font-semibold py-2">Changing the Selection:</div>
      <ul className="list-disc">
        <li>
          To add or remove items from the Materials selected, go back to the
          Material Log page and reselect the Materials you wish to Bulk Link.
        </li>
      </ul>

      <div className="font-semibold py-2">Note:</div>
      <ul className="list-disc">
        <li>
          Individual Materials in your selection may already be linked with
          different entities, such links are not shown here.
        </li>
        <li>
          Some materials in your selection may already be linked to common
          entities, such links are not shown here.
        </li>
        <li>
          If all the selected materials are linked to a common entity, such
          links are displayed here.
        </li>
      </ul>
    </div>
  );

  return (
    <ErrorBoundary>
      <div className="linking-page flex flex-col text-xs">
        <div
          className={`linking-container ${
            selectedMaterial ? "" : "linking-container--empty"
          }`}
        >
          {selectedBulkMaterialIds.length > 1
            ? forBulkMaterials
            : forSingleMaterial}
          {selectedMaterial ? (
            <div className="linking-group">
              <div className="linking-group--left show-material">
                <MaterialActivityLinking
                  activityList={activityList}
                  material={selectedMaterial}
                  reFetchActivity={reFetchActivity}
                  canEdit={!isPermissionNotGrant}
                />
                <MaterialSubmittalLinking
                  submittalList={submittalList}
                  material={selectedMaterial}
                  selectedId={selectedSubmittal?.id}
                  onSelect={(submittal) => {
                    setSelectedSubmittal(submittal);
                  }}
                  canEdit={!isPermissionNotGrant}
                />
              </div>
              <div className="linking-group--right" id="linkingOptionContainer">
                <div className="linking-group--right--nav">
                  <h2>Instructions</h2>
                </div>
                {selectedBulkMaterialIds.length > 1
                  ? bulkMaterialInstructions
                  : singleMaterialInstructions}
              </div>
            </div>
          ) : loadingMaterialDetail ? (
            <div className="linking-loading--container">
              <div>
                <GridLoadingIndicator />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {showViewSelectedDrawer && (
          <ViewSelectedItemsDrawer
            instruction="Add more to the selection from the Material Log page."
            isOpen={showViewSelectedDrawer}
            setOpen={setViewSelectedDrawer}
            items={state.selectedRows.map((m: any) => ({
              name: `${m.material_id} - ${m.name}`
            }))}
            key="ViewSelectedItemsDrawer"
            title="Selected Materials"
          />
        )}
      </div>
    </ErrorBoundary>
  );
}

export default MaterialLinkedBulkTab;
