import Button from "antd/lib/button";

export const SendRequestsBtnDisabledReasons: {
  NO_VALID_MATERIAL: string;
} = {
  NO_VALID_MATERIAL: "No material available to send request"
};

function Footer({
  data,
  onCancel,
  onSend,
  submitting,
  sendRequestsBtnProps,
  sendRequestsBtnDisabledReason
}: {
  data: {
    selectedCount: number;
    excludedCount: number;
    emailsToBeSentCount: number;
  };
  onCancel: () => void;
  onSend: () => void;
  submitting: boolean;
  sendRequestsBtnProps: {
    disabled: boolean;
  };
  sendRequestsBtnDisabledReason: string | null;
}) {
  return (
    <div className="px-4 py-3 flex items-center">
      <div className="flex grow items-center space-x-2 text-sm">
        <div>Selected Materials: {data.selectedCount}</div> <div>|</div>
        <div>
          Request being sent for: {data.selectedCount - data.excludedCount}
        </div>{" "}
        <div>|</div>
        <div data-testid="excluded-container">
          Excluded: <span className="danger-text">{data.excludedCount}</span>
        </div>
      </div>
      <div className="space-x-2">
        <Button
          onClick={onCancel}
          disabled={submitting}
          data-testid="cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onSend}
          loading={submitting}
          {...sendRequestsBtnProps}
          title={sendRequestsBtnDisabledReason ?? undefined}
          data-testid="send-requests-btn"
        >
          Send Requests{" "}
          {!sendRequestsBtnProps.disabled
            ? `(${data.emailsToBeSentCount})`
            : ""}
        </Button>
      </div>
    </div>
  );
}

export default Footer;
