import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import CiqAgGridUtil from "admin-app/components/ag-grid-util";
import { AUDIT_USER_EVENTS } from "admin-app/services/queries";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, message } from "antd";
import SearchInput from "components/search-input";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  DATE_FORMAT_MMDDYYYY_HHMM_Z,
  DateUtils,
  DateFilter
} from "utils/dateutils";

type TAuditType = {
  audit_user_events: Array<any>;
  audit_user_events_aggregate: { aggregate: { count: number } };
};

function AuditUserEvents() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [auditData, setAuditData] = useState<TAuditType>();
  const history = useHistory();
  const location = useLocation();
  const { userId, name, organization, subscription } =
    location.state || ({} as any);

  const [getAuditData] = useLazyQuery<TAuditType>(AUDIT_USER_EVENTS, {
    fetchPolicy: "no-cache",
    variables: { user_id: userId }
  });

  useEffect(() => {
    if (userId) {
      getAuditData().then((res) => {
        if (res.data) {
          setAuditData(res.data);
        }
        if (res.error) {
          message.error(res.error.message);
        }
      });
    }
  }, [getAuditData, userId]);

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: []
    };
  }, []);

  const columnDefsUsers: ColDef[] = useMemo<ColDef[]>(
    () => [
      // {
      //   colId: "created_by_user",
      //   field: "created_by_user.email",
      //   headerName: "Created By User"
      // },
      {
        colId: "event_description",
        field: "event_log.event.description",
        headerName: "Event"
      },
      {
        colId: "created_at",
        field: "created_at",
        headerName: "CREATED ON",
        valueGetter: ({ data }) => {
          return DateUtils.format(
            data?.created_at,
            DATE_FORMAT_MMDDYYYY_HHMM_Z
          );
        },
        comparator: (value1, value2) =>
          DateFilter.customComparator(
            value1,
            value2,
            DATE_FORMAT_MMDDYYYY_HHMM_Z
          )
      }
    ],
    []
  );

  return (
    <div className="h-[calc(100vh-135px)] px-3 p-1">
      <div className="flex w-full justify-between items-center p-1">
        <Button
          icon={<ArrowLeftOutlined />}
          className="!bg-transparent !border-0 px-0 !shadow-none uppercase font-medium items-center"
          onClick={() => {
            history.goBack();
          }}
        >
          {name}
        </Button>
        <div className="p-1 flex uppercase items-center space-x-1">
          <div>organization :</div>
          <div className="font-medium ">{organization}</div>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="p-1 flex uppercase items-center space-x-1">
          <div>subscription :</div>
          <div className="font-medium ">{subscription}</div>
        </div>
        <div className="uppercase ">Login Events</div>
        <SearchInput
          placeholder="Search"
          onChange={(text) => {
            gridRef.current?.api?.setQuickFilter(text);
          }}
        />
      </div>
      <CiqAgGridUtil
        gridRef={gridRef}
        rowData={auditData?.audit_user_events}
        columnDefs={columnDefsUsers}
        defaultColDef={defaultColDef}
      />
    </div>
  );
}
export default AuditUserEvents;
