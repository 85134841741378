import { EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Tooltip, message } from "antd";
import { Link, useHistory } from "react-router-dom";

import "../links.scss";
import LinkIcon from "components/svg-icons/link-icon";
import AsterixIcon from "components/svg-icons/asterix-icon";
import { DateUtils } from "utils/dateutils";
import { useLazyQuery } from "@apollo/client";
import { QUERY_GET_MATERIAL } from "services/graphQL/queries";
import { useMemo, useRef, useState } from "react";
import { RiskLevelType } from "components/date-block/models";
import { mergedStrings } from "utils/utils";
import linkedCell from "../linkedCell";
import { ErrorMessages, ESubmittalStatus } from "../../../constants";

const { Panel } = Collapse;

type TSubmittalLinksProps = {
  projectDetails: any;
  submittalData: any;
  materialLinks: any;
  projectId: string;
  isRevisionMode: boolean;
  canOpenLinkingPage: boolean;
  canEditLinkingPage: boolean;
  canInlineEditMaterialPrimaryFields: boolean;
  updateMaterialMutation: any;
  gqlClientForProject: any;
  isIntegrationMode: boolean;
};

function SubmittalLinks(props: TSubmittalLinksProps) {
  const {
    submittalData,
    canOpenLinkingPage,
    isRevisionMode,
    canEditLinkingPage,
    materialLinks,
    projectId,
    projectDetails,
    updateMaterialMutation,
    canInlineEditMaterialPrimaryFields,
    gqlClientForProject,
    isIntegrationMode
  } = props;

  const [checkForExistingMaterial] = useLazyQuery(QUERY_GET_MATERIAL, {
    client: gqlClientForProject
  });

  const [isMaterialExist, setMaterialExist] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const inputRef: any = useRef();
  const [materialName, setMaterialName] = useState("");
  const history = useHistory();

  const isSubmittalVoid: boolean = useMemo(() => {
    return submittalData.workflow_status === ESubmittalStatus.VOID;
  }, [submittalData?.workflow_status]);

  async function updateMatName(name: string, materialId: string) {
    if (name.trim() === "") return;
    const matUpdateObj: any = { name };

    if (submittalData?.spec_section_id) {
      matUpdateObj.spec_section_id = submittalData.spec_section_id;

      if (submittalData?.spec_name) {
        matUpdateObj.spec_section_name = submittalData.spec_name;
      }
      if (submittalData?.spec_no) {
        matUpdateObj.spec_section_no = submittalData.spec_no;
      }
    }

    if (!isIntegrationMode) {
      if (submittalData?.responsible_contractor) {
        matUpdateObj.trade_partner = submittalData.responsible_contractor;
      }
      if (submittalData?.submitter) {
        matUpdateObj.assignee = submittalData.submitter;
      }
    }

    const updateResponse = await updateMaterialMutation({
      variables: {
        set: matUpdateObj,
        pk_columns: { id: materialId }
      }
    });
    if (updateResponse.data) {
      message.success("Saved successfully");
    }
  }

  const getShadowMaterialName = (materialId: string) => {
    const showSpecNo = projectDetails?.spec_section;

    const submittalRefrenceId =
      showSpecNo && submittalData.spec_no
        ? `${submittalData.spec_no} - ${submittalData.submittal_id}`
        : submittalData.submittal_id;

    return (
      <div className=" w-full material-name-input">
        <div className="flex w-full truncate px-1  items-center">
          {isEditable ? (
            <Input
              bordered={isEditable}
              className="material-name-input"
              ref={inputRef}
              autoFocus
              defaultValue={`Material for Submittal ${submittalRefrenceId}`}
              readOnly={!isEditable}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.code === "Escape") {
                  setEditable(false);
                  setMaterialExist(false);
                  setMaterialName(
                    `Material for Submittal ${submittalRefrenceId}`
                  );
                }
              }}
              onPressEnter={() => {
                if (!isMaterialExist) {
                  setEditable(false);
                  setMaterialExist(false);
                  setMaterialName(inputRef?.current?.input?.value || "");
                  updateMatName(
                    inputRef?.current?.input?.value || "",
                    materialId
                  );
                }
              }}
              onBlur={() => {
                setEditable(false);
                setMaterialExist(false);
                setMaterialName(
                  `Material for Submittal ${submittalRefrenceId}`
                );
              }}
              onInput={async (name) => {
                if (name.currentTarget.value.trim() === "") return;
                const existingMaterialResp = await checkForExistingMaterial({
                  variables: {
                    where: {
                      name: { _ilike: name.currentTarget?.value?.trim() }
                    }
                  }
                });

                if (existingMaterialResp?.data?.material.length) {
                  setMaterialExist(true);
                  return;
                }
                setMaterialExist(false);
              }}
            />
          ) : (
            <span className="px-4 py-1 grow">
              {materialName || `Material for Submittal ${submittalRefrenceId}`}
            </span>
          )}
          {!isSubmittalVoid &&
            canInlineEditMaterialPrimaryFields &&
            !isEditable && (
              <EditOutlined
                className="w-6"
                onClick={() => {
                  setEditable(true);
                  inputRef?.current?.focus();
                }}
              />
            )}
        </div>
        {isMaterialExist && (
          <span className="text-xs text-red-600 ml-1">
            {ErrorMessages.MaterialExists}
          </span>
        )}
      </div>
    );
  };

  // const getSpecInfo = (spec_section_no: string, spec_section_name: string) => {
  //   if (spec_section_no && spec_section_name)
  //     return `${spec_section_no} - ${spec_section_name}`;
  //   if (spec_section_no) return spec_section_no;
  //   return "";
  // };

  // const getAssignee = (material: any) => {
  //   let assigneeFullName = "";
  //   if (material.material_assignee) {
  //     assigneeFullName = `${material.material_assignee.first_name} ${material.material_assignee.last_name}`;
  //   }
  //   if (material.assignee_unregistered) {
  //     assigneeFullName = material.assignee_unregistered;
  //   }

  //   return {
  //     fullName: assigneeFullName,
  //     company: material?.materials_trade_partner?.name || ""
  //   };
  // };

  const materialPanelHeader = (link: any) => {
    const matFullName = link.material_sequence_id
      ? `${link.material_sequence_id}-${link.material_name}`
      : "";
    const title: string = link.implicit ? "" : matFullName;
    return (
      <div className="flex items-center" title={title}>
        <span className="flex items-center mr-2">
          <LinkIcon />
        </span>

        {link.implicit ? (
          getShadowMaterialName(link.material_id)
        ) : (
          <>
            <i className="opacity-60">
              {link.material_sequence_id}
              {link.material_sequence_id && !link.driving_material && (
                <span className="mx-1">-</span>
              )}
            </i>
            {link.driving_material && (
              <span className="flex items-center ml-2 mr-1 mb-1">
                <AsterixIcon />
              </span>
            )}
            <span className="w-full truncate overflow-hidden mr-5">
              {link.material_name}
            </span>
          </>
        )}
      </div>
    );
  };

  const schedulePanelHeader = (link: any) => {
    const headerMarkup = (
      <div
        className="flex items-center"
        title={`${link?.gantt_task?.source_task_id}-${link?.gantt_task?.text}`}
      >
        <span className="flex items-center mr-2">
          <LinkIcon />
        </span>
        <i className="opacity-60">
          {link?.gantt_task?.source_task_id}
          {link?.gantt_task?.source_task_id && !link.driving_task && (
            <span className="mx-1">-</span>
          )}
        </i>
        {link.driving_task && (
          <div className="flex items-center mx-1 mb-1">
            <AsterixIcon />
          </div>
        )}
        <span className="truncate overflow-hidden">
          {link?.gantt_task?.text}
        </span>
        {(link?.gantt_task?.start_date || link?.gantt_task?.end_date) && (
          <i className="opacity-60 px-1 w-[90px]">
            {link?.gantt_task?.start_date && link.linked_to_end_date === false
              ? DateUtils.format(link?.gantt_task?.start_date)
              : null}
            {link?.gantt_task?.end_date && link.linked_to_end_date === true
              ? DateUtils.format(link?.gantt_task?.end_date)
              : null}
          </i>
        )}
      </div>
    );

    if (canOpenLinkingPage && !isRevisionMode) {
      return (
        <Link
          to={`/project/${projectId}/schedule/submittal-linking?&SubmittalId=${submittalData?.id}`}
          className="text-inherit hover:text-inherit"
        >
          {headerMarkup}
        </Link>
      );
    }

    return headerMarkup;
  };

  const cssForValue = "text-xs font-normal text-[#000000CC]";

  return (
    <div>
      <Form.Item
        label={
          <div className="flex space-x-2 items-center">
            <span>
              MATERIALS
              {materialLinks?.length ? ` (${materialLinks?.length})` : ""}
            </span>
            {/* <Link to={`/project/${projectId}/schedule?tab=4`}>
              <LinkOutlined style={{ fontSize: "18px" }} />
            </Link> */}
          </div>
        }
      >
        {materialLinks?.length > 0 ? (
          <Collapse expandIconPosition="end" className="custom-collapse">
            {materialLinks.map((link: any) => {
              const { material } = link;
              if (!material)
                return (
                  <Panel
                    header={
                      <Tooltip
                        title={
                          !link.implicit && !material
                            ? ErrorMessages.MaterialAccessDenied
                            : ""
                        }
                      >
                        {materialPanelHeader(link)}
                      </Tooltip>
                    }
                    key={link.material_id}
                    className="custom-panel"
                    collapsible="disabled"
                  />
                );
              const dateBlockMaterial = material?.date_block_materials?.length
                ? material?.date_block_materials[0]
                : null;

              const materialReleaseStatus =
                dateBlockMaterial?.milestone_state || "";
              const materialRiskLevel = dateBlockMaterial?.risk_level || "";

              const specSection = mergedStrings([
                material?.spec_section_no ?? "",
                material?.spec_section_name ?? ""
              ]);

              const additionalPanelProps: any = {};
              if (link.implicit) {
                additionalPanelProps.collapsible = "disabled";
              }
              return (
                <Panel
                  header={materialPanelHeader(link)}
                  key={material.material_id}
                  className={
                    materialRiskLevel === RiskLevelType.High
                      ? "custom-panel risk-panel"
                      : "custom-panel"
                  }
                  {...additionalPanelProps}
                >
                  <div className="space-y-2.5">
                    <div className="grid grid-rows-2 gap-2">
                      <div className="flex w-full">
                        {linkedCell(
                          "Spec Section:",
                          `${specSection}`,
                          "",
                          `${cssForValue} max-w-[300px] truncate`
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-3">
                        {linkedCell(
                          "Status:",
                          materialReleaseStatus,
                          "",
                          cssForValue
                        )}
                        {linkedCell(
                          "Risk:",
                          materialRiskLevel,
                          "",
                          materialRiskLevel === RiskLevelType.High
                            ? "date-block-risk-high text-xs font-semibold"
                            : cssForValue
                        )}
                      </div>
                      <div className="text-right p-2">
                        {!isRevisionMode && !link?.implicit ? (
                          <Button
                            size="small"
                            className="text-xs"
                            onClick={() => {
                              history.push(
                                `/project/${projectId}/materials/${link?.material.id}`
                              );
                            }}
                          >
                            View Material
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            {submittalData?.material_tracking
              ? "No materials linked."
              : "Material tracking is disabled."}{" "}
            {canEditLinkingPage && !isRevisionMode && (
              <Link
                to={`/project/${projectId}/schedule/submittal-linking?&SubmittalId=${submittalData?.id}`}
              >
                {submittalData?.material_tracking
                  ? "Click here to add"
                  : "Click here to enable"}
              </Link>
            )}
          </span>
        )}
      </Form.Item>
      <Form.Item
        label={
          <span>
            SCHEDULE ACTIVITIES
            {submittalData?.submittal_schedule_links?.length
              ? ` (${submittalData?.submittal_schedule_links.length})`
              : ""}
          </span>
        }
      >
        {submittalData?.submittal_schedule_links.length > 0 ? (
          <Collapse
            accordion
            expandIconPosition="end"
            className="custom-collapse"
          >
            {submittalData?.submittal_schedule_links.map((link: any) => {
              return (
                <Panel
                  header={schedulePanelHeader(link)}
                  key={link.task_id}
                  className="custom-panel"
                  collapsible="disabled"
                />
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            No links added{" "}
            {!isSubmittalVoid && canEditLinkingPage && !isRevisionMode && (
              <Link
                to={`/project/${projectId}/schedule/submittal-linking?&SubmittalId=${submittalData?.id}`}
              >
                Click to add
              </Link>
            )}
          </span>
        )}
      </Form.Item>
    </div>
  );
}

export default SubmittalLinks;
